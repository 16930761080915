import { Fragment, useEffect, useState } from 'react';
import { Column, Row } from 'react-table';
import { useHistory, useParams } from 'react-router-dom';
import { gql, useMutation, useQuery } from '@apollo/client';
import {
  createStyles,
  makeStyles,
  Paper,
  Theme,
  Typography,
  Collapse,
  Box,
  IconButton,
  lighten,
} from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';

import Header from 'components/Header';
import Loader from 'components/Loader';
import Table from 'components/Table';
import IconLabelButton from 'components/IconLabelButton';
import CreateChannelInstance from 'components/CreateChannel';
import ChannelAnnotations from 'components/ChannelAnnotations';
import SnackMessage from 'components/SnackMessage';
import Breadcrumbs from 'components/Breadcrumb';

import { IIndexable, Sensor } from 'types';
import { useThemeStyles } from 'theme';
import UPDATECHANNEL from 'components/UpdateChannel';
import { ENERGY_CHANNEL_COLUMNS } from 'constants/data';

export const CHANNEL_ANNOTATION_ATTRIBUTES = gql`
  fragment channelAnnotationAttributes on energy_meter_channel_instance_annotations {
    id
    key
    value
  }
`;

export const CHANNEL_ANNOTATIONS = gql`
  fragment channelAnnotations on energy_meter_channel_instance {
    annotations {
      ...channelAnnotationAttributes
    }
  }
  ${CHANNEL_ANNOTATION_ATTRIBUTES}
`;

export const CHANNELS = gql`
  fragment channels on energy_meter_instance {
    channels {
      id
      name
      hardware_id
      org_id
      space {
        name
      }
      thing {
        name
      }
      energy_meter_instance_id
      ...channelAnnotations
    }
  }
  ${CHANNEL_ANNOTATIONS}
`;

export const GET_ENERGY_METER_INSTANCE = gql`
  query getEnergyMeterInstance($id: uuid!) {
    energy_meter_instance_by_pk(id: $id) {
      id
      hardware_id
      org_id
      ...channels
      spec {
        id
        model
        name
        description
      }
      organisation {
        name
      }
      building {
        name
      }
      space {
        name
      }

      annotations(where: { key: { _eq: "root_id" } }) {
        id
        value
      }
    }
  }
  ${CHANNELS}
`;

const DELETE_ENERGY_METER_CHANNEL_INSTANCES = gql`
  mutation deleteEnergyMeterChannelInstances(
    $ids: [uuid!]!
    $energy_meter_instance_id: uuid!
  ) {
    delete_energy_meter_channel_instance(
      where: {
        id: { _in: $ids }
        energy_meter_instance_id: { _eq: $energy_meter_instance_id }
      }
    ) {
      returning {
        id
        hardware_id
      }
    }
  }
`;

type InstanceParams = {
  id: string;
  org_id: string;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    description: {
      padding: theme.spacing(2),
      width: '30em',
    },

    topWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
      marginBottom: theme.spacing(2),
    },
  })
);

export default function EnergyMeterInstance() {
  const params = useParams<InstanceParams>();
  const { id } = params;

  const [orgName, setOrgName] = useState<string | any>('');

  const { data, loading } = useQuery(GET_ENERGY_METER_INSTANCE, {
    variables: { id },

    onCompleted: () => {
      setOrgName(data?.energy_meter_instance_by_pk?.organisation?.name);
    },
  });

  //Following code for deleting the channel is not being used anymore as we removed the ability to delete the channel from UI.
  // const [deleteChannel, { error: deleteError }] = useMutation(
  //   DELETE_ENERGY_METER_CHANNEL_INSTANCES,
  //   {
  //     update(
  //       cache,
  //       {
  //         data: {
  //           delete_energy_meter_channel_instance: { returning },
  //         },
  //       }
  //     ) {
  //       const __typename = 'energy_meter_instance';
  //       const commonParams = {
  //         id: `${__typename}:${id}`,
  //         fragment: CHANNELS,
  //         fragmentName: 'channels',
  //       };
  //       const currentMeter: any = cache.readFragment(commonParams);
  //       const deletedIds = returning.map((c: Sensor) => c.id);

  //       // Filter out deleted record and insert filtered array back into cache
  //       const filteredChannels = currentMeter?.channels.filter(
  //         (c: Sensor) => !deletedIds.includes(c.id)
  //       );

  //       cache.writeFragment({
  //         ...commonParams,
  //         data: {
  //           __typename,
  //           channels: filteredChannels,
  //         },
  //       });
  //     },
  //     onCompleted: ({
  //       delete_energy_meter_channel_instance: { returning },
  //     }) => {
  //       setDeleteSuccessMessage(
  //         `${
  //           returning.length > 1
  //             ? `${returning.length} channels`
  //             : returning[0].hardware_id
  //         } deleted successfully!`
  //       );
  //     },
  //   }
  // );

  const [openForm, setOpenForm] = useState(false);
  const [update, setUpdate] = useState(false);
  const [editAnnot, setEditAnnot] = useState(false);
  const [selectedChannel, setSelectedChannel] = useState<Sensor | null>(null);
  const [deleteSuccessMessage, setDeleteSuccessMessage] = useState('');

  const classes: IIndexable = useStyles();
  const themeClasses: IIndexable = useThemeStyles();

  // function handleChannelSelect({ values }: Row) {
  //   const channel = data?.energy_meter_instance_by_pk?.channels.find(
  //     (c: Sensor) => c.id === values.id
  //   );
  //   setSelectedChannel(channel);
  //   setUpdate(false);
  //   setEditAnnot(false);
  // }

  // function handleDelete(rows: Row[]) {
  //   const ids = rows.map((r: Row) => r.values.id);
  //   deleteChannel({
  //     variables: {
  //       ids,
  //       energy_meter_instance_id: id,
  //     },
  //   });
  // }

  function handleUpdate({ values }: Row) {
    const channel = data?.energy_meter_instance_by_pk?.channels.find(
      (c: Sensor) => c.id === values.id
    );
    setSelectedChannel(channel);
    setUpdate(!update);
    setEditAnnot(false);
  }

  function handleAnnotUpdate({ values }: Row) {
    const channel = data?.energy_meter_instance_by_pk?.channels.find(
      (c: Sensor) => c.id === values.id
    );
    setSelectedChannel(channel);
    setUpdate(false);
    setEditAnnot(!editAnnot);
  }

  if (loading) return <Loader />;

  const sortedChannels = [...data?.energy_meter_instance_by_pk?.channels];
  sortedChannels &&
    sortedChannels?.sort((a, b) => {
      const aa = a.hardware_id.split('/')[1];
      const bb = b.hardware_id.split('/')[1];
      return aa - bb;
    });

  return (
    <Fragment>
      {/* <SnackMessage message={deleteError?.message} type="error" /> */}
      <SnackMessage message={deleteSuccessMessage} type="success" />
      <Header>
        <Breadcrumbs />
      </Header>
      <Box className={classes.topWrapper}>
        <Paper className={classes.description}>
          <Typography variant="h5" noWrap>
            {data?.energy_meter_instance_by_pk?.spec?.description}
          </Typography>
          <Typography>
            {data?.energy_meter_instance_by_pk?.spec?.model}
          </Typography>
          <Typography>
            {data?.energy_meter_instance_by_pk?.organisation?.name} -{' '}
            {data?.energy_meter_instance_by_pk?.building?.name} -{' '}
            {data?.energy_meter_instance_by_pk?.space?.name}
          </Typography>
        </Paper>
        {/* <IconLabelButton
          item={'Add Channel'}
          onClick={() => setOpenForm(!openForm)}
          open={openForm}
        /> */}
      </Box>
      {/* <Collapse in={openForm}>
        <CreateChannelInstance energy_meter={energy_meter_instance_by_pk} />
      </Collapse> */}
      <Box className={themeClasses.detailWrapper}>
        <Box
          className={[
            themeClasses.maxWidthTransition,
            themeClasses[
              selectedChannel && (update || editAnnot)
                ? 'tableSmall'
                : 'tableLarge'
            ],
          ].join(' ')}
        >
          {/* {data?.energy_meter_instance_by_pk?.channels?.thing?.name ? ( */}
          <Table
            title="Channels"
            data={sortedChannels}
            columns={ENERGY_CHANNEL_COLUMNS}
            // onRowClick={handleChannelSelect}
            selectedRow={selectedChannel?.id}
            // onDeleteRows={handleDelete}
            globalSearch
            onUpdateRow={handleUpdate}
            onEditRow={handleAnnotUpdate}
          />
          {/* ) : null} */}
        </Box>
        <Box
          className={[
            themeClasses.maxWidthTransition,
            themeClasses[
              selectedChannel && (update || editAnnot)
                ? 'contentOpen'
                : 'contentClosed'
            ],
          ].join(' ')}
        >
          <Paper
            className={[
              themeClasses.maxWidthTransition,
              themeClasses.detailsTop,
              themeClasses[
                selectedChannel || update || editAnnot ? 'padding' : ''
              ],
            ].join(' ')}
          >
            <IconButton
              aria-label="close"
              onClick={() => {
                if (update && !editAnnot) {
                  setUpdate(!update);
                } else if (!update && editAnnot) {
                  setEditAnnot(!editAnnot);
                } else {
                  setSelectedChannel(null);
                }
              }}
            >
              <ChevronRight />
            </IconButton>

            {selectedChannel && (update || editAnnot) && (
              <Typography variant="h5">
                {selectedChannel?.hardware_id}
              </Typography>
            )}
          </Paper>
          {update && !editAnnot && (
            <UPDATECHANNEL
              onDone={() => {
                setUpdate(false);
                setEditAnnot(false);
              }}
              channel={selectedChannel}
            />
          )}
          {selectedChannel && !update && editAnnot && (
            <ChannelAnnotations
              onDone={() => {
                setUpdate(false);
                setEditAnnot(false);
              }}
              channel={selectedChannel}
            />
          )}
        </Box>
      </Box>
    </Fragment>
  );
}
