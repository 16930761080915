import { format } from 'date-fns';

export function formatDateString(
  date: string,
  formatString: string = 'dd MMM YYY HH:mm'
) {
  return !isNaN(Date.parse(date))
    ? format(new Date(date), formatString)
    : 'Invalid Date';
}

export function formatDateStringPricing(
  date: string,
  formatString: string = 'dd MMM YYY'
) {
  return !isNaN(Date.parse(date))
    ? format(new Date(date), formatString)
    : 'Invalid Date';
}

export const groupByKey = (list: any, key: any) =>
  list.reduce(
    (hash: any, obj: any) => ({
      ...hash,
      [obj[key]]: (hash[obj[key]] || []).concat(obj),
    }),
    {}
  );

export const trimmedFields = (obj) => {
  const trimmedObj = { ...obj };
  Object.keys(trimmedObj).forEach(
    (k) =>
      (trimmedObj[k] =
        typeof trimmedObj[k] == 'string' ? trimmedObj[k].trim() : trimmedObj[k])
  );
  return trimmedObj;
};

export const sortGroupAutoComplete = (arrData) => {
  const options = arrData.map((option) => {
    const firstLetter = option?.name[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
      ...option,
    };
  });

  return options;
};

export const sortDataByAlpha = (arrData) => {
  const options = arrData?.sort((a, b) => a.name.localeCompare(b.name));
  return options;
};
