import { gql, useQuery } from '@apollo/client';
import { RouteComponentProps } from 'react-router-dom';

import Title from 'components/Title';

type Params = {
  space_id: string;
};

const GET_SPACE_NAME = gql`
  query getSpace($space_id: uuid!) {
    getSpaceById(id: $space_id) {
      id
      name
    }
  }
`;

const SpaceCrumb: React.FC<RouteComponentProps<Params>> = ({
  match,
  location,
}) => {
  const { space_id } = match.params;
  const { pathname } = location;

  const { data } = useQuery(GET_SPACE_NAME, {
    variables: { space_id },
    fetchPolicy: 'cache-first',
  });

  return (
    // is the matched path the current location (is space the current path?)
    pathname === match.url ? (
      <Title heading={data?.getSpaceById?.name} />
    ) : (
      <span>{data?.getSpaceById?.name}</span>
    )
  );
};

export default SpaceCrumb;
