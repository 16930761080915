import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { Row, useAsyncDebounce } from 'react-table';
import { useHistory } from 'react-router-dom';
import Table from 'components/Table';
import Header from 'components/Header';
import Title from 'components/Title';
import { GLOBAL_PRISM_COLUMNS, GLOBAL_THINGS_COLUMNS } from 'constants/data';
import { SpaceThing } from 'types';
import SnackMessage from 'components/SnackMessage';
import { IIndexable } from 'types';
import Update from '../../components/UpdateThing';
import { ChevronRight } from '@material-ui/icons';
import { useThemeStyles } from 'theme';
import {
  Box,
  Button,
  Collapse,
  IconButton,
  InputBase,
  makeStyles,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { FaSearch } from 'react-icons/fa';
import AutocompleteORG from 'components/OrgAutoComplete';
import { GET_ROOTS } from 'components/CreateEnergyMeter';
import { GET_ALL_SPACES_FOR_BUILDING } from 'graphql/common-queries';
import React from 'react';
import CreatePrismInstall from 'components/CreatePrismInstall';
import IconLabelButton from 'components/IconLabelButton';
import { sortDataByAlpha } from 'utils/helpers';
import { THING_GROUP } from '../things/group';
import MIMMI from 'components/MiMMi';
import UPDATE_MULTI_THING from 'components/UpdateMultiThing';
import PrismInstallTable from 'components/PrismInstallTableO';
import { Column } from '@tanstack/react-table';
import build from 'react-countup';
import { add } from 'date-fns';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '90%',
    marginBottom: 30,
    marginLeft: 110,
    paddingLeft: 20,
    paddingTop: 20,
    flexDirection: 'column',
    padding: 10,
    justifyContent: 'space-between',
    height: 300,
  },
  para: {
    width: '50%',
    justifyContent: 'flex-end',
  },
  createThing: {
    display: 'flex',
    justifyContent: 'flex-end',
  },

  buttongroup: {
    width: 300,
    borderRadius: 15,
    display: 'flex',
    marginTop: 10,
    marginBottom: 60,
  },
  button: {
    width: 200,
    height: 50,
    borderRadius: 15,
    padding: 10,
  },

  buttonActive: {
    backgroundColor: '#3d70b2',
    '&:hover': {
      backgroundColor: '#3d70b2',
    },
  },

  buttonDisable: {
    backgroundColor: 'whitesmoke',
    color: '#3d70b2',
    '&:hover': {
      backgroundColor: '#3d70b2',
      color: 'whitesmoke',
    },
  },

  autocomplete: {
    width: '100%',
  },
  mmi: {
    width: 20,
  },
  completeSearchBox: {
    '&:hover': {
      backgroundColor: 'aliceblue',
      border: '2px solid #1769aa',
    },
  },

  search: {
    display: 'flex',
    flexDirection: 'row',
    borderRadius: 4,
    alignItems: 'center',
    width: 'auto',

    backgroundColor: 'whitesmoke',
    boxSizing: 'border-box',
    justifyContent: 'stretch',
    flex: 1,
    border: '1px solid rgba(224, 224, 224, 1)',
    marginTop: 10,
    marginBottom: 0,
    height: 40,
  },
  searchBox: {
    justifyContent: 'flex-end',
  },
  searchIcon: {
    display: 'flex',
    flexDirection: 'row',
    height: 38,
    width: 'auto',
    alignSelf: 'flex-end',
    backgroundColor: 'rgba(224, 224, 224, 1)',
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    color: 'blue',
    boxSizing: 'border-box',
    '&:hover': {
      backgroundColor: 'rgba(224, 224, 224, 1)',
    },
    padding: '10px 10px',
  },
  inputRoot: {
    color: 'inherit',
    display: 'flex',
    flexDirection: 'row',
    borderRadius: 0,
    height: 40,
    width: '50%',
    borderLeft: '1px solid rgba(224, 224, 224, 1)',
    alignSelf: 'flex-end',
  },
  inputInput: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,

    justifyContent: 'space-evenly',
    padding: theme.spacing(1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(1)}px)`,
    height: 40,
  },

  circular: {
    display: 'flex',
    marginTop: '15%',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    justifyItems: 'center',
  },
}));

export const GET_TASKS_QUERY = gql`
  query tasksQuery(
    $assignedDateGte: timestamptz
    $assignedDateLt: timestamptz
    $status: String
    $taskNum: Int
    $createdDateGte: timestamptz
    $createdDateLt: timestamptz
    $contractor: String
    $completedDateGte: timestamptz
    $completedDateLt: timestamptz
    $clientOrg: String
    $building: String
    $dueDateGte: timestamptz
    $dueDateLt: timestamptz
    $limit: Int
    $offset: Int # $cursor: timestamptz
    $orderBy: [task_tasks_order_by!]
  ) {
    task_tasks(
      offset: $offset
      limit: $limit
      order_by: $orderBy
      # limit: $limit
      # cursor: $cursor
      where: {
        building: { name: { _ilike: $building } }
        client_org: { name: { _ilike: $clientOrg } }
        contractor_org: { name: { _ilike: $contractor } }
        status: { name: { _ilike: $status } }
        complete_date: { _gte: $completedDateGte, _lt: $completedDateLt }
        created_at: { _gte: $createdDateGte, _lt: $createdDateLt }
        number: { _eq: $taskNum }
        due_date: { _gte: $dueDateGte, _lt: $dueDateLt }
        assigned_date: { _gte: $assignedDateGte, _lt: $assignedDateLt }
      }
    ) {
      assigned_date
      building {
        name
      }
      building_id
      client_org {
        name
      }
      client_org_id
      complete_date
      contractor_org {
        name
      }
      contractor_org_id
      created_at
      description
      due_date
      id
      status_id
      number
      status {
        name
        active
      }
    }
    task_tasks_aggregate(
      where: {
        building: { name: { _ilike: $building } }
        client_org: { name: { _ilike: $clientOrg } }
        contractor_org: { name: { _ilike: $contractor } }
        status: { name: { _ilike: $status } }
        complete_date: { _gte: $completedDateGte, _lt: $completedDateLt }
        created_at: { _gte: $createdDateGte, _lt: $createdDateLt }
        number: { _eq: $taskNum }
        due_date: { _gte: $dueDateGte, _lt: $dueDateLt }
        assigned_date: { _gte: $assignedDateGte, _lt: $assignedDateLt }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const ROWS_PER_PAGE = 20;

type Filter = {
  taskNum: string | null;
  client: string | null;
  building: string | null;
  contractor: string | null;
  assignedDateGte: string | null;
  assignedDateLt: string | null;
  completedDateGte: string | null;
  completedDateLt: string | null;
  dueDateGte: string | null;
  createdDateGte: string | null;
  dueDateLt: string | null;
  createdDateLt: string | null;
  status: string | null;
};

export default function Tasks() {
  const classes = useStyles();
  const [filterState, setFilterState] = useState<Filter>({
    taskNum: null,
    client: '',
    building: '',
    contractor: '',
    assignedDateGte: null,
    assignedDateLt: null,
    completedDateGte: null,
    completedDateLt: null,
    dueDateGte: null,
    dueDateLt: null,
    createdDateGte: null,
    createdDateLt: null,

    status: '',
  });
  const [offset, setOffset] = useState<number>(0);

  const [isFilter, setIsFilter] = useState<boolean>(false);
  const [inputFilter, setInputFilter] = useState<boolean>(false);
  const [cursor, setCursor] = useState<string>('now');
  const [orderBy, setOrderBy] = useState<any>({});

  const [deleteSuccessMessage, setDeleteSuccessMessage] = useState('');
  const [controlledPageIndex, setControlledPage] = useState(0);
  const [update, setUpdate] = useState(false);
  const [page, setPage] = useState<number>(0);

  const [openForm, setOpenForm] = useState<boolean>(false);
  const [openMultiEditForm, setOpenMultiEditForm] = useState<boolean>(false);
  const history = useHistory();

  function nextDate(currentDate: string | null) {
    let result;
    if (!currentDate) {
      return null;
    }
    const d = add(new Date(currentDate as string), {
      days: 1,
    });

    if (d.toISOString() === '1970-01-02T00:00:00.000Z') {
      return null;
    }

    result = d;
    return result;
  }
  const [sortedData, setSortedData] = useState([]);

  const { data, loading } = useQuery(GET_TASKS_QUERY, {
    variables: {
      taskNum:
        filterState.taskNum === null
          ? filterState.taskNum
          : parseInt(filterState?.taskNum),
      assignedDateGte: filterState.assignedDateGte,
      assignedDateLt: nextDate(filterState.assignedDateGte),
      createdDateGte: filterState.createdDateGte,
      createdDateLt: nextDate(filterState.createdDateGte),
      contractor: `%${filterState.contractor}%`,
      completedDateGte: filterState.completedDateGte,
      completedDateLt: nextDate(filterState.completedDateGte),
      dueDateGte: filterState.dueDateGte,
      dueDateLt: nextDate(filterState.dueDateGte),
      clientOrg: `%${filterState.client}%`,
      building: `%${filterState.building}%`,
      status: `%${filterState.status}%`,
      orderBy:
        Object.keys(orderBy).length === 0 ? { created_at: 'desc' } : orderBy,
      limit: ROWS_PER_PAGE,
      offset,
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    const areFiltersEmpty = Object.values(filterState).every(
      (value) => value === null || value === ''
    );

    if (areFiltersEmpty) {
      setIsFilter(false);
    }
  }, [filterState]);

  const handlePageChange = (page: number) => {
    setControlledPage(page);
    const currentOffset = page === 0 ? 0 : page * ROWS_PER_PAGE;
    setOffset(currentOffset);
  };

  function handleRowClick({ values }: Row) {
    const { id, org_id } = values;
    const newSpaceID = data?.thing_things?.find((c: any) => c.id === values.id);

    history.push(
      `/organisation/${org_id}/spaces/${newSpaceID.space.space_id}/things/${id}`
    );
  }

  function handleClearFilter() {
    setInputFilter(true);
    setFilterState({
      taskNum: null,
      client: '',
      building: '',
      contractor: '',
      assignedDateGte: null,
      assignedDateLt: null,
      completedDateGte: null,
      completedDateLt: null,
      dueDateGte: null,
      dueDateLt: null,
      createdDateGte: null,
      createdDateLt: null,
      status: '',
    });
    setIsFilter(false);
  }
  function handleSort(sortArray: []) {
    const orderObj = sortArray.reduce(
      (acc, sort: { id: string; desc: string | number }) => {
        let { id, desc } = sort;

        let idArr = id.split('.');
        let sortDir = desc ? 'desc' : 'asc';
        let value = idArr.length > 1 ? { [idArr[1]]: sortDir } : sortDir;
        return { ...acc, [idArr[0]]: value };
      },
      {}
    );
    setOrderBy(orderObj);
  }

  const handleChange = useAsyncDebounce((filterValue, columnHeader) => {
    setInputFilter(false);
    setOffset(0);
    setIsFilter(true);
    setControlledPage(0);
    const headerToKeyMap = {
      'TASK ID': 'taskNum',
      CLIENT: 'client',
      BUILDING: 'building',
      CONTRACTOR: 'contractor',
      ASSIGNED: 'assignedDateGte',
      COMPLETED: 'completedDateGte',
      DUE: 'dueDateGte',
      CREATED: 'createdDateGte',
      STATUS: 'status',
      // Add more mappings as needed
    };

    // Map the columnHeader to the corresponding key in filterState
    const columnId = headerToKeyMap[columnHeader] || '';

    // Update the filterState object with the new value for the current column
    setFilterState((prevFilterState) => ({
      ...prevFilterState,
      [columnId]: filterValue,
    }));
  }, 200);

  return (
    <>
      {/* <SnackMessage message={deleteError?.message} type="error" /> */}
      <SnackMessage message={deleteSuccessMessage} type="success" />

      <Header>
        <Title heading="Browse Prism Installs" />
        <div className={classes.createThing}>
          <IconLabelButton
            item={'Add Prism Install'}
            onClick={() => {
              setUpdate(false);
              setOpenMultiEditForm(!openMultiEditForm);
            }}
            open={openForm}
          />
        </div>
      </Header>

      <CreatePrismInstall
        closeForm={setOpenMultiEditForm}
        openForm={openMultiEditForm}
        searchFilters={{
          taskNum: filterState.taskNum,
          assignedDateGte: filterState.assignedDateGte,
          assignedDateLt: nextDate(filterState.assignedDateGte),
          createdDateGte: filterState.createdDateGte,
          createdDateLt: nextDate(filterState.createdDateGte),
          contractor_org: `%${filterState.contractor}%`,
          completedDateGte: filterState.completedDateGte,
          completedDateLt: nextDate(filterState.completedDateGte),
          dueDateGte: filterState.dueDateGte,
          dueDateLt: nextDate(filterState.dueDateGte),
          clientOrg: `%${filterState.client}%`,
          building: `%${filterState.building}%`,
          status: `%${filterState.status}%`,
          orderBy:
            Object.keys(orderBy).length === 0
              ? { created_at: 'desc' }
              : orderBy,
          limit: ROWS_PER_PAGE,
          offset,
        }}
      />

      <PrismInstallTable
        loading={loading}
        filterState={filterState}
        setFilterState={setFilterState}
        handleChange={handleChange}
        columns={GLOBAL_PRISM_COLUMNS}
        data={data?.task_tasks || []}
        onSort={handleSort}
        count={data?.task_tasks_aggregate?.aggregate?.count}
        manualPagination
        manualSorting
        pageSize={ROWS_PER_PAGE}
        onChangePage={handlePageChange}
        //onRowClick={handleRowClick}
        offset={offset}
        handleClearFilter={handleClearFilter}
        isFilter={isFilter}
        inputFilter={inputFilter}
        // onDeleteRows={handleDelete}

        controlledPageIndex={controlledPageIndex}
      />
      {/* <pre>{JSON.stringify(filterState, null, 4)}</pre> */}
    </>
  );
}
