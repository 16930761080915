import { gql, useQuery } from "@apollo/client";
import { RouteComponentProps } from "react-router-dom";

import Title from "components/Title";

type Params = {
  id: string;
};

const GET_ENERGY_METER_HARDWARE_ID = gql`
  query getEnergyMeterInstance($id: uuid!) {
    energy_meter_instance_by_pk(id: $id) {
      id
      hardware_id
    }
  }
`;

const EnergyMeterCrumb:React.FC<RouteComponentProps<Params>> = ({ match }) => {

  const { id } = match.params;

  const { data } = useQuery(GET_ENERGY_METER_HARDWARE_ID, {
    variables: { id },
    fetchPolicy: 'cache-first'
  });

  return (
    <Title heading={data?.energy_meter_instance_by_pk?.hardware_id}/>
  )
};

export default EnergyMeterCrumb;