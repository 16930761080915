import { useState } from 'react';
import { Box, FormLabel, TextField, styled } from '@material-ui/core';
import {
  gql,
  PureQueryOptions,
  RefetchQueriesFunction,
  useMutation,
  useQuery,
} from '@apollo/client';
import Form from './Form';
import SnackMessage from './SnackMessage';
import useForm from '../hooks/useForm';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { SENSOR_NODE_MEASUREMENT_CATEGORY } from 'pages/measurements/sensor_node_category';
import { SENSOR_NODE_MEASUREMENT_TYPE } from 'pages/measurements/sensor_node_type';
import { sortDataByAlpha, trimmedFields } from 'utils/helpers';

const ADD_MEASUREMENT_TYPE = gql`
  mutation add_measurement_spec_one(
    $category_id: uuid
    $description: String
    $maximum: numeric
    $minimum: numeric
    $name: String
    $type_id: uuid
    $unit: String
  ) {
    insert_measurement_spec_one(
      object: {
        category_id: $category_id
        description: $description
        maximum: $maximum
        minimum: $minimum
        name: $name
        type_id: $type_id
        unit: $unit
      }
    ) {
      description
      id
      maximum
      minimum
      name
      type_id
      unit
      category_id
    }
  }
`;

type Query = {
  query: RefetchQueriesFunction | (string | PureQueryOptions)[] | undefined;
};

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-around',
  margin: theme.spacing(2),
  transition: 'all .5s',
}));

export default function CreateMeasurementSpec({ query }: Query) {
  const { inputs, handleChange, clearForm } = useForm({
    name: '',
    description: '',
    maximum: '',
    minimum: '',
    unit: '',
    category_id: null,
    type_id: null,
  });

  const [categoryID, setCategoryID] = useState(null);
  const [typeID, setTypeID] = useState(null);

  const { data: categoryData } = useQuery(SENSOR_NODE_MEASUREMENT_CATEGORY, {
    onCompleted: (categoryData) => setCategoryID(categoryData?.id),
  });

  const { data: typeData } = useQuery(SENSOR_NODE_MEASUREMENT_TYPE, {
    onCompleted: (typeData) => setTypeID(typeData?.id),
  });

  const [AddType, { loading, error }] = useMutation(ADD_MEASUREMENT_TYPE, {
    variables: {
      ...trimmedFields(inputs),
      category_id: categoryID,
      type_id: typeID,
    },
    errorPolicy: 'all',
    refetchQueries: query,
    onCompleted: ({ insert_measurement_spec_one }) => {
      setSuccessMessage(
        `${insert_measurement_spec_one?.name} added successfully!`
      );
    },
  });

  const [successMessage, setSuccessMessage] = useState('');

  async function handleSubmit() {
    await AddType();
    clearForm();
  }

  const categoryDataOptions = categoryData?.measurement_category;
  const typeDataOptions = typeData?.measurement_type;

  return (
    <StyledBox>
      <SnackMessage message={error?.message} type="error" />
      <SnackMessage message={successMessage} type="success" />
      <Form onSubmit={handleSubmit} busy={loading}>
        <FormLabel>ADD SPEC</FormLabel>
        <TextField
          name="name"
          label="name"
          variant="filled"
          fullWidth
          disabled={loading}
          value={inputs.name}
          onChange={handleChange}
          autoComplete="new-password"
        />
        <TextField
          name="description"
          label="description"
          variant="filled"
          fullWidth
          disabled={loading}
          value={inputs.description}
          onChange={handleChange}
          autoComplete="new-password"
        />
        <TextField
          name="maximum"
          label="maximum"
          variant="filled"
          fullWidth
          disabled={loading}
          value={inputs.maximum}
          onChange={handleChange}
          autoComplete="new-password"
        />
        <TextField
          name="minimum"
          label="minimum"
          variant="filled"
          fullWidth
          disabled={loading}
          value={inputs.minimum}
          onChange={handleChange}
          autoComplete="new-password"
        />
        <TextField
          name="unit"
          label="unit"
          variant="filled"
          fullWidth
          disabled={loading}
          value={inputs.unit}
          onChange={handleChange}
          autoComplete="new-password"
        />

        {categoryData && (
          <Autocomplete
            onChange={(event: any, newValue: string | null | any) => {
              setCategoryID(newValue?.id);
            }}
            fullWidth
            id="controllable-states-measurement-1"
            getOptionLabel={(option: any) => option.name}
            options={sortDataByAlpha([...categoryDataOptions])}
            renderInput={(params: any) => (
              <TextField
                {...params}
                nputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password',
                }}
                label="category"
                variant="outlined"
              />
            )}
          />
        )}

        {typeData && (
          <Autocomplete
            onChange={(event: any, newValue: string | null | any) => {
              setTypeID(newValue?.id);
            }}
            fullWidth
            id="controllable-states-spec"
            getOptionLabel={(option: any) => option.name}
            options={sortDataByAlpha([...typeDataOptions])}
            renderInput={(params: any) => (
              <TextField
                {...params}
                nputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password',
                }}
                label="type"
                variant="outlined"
              />
            )}
          />
        )}
      </Form>
    </StyledBox>
  );
}
