import { gql } from '@apollo/client';

// Queries

export const GET_CLIENTS = gql`
  query {
    organisation_clients {
      id
      name
    }
  }
`;

export const GET_ORGANISATIONS_BY_TYPE = gql`
  query orgByType($type_id: uuid) {
    organisation(where: { type_id: { _eq: $type_id } }) {
      id
      name
    }
  }
`;

// export const GET_CLIENTS = gql`
//   query orgByClient {
//     organisation_clients {
//       name
//       id
//     }
//   }
// `;
export const GET_CONTRACTORS = gql`
  query orgByContractor {
    organisation_contractors {
      name

      id
    }
  }
`;

export const GET_TYPES_OF_ORGANISATIONS = gql`
  query orgType {
    organisation_type {
      id
      name
    }
  }
`;

export const GET_CLIENT_BUILDINGS = gql`
  query getRoots($org_id: uuid!) {
    spaces(where: { org_id: { _eq: $org_id }, parent_id: { _is_null: true } }) {
      id
      name
    }
  }
`;

export const GET_ALL_SPACES_FOR_BUILDING = gql`
  query getAllSpacesForBuilding($root_id: String!, $building_id: uuid!) {
    spaces(
      where: {
        _or: [
          { id: { _eq: $building_id } }
          { annotations: { key: { _eq: "root_id" }, value: { _eq: $root_id } } }
        ]
      }
    ) {
      id
      name
      parent_id
      things {
        thing {
          id
          name
        }
      }
    }
  }
`;
