import {
  createMuiTheme,
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core/styles';

const colors = {
  green: 'rgb(109, 194, 179)',
  black: '#222',
  red: 'rgba(255,0,0,0.5)',
};

const theme = createMuiTheme({
  typography: {
    h1: {
      fontFamily: ['"Lato"', 'Roboto'].join(','),
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '.MuiListItemText-root': {
          color: 'white',
        },
      },
    },
  },
  palette: {
    primary: {
      main: colors.black,
      light: '#444',
      dark: '#000',
    },
    secondary: {
      main: colors.green,
    },
    error: {
      main: colors.red,
    },
    // info: {
    //   // main: colors.black
    // },
    divider: colors.green,
    action: {
      hover: colors.green,
    },
  },
});

export const useThemeStyles = makeStyles((theme: Theme) =>
  createStyles({
    // detail drill down styles
    detailWrapper: {
      display: 'flex',
      flexDirection: 'row',
    },
    autocomplete: {
      width: 150,
    },
    autocompleteMainUser: {
      width: '100%',
    },
    search: {
      display: 'flex',
      flexDirection: 'row',
      borderRadius: 4,
      alignItems: 'center',
      width: 'auto',
      justifyContent: 'stretch',
      backgroundColor: 'whitesmoke',
      boxSizing: 'border-box',

      '&:hover': {
        backgroundColor: 'aliceblue',
      },
    },
    detailsTop: {
      marginBottom: theme.spacing(2),
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    maxWidthTransition: {
      flexGrow: 1,
      transition: 'max-width .1s linear',
    },
    tableSmall: {
      minWidth: '60em',
      maxWidth: '60em',
      maxHeight: 'calc(100vh - 200px)',
      overflowX: 'auto',
      marginRight: theme.spacing(2),
    },
    tableLarge: {
      maxWidth: '100%',
    },
    contentOpen: {
      maxWidth: '100%',
    },
    contentClosed: {
      maxWidth: 0,
    },
    margin: {
      margin: theme.spacing(1),
    },
    padding: {
      padding: theme.spacing(2),
    },
    // paddingBuilding: {
    //   padding: theme.spacing(4),
    // },
  })
);

export default theme;
