import React, { Fragment, useEffect, useRef, useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { createStyles, lighten, styled, Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { GET_CLIENTS } from 'graphql/common-queries';
import SnackMessage from 'components/SnackMessage';
import { Box, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { IIndexable } from 'types';
import { StyledTextField } from './StyledComponent';
import { SENSOR_NODES_QUERY } from 'pages/sensor-nodes';

type Props = {
  id: string;
  name: string;
  model: string;
  description: string;
  offset: Number;
  limit: number;
  orderBy: Object;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      width: '60%',
      alignContent: 'center',
      justifyContent: 'center',
      padding: theme.spacing(1),
      '& .MuiInputBase-root': {
        marginBottom: theme.spacing(1),
      },
      '& .MuiFormLabel-root': {
        marginBottom: theme.spacing(1),
      },
    },
    footer: {
      display: 'flex',
      width: '100%',
      backgroundColor: lighten(theme.palette.secondary.main, 0.85),
      padding: theme.spacing(1),
      justifyContent: 'flex-end',
    },
  })
);

const PaperWrapper = styled(Paper)(({ theme }) => ({
  borderTop: '5px solid',
  borderColor: theme.palette.secondary.main,
}));

export const UPDATE_SENSORNODE = gql`
  mutation updateSensorNode(
    $update_name: String
    $id: uuid!
    $update_model: String
    $update_description: String
  ) {
    update_sensor_node_nodes_by_pk(
      pk_columns: { id: $id }
      _set: {
        name: $update_name
        model: $update_model
        description: $update_description
      }
    ) {
      model
      name
      description
      id
    }
  }
`;

export default function Update({
  id,
  name,
  model,
  description,
  orderBy,
  limit,
  offset,
}: Props) {
  const classes: IIndexable = useStyles();

  const input = useRef<HTMLInputElement>(null);
  const [updateName, setUpdateName] = useState(name);
  const [updateModel, setUpdateModel] = useState(model);
  const [updateDescription, setUpdateDescription] = useState(description);
  const [updateSuccessMessage, setUpdateSuccessMessage] = useState('');

  // If value is changed externally sync it here
  useEffect(() => {
    setUpdateName(name);
  }, [name]);

  useEffect(() => {
    setUpdateDescription(description);
  }, [description]);

  useEffect(() => {
    setUpdateModel(model);
  }, [model]);

  const [updateSensorNode, { loading, error: updateError }] = useMutation(
    UPDATE_SENSORNODE,
    {
      variables: {
        id,
        update_name: updateName.trim(),
        update_model: updateModel.trim(),
        update_description: updateDescription.trim(),
      },
      update(
        cache,
        {
          data: {
            update_sensor_node_nodes_by_pk: { id, name, model, description },
          },
        }
      ) {
        cache.writeQuery({
          query: SENSOR_NODES_QUERY,
          variables: {
            offset,
            limit,
            name: `%${name}%`,
            orderBy,
          },
          data: {
            id,
            name,
            model,
            description,
          },
        });
      },
      onCompleted: ({ update_sensor_node_nodes_by_pk }) => {
        setUpdateName((prevVal) => prevVal?.trim());
        setUpdateModel((prevVal) => prevVal?.trim());
        setUpdateDescription((prevVal) => prevVal?.trim());
        setUpdateSuccessMessage(
          `${update_sensor_node_nodes_by_pk.name} updated successfully!`
        );
      },
    }
  );

  function handleKeyPress(e: React.KeyboardEvent) {
    if (e.code === 'Enter') {
      updateSensorNode();
      input?.current?.blur();
    }
  }

  function handleSaveButton() {
    updateSensorNode();
    input?.current?.blur();
  }
  return (
    <Fragment>
      <PaperWrapper elevation={3} square>
        <SnackMessage message={updateError?.message} type="error" />
        <SnackMessage
          message={updateSuccessMessage}
          onClose={() => setUpdateSuccessMessage('')}
          type="success"
        />

        <StyledTextField
          inputProps={{ ref: input }}
          style={{
            display: 'flex',
            width: '80%',
            marginBottom: 20,
            marginTop: 20,
            justifyContent: 'center',
            marginLeft: '10%',
          }}
          name={updateName}
          value={updateName}
          onChange={(e) => setUpdateName(e.target.value)}
          onKeyPress={handleKeyPress}
          disabled={loading}
          variant="filled"
          label="NAME"
        />

        <StyledTextField
          inputProps={{ ref: input }}
          style={{
            display: 'flex',
            width: '80%',
            marginBottom: 20,
            marginTop: 20,
            justifyContent: 'center',
            marginLeft: '10%',
          }}
          name={updateModel}
          value={updateModel}
          onChange={(e) => setUpdateModel(e.target.value)}
          onKeyPress={handleKeyPress}
          disabled={loading}
          variant="filled"
          label="MODEL"
        />

        <StyledTextField
          inputProps={{ ref: input }}
          style={{
            display: 'flex',
            width: '80%',
            marginBottom: 20,
            marginTop: 20,
            justifyContent: 'center',
            marginLeft: '10%',
          }}
          name={updateDescription}
          value={updateDescription}
          onChange={(e) => setUpdateDescription(e.target.value)}
          onKeyPress={handleKeyPress}
          disabled={loading}
          variant="filled"
          label="DESCRIPTION"
        />
        <div className={classes.footer}>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleSaveButton}
          >
            Save
          </Button>
        </div>
      </PaperWrapper>
    </Fragment>
  );
}
