import React, { Fragment, useEffect, useRef, useState } from 'react';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { createStyles, lighten, styled, Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import SnackMessage from 'components/SnackMessage';
import { Paper, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Entity, IIndexable } from 'types';
import { StyledTextField } from './StyledComponent';
import { sortDataByAlpha } from 'utils/helpers';

import Autocomplete from '@material-ui/lab/Autocomplete';
import { GET_ALL_SPACES_FOR_BUILDING } from 'graphql/common-queries';

import { Sensor } from 'types';
import { useAuth } from 'hooks/useAuth';
import { GET_ENERGY_METER_INSTANCE } from 'pages/energy-meter/instance';

type Props = {
  channel: Sensor | null;
  onDone: () => void;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      alignContent: 'center',
      justifyContent: 'center',
      padding: theme.spacing(1),
      '& .MuiInputBase-root': {
        marginBottom: theme.spacing(1),
      },
      '& .MuiFormLabel-root': {
        marginBottom: theme.spacing(1),
      },
    },

    autocomplete: {
      width: '80%',
      marginBottom: 20,
      marginLeft: '10%',
      marginRight: 20,
    },
    footer: {
      display: 'flex',
      width: '100%',
      backgroundColor: lighten(theme.palette.secondary.main, 0.85),
      padding: theme.spacing(1),
      justifyContent: 'flex-end',
    },
  })
);

const PaperWrapper = styled(Paper)(({ theme }) => ({
  borderTop: '5px solid',
  borderColor: theme.palette.secondary.main,
}));

const UPDATE_CHANNEL = gql`
  mutation UpsertChannel(
    $updateAnnotObject: [energy_meter_channel_instance_annotations_insert_input!]!
    $name: String
  ) {
    insert_energy_meter_channel_instance_annotations(
      objects: $updateAnnotObject
      on_conflict: {
        constraint: channel_instance_annotations_pkey
        update_columns: value
      }
    ) {
      returning {
        channel_instance_id
        created_by
        creqated_at
        id
        key
        org_id
        type
      }
    }
  }
`;

const DELETE_CHANNEL_ANNOTATIONS = gql`
  mutation deleteChannelAnnotation($ids: [uuid!]!) {
    delete_energy_meter_channel_instance_annotations(
      where: { id: { _in: $ids } }
    ) {
      returning {
        id
        key
      }
    }
  }
`;

export default function UPDATECHANNEL({ channel, onDone }: Props) {
  const classes: IIndexable = useStyles();

  const { user } = useAuth();

  const created_by = user?.sub;

  const input = useRef<HTMLInputElement>(null);
  const {
    org_id,
    name,
    annotations,
    id: channel_id,
    energy_meter_instance_id,
  } = channel as any;

  //debugger;
  const root_idValue = annotations?.find(
    (c: any) => c.key === 'root_id'
  )?.value;

  const defaultSpaceAnnotation = annotations?.find(
    (c: any) => c.key === 'space_id'
  );

  const defaultThingAnnotation = annotations?.find(
    (c: any) => c.key === 'thing_id'
  );

  const [updateSuccessMessage, setUpdateSuccessMessage] = useState('');
  const [updateName, setUpdateName] = useState(name);

  const [updateSpace, setUpdateSpace] = useState({
    id: defaultSpaceAnnotation?.value,
    name: '',
  });
  const [updateThing, setUpdateThing] = useState({
    id: defaultThingAnnotation?.value,
    name: '',
  });
  const [things, setThings] = useState<any[]>([]);

  const [getSpaces, { data: spacesData }] = useLazyQuery(
    GET_ALL_SPACES_FOR_BUILDING,
    {
      variables: {
        root_id: root_idValue,
        building_id: root_idValue,
      },

      fetchPolicy: 'no-cache',
    }
  );

  useEffect(() => {
    getSpaces();
  }, [root_idValue]);

  useEffect(() => {
    setUpdateSpace(updateSpace);
  }, [updateSpace, root_idValue]);

  useEffect(() => {
    if (updateSpace) {
      setThings(
        spacesData?.spaces?.find(({ id }: Entity) => id === updateSpace.id)
          ?.things
      );
    }
  }, [updateSpace, spacesData?.spaces, things, updateThing]);

  const [deleteAnnotations, { loading: deleteLoading, error: deleteError }] =
    useMutation(DELETE_CHANNEL_ANNOTATIONS);

  const [updateChannel, { loading, error: updateError }] = useMutation(
    UPDATE_CHANNEL,
    {
      onCompleted: ({ insert_energy_meter_channel_instance_annotations }) => {
        onDone();
        setUpdateSuccessMessage(
          `${insert_energy_meter_channel_instance_annotations.key} updated successfully!`
        );
      },

      refetchQueries: [
        {
          query: GET_ENERGY_METER_INSTANCE,
          variables: {
            id: energy_meter_instance_id,
          },
        },
      ],
    }
  );

  function Save() {
    const object1: Record<string, any> = {
      root_id: root_idValue,
    };
    const deleteAnnotationIds: string[] = [];

    if (updateSpace.id) {
      object1.space_id = updateSpace.id;
    } else {
      defaultSpaceAnnotation &&
        deleteAnnotationIds.push(defaultSpaceAnnotation.id);
    }

    if (updateThing.id) {
      object1.thing_id = updateThing.id;
    } else {
      defaultThingAnnotation &&
        deleteAnnotationIds.push(defaultThingAnnotation.id);
    }

    const arry1 = Object.keys(object1).map((key) => {
      const obj = {
        channel_instance_id: channel_id,
        created_by,
        key,
        org_id,
        type: 'system',
        value: object1[key],
      };
      const id = annotations?.find((c: any) => c.key === key)?.id;

      if (id) {
        obj['id'] = id;
      }
      return obj;
    });

    updateChannel({
      variables: {
        updateAnnotObject: arry1,
      },
    });

    deleteAnnotationIds.length > 0 &&
      deleteAnnotations({
        variables: {
          ids: deleteAnnotationIds,
        },
      });

    input?.current?.blur();
  }

  function handleKeyPress(e: React.KeyboardEvent) {
    if (e.code === 'Enter') {
      Save();
    }
  }

  function handleSaveButton() {
    Save();
  }
  const spaceData = spacesData?.spaces;

  const spaceSortedOptions = spacesData && sortDataByAlpha([...spaceData]);

  //const thingsSortedUptions = things;
  const thingsSortedUptions = things?.sort((a, b) =>
    a.thing.name.localeCompare(b.thing.name)
  );

  return (
    <Fragment>
      <PaperWrapper elevation={3} square>
        <SnackMessage message={updateError?.message} type="error" />
        <SnackMessage
          message={updateSuccessMessage}
          onClose={() => setUpdateSuccessMessage('')}
          type="success"
        />

        <StyledTextField
          inputProps={{ ref: input }}
          style={{
            display: 'flex',
            width: '80%',
            marginBottom: 20,
            marginTop: 20,
            justifyContent: 'center',
            marginLeft: '10%',
          }}
          name={updateName}
          value={updateName}
          onKeyPress={handleKeyPress}
          disabled={true}
          variant="filled"
          label="NAME"
        />

        {spacesData && (
          <Autocomplete
            className={classes.autocomplete}
            options={spaceSortedOptions || []}
            getOptionLabel={(option: any) => {
              return (
                spacesData?.spaces?.find(
                  (space: Entity) => space.id === option.id
                )?.name || ''
              );
            }}
            onChange={(event: any, newValue: string | any) => {
              setUpdateSpace({
                id: newValue?.id,
                name: newValue?.name,
              });
              setUpdateThing({
                id: null,
                name: '',
              });
            }}
            value={updateSpace}
            renderInput={(params) => (
              <TextField {...params} label="Space" variant="filled" />
            )}
          />
        )}

        {spacesData && updateSpace && updateThing && things && (
          <Autocomplete
            className={classes.autocomplete}
            id="thing-select-label"
            options={thingsSortedUptions || []}
            getOptionLabel={(option: any) => {
              return (
                things?.find(
                  (item: any) =>
                    item?.thing?.id === (option.id || option?.thing?.id)
                )?.thing?.name || ''
              );
            }}
            onChange={(event: any, newValue: string | any) => {
              setUpdateThing({
                id: newValue?.thing?.id,
                name: newValue?.thing?.name,
              });
            }}
            value={updateThing}
            renderInput={(params) => (
              <TextField {...params} label="Thing" variant="filled" />
            )}
          />
        )}
        <div className={classes.footer}>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => handleSaveButton()}
            disabled={!updateSpace.id || !updateThing.id}
          >
            Save
          </Button>
        </div>
      </PaperWrapper>
    </Fragment>
  );
}
