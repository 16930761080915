import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import clsx from 'clsx';
import { Box, Typography } from '@material-ui/core';

type HeaderProps = {
  heading: string;
};
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    // justifyContent: 'center',
    // justifyItems: 'center',
    alignItems: 'center',
  },
  font: {
    fontSize: 20,
    fontWeight: 100,
    fontFamily: 'Roboto',
  },
}));

const Title = ({ heading }: HeaderProps) => {
  const classes = useStyles();
  return (
    <div className={clsx(classes.font, classes.root)}>
      <Typography variant="h1">
        <Box className={classes.font}>{heading}</Box>
      </Typography>
    </div>
  );
};

export default Title;
