import { useEffect, useRef, useState } from 'react';
import { Box, FormLabel, TextField, styled } from '@material-ui/core';
import { gql, useMutation, useQuery } from '@apollo/client';
import Form from './Form';
import SnackMessage from './SnackMessage';
import useForm from '../hooks/useForm';

import { GET_AGGREGATE_ORG, GET_CLIENTS_ORG } from 'pages/orgs';

import { trimmedFields } from 'utils/helpers';
import {
  GET_CONTRACTORS,
  GET_AGGREGATE_ORG as GET_CONTRACTORS_AGG,
} from 'pages/contractor-org';
import { GET_ORGANISATIONS_BY_TYPE } from 'graphql/common-queries';

const ADD_ORG = gql`
  mutation AddOrg($name: String!, $type_id: uuid!) {
    insert_one_organisation(object: { name: $name, type_id: $type_id }) {
      id
      name
    }
  }
`;

type InProp = {
  checked: boolean;
  searchFilters: any;
  type_id?: string;
};

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-around',
  margin: theme.spacing(2),
  transition: 'all .5s',
}));

export default function CreateOrg({ checked, searchFilters, type_id }: InProp) {
  const { inputs, handleChange, clearForm } = useForm({ name: '' });

  const [helperTextOrg, setHelperTextOrg] = useState('');
  const [errorTextOrg, setErrorTextOrg] = useState(false);
  const [duplicateOrgFlag, setDuplicateOrgFlag] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    checked &&
      setTimeout(() => {
        inputRef.current?.focus();
      }, 100);
  }, [checked]);

  const { data: orgData } = useQuery(GET_ORGANISATIONS_BY_TYPE, {
    variables: {
      type_id,
    },
    fetchPolicy: 'network-only',
  });

  const [addOrg, { loading, error }] = useMutation(ADD_ORG, {
    variables: {
      type_id: type_id,
      ...trimmedFields(inputs),
    },
    errorPolicy: 'all',
    refetchQueries: [
      {
        query: GET_CONTRACTORS,
        variables: { ...searchFilters },
      },
      {
        query: GET_CLIENTS_ORG,
        variables: { ...searchFilters },
      },
      {
        query: GET_ORGANISATIONS_BY_TYPE,
        variables: { type_id },
      },
      {
        query: GET_AGGREGATE_ORG,
        variables: { ...searchFilters },
      },
      { query: GET_CONTRACTORS_AGG, variables: { ...searchFilters } },
    ],

    onCompleted: ({ insert_one_organisation }) => {
      setSuccessMessage(`${insert_one_organisation.name} added successfully!`);
    },
  });

  useEffect(() => {
    const isOrgNameExists = orgData?.organisation?.find((org) => {
      return org?.name.toLowerCase() === inputs?.name?.toString().toLowerCase();
    });

    if (isOrgNameExists) {
      setDuplicateOrgFlag(true);
      setErrorTextOrg(true);
      setHelperTextOrg(`Name already exists. Please choose a different name`);
    } else {
      setErrorTextOrg(false);
      setDuplicateOrgFlag(false);
      setHelperTextOrg('');
    }
  }, [inputs.name, duplicateOrgFlag, orgData, type_id]);

  const [successMessage, setSuccessMessage] = useState('');

  async function handleSubmit() {
    if (!duplicateOrgFlag) {
      await addOrg();
      clearForm();
      setHelperTextOrg('');
      setErrorTextOrg(false);
      setDuplicateOrgFlag(false);
    }
  }

  return (
    <StyledBox>
      <SnackMessage message={error?.message} type="error" />
      <SnackMessage message={successMessage} type="success" />
      <Form
        onSubmit={handleSubmit}
        busy={loading}
        inComplete={!inputs.name || duplicateOrgFlag}
      >
        <FormLabel>Add Organisation</FormLabel>
        <TextField
          name="name"
          label="name"
          variant="filled"
          fullWidth
          disabled={loading}
          value={inputs.name}
          onChange={handleChange}
          inputRef={inputRef}
          helperText={helperTextOrg}
          error={errorTextOrg}
        />
      </Form>
    </StyledBox>
  );
}
