import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { GET_ENERGY_METER_COUNT } from 'pages/energy-meter';
import { GET_AGGREGATE_DATA } from 'pages/spaces';
import { THINGS_COUNT_QUERY } from 'pages/things';
import { cursorPagination } from './cursorPagination';

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_URL,
});

const authLink = setContext((_, { headers }) => {
  // return the headers to the context so httpLink can read them

  const token = localStorage.getItem('token');

  return {
    headers: {
      ...headers,
      Authorization: `Bearer ${token && JSON.parse(token)}`,
    },
    fetchOptions: {
      credentials: 'same-origin',
    },
  };
});

function getHardwareFilter(where: any) {
  return {
    hardware_id: {
      value: where.hardware_id?._ilike,
      type: 'ilike',
    },
  };
}

function getSpaceFilter(where: any) {
  return {
    name: {
      value: where.name?._ilike,
      type: 'ilike',
    },
    org_id: {
      value: where.org_id?._eq,
      type: 'eq',
    },
  };
}

function getNameFilter(where: any) {
  return {
    name: {
      value: where.name?._ilike,
      type: 'ilike',
    },
  };
}

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
  //   {
  //   typePolicies: {
  //     Query: {
  //       fields: {
  //         energy_meter_instance: cursorPagination(
  //           GET_ENERGY_METER_COUNT,
  //           'energy_meter_instance_aggregate',
  //           'created_at',
  //           getHardwareFilter
  //         ),
  //         spaces: cursorPagination(
  //           GET_AGGREGATE_DATA,
  //           'rowcount',
  //           'created_date',
  //           getSpaceFilter
  //         ),
  //         // thing_thing: cursorPagination(
  //         //   GET_SPACE,
  //         //   'things_rowcount',
  //         //   'created_date',
  //         //   getSpaceFilter
  //         // ),
  //         thing_things: cursorPagination(
  //           THINGS_COUNT_QUERY,
  //           'rowcount',
  //           'created_date',
  //           getNameFilter
  //         ),
  //       },
  //     },
  //   },
  // }
});

export default client;
