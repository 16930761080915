import { gql, useQuery } from "@apollo/client";
import { RouteComponentProps } from "react-router-dom";

import Title from "components/Title";

type Params = {
  id: string;
};

const GET_SENSOR_NODE = gql`
  query getThing($id: uuid!) {
    sensor_node_nodes_by_pk(id: $id) {
      id
      name
    }
  }
`;

const SensorNodeCrumb:React.FC<RouteComponentProps<Params>> = ({ match }) => {

  const { id } = match.params;

  const { data } = useQuery(GET_SENSOR_NODE, {
    variables: { id },
    fetchPolicy: 'cache-first'
  });

  return (
    <Title heading={data?.sensor_node_nodes_by_pk?.name}/>
  )
};

export default SensorNodeCrumb;