import { Fragment, useEffect, useMemo, useState } from 'react';
import {
  useTable,
  Column,
  useRowSelect,
  HeaderGroup,
  useSortBy,
  SortingRule,
  Row,
  usePagination,
  useGlobalFilter,
  useAsyncDebounce,
} from 'react-table';
import {
  withStyles,
  Theme,
  createStyles,
  lighten,
  makeStyles,
} from '@material-ui/core/styles';

import {
  TableContainer,
  TableHead,
  TableRow,
  Table,
  TableBody,
  TableCell,
  Checkbox,
  Paper,
  TablePagination,
  TableSortLabel,
  Toolbar,
  Typography,
  Tooltip,
  IconButton,
  TextField,
  Button,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import clsx from 'clsx';

import LinearLoader from './LinearLoader';
import { AddIcon } from '@material-ui/data-grid';

type TableProps<T extends object> = {
  data: T[];
  columns: Column<T>[];
  loading?: boolean;
  count?: number;
  manualPagination?: boolean;
  selectedRow?: string | null;
  onChangePage?(newPageOrCursor: number | string, newPage?: number): void;
  onSort?(sortArray: SortingRule<T>[]): void;
  pageSize?: number;
  onRowClick?(row: Row<T>): void;
  title?: string;
  onSubscribeRows?(rows: Row<T>[]): void;
  onEditMultipleRows?(rows: Row<T>[]): void;
  onDeleteRows?(rows: Row<T>[]): void;
  onUpdateRow?(row: Row<T>): void | undefined;
  onEditRow?(row: Row<T>): void | undefined;
  onEditBuildingRow?(row: Row<T>): void | undefined;
  onSelectedRow?(row: Row<T>[]): void | undefined;
  globalSearch?: boolean;
  cursor?: string;
  controlledPageIndex?: number;
};

export const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      fontWeight: 'bold',
      '&:first-child': {
        width: 80,
      },
      '&:nth-child': {
        width: 20,
      },

      fontFamily: 'Roboto , Helvetica, Arial, sans-serif',
    },
    body: {
      fontFamily: 'Roboto , Helvetica, Arial, sans-serif',
      '&:first-child': {
        width: 80,
      },
      '&:nth-child': {
        width: 20,
      },
    },
  })
)(TableCell);

export const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: (props: { clickable?: boolean }) => ({
      '&:hover': {
        backgroundColor: lighten(theme.palette.secondary.main, 0.85),
        cursor: props.clickable ? 'pointer' : 'inherit',
      },
    }),
  })
)(TableRow);

const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    highlight: {
      color: theme.palette.secondary.main,
      backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      justifyContent: 'space-between',
    },
    tooltip: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    tipContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
  })
);

function CTableHead({ headerGroups }: any) {
  return (
    <TableHead>
      {headerGroups.map((headerGroup: HeaderGroup) => (
        <TableRow {...headerGroup.getHeaderGroupProps()}>
          {headerGroup.headers.map((column) => {
            const {
              id,
              canSort,
              isSorted,
              isSortedDesc,
              getSortByToggleProps,
              getHeaderProps,
              render,
            } = column;

            return (
              <StyledTableCell
                {...getHeaderProps(getSortByToggleProps)}
                component="th"
                padding={id === 'selection' ? 'checkbox' : 'default'}
              >
                {canSort ? (
                  <TableSortLabel
                    active={isSorted}
                    direction={isSortedDesc ? 'desc' : 'asc'}
                  >
                    {render('Header')}
                  </TableSortLabel>
                ) : (
                  render('Header')
                )}
              </StyledTableCell>
            );
          })}
        </TableRow>
      ))}
    </TableHead>
  );
}

type ToolbarProps<T extends object> = {
  title: string | undefined;
  onDeleteRows: (() => void) | undefined;
  selectedRows: string[];
  onSubscribeRows: (() => void) | undefined;
  onEditMultipleRows: (() => void) | undefined;
  // filter props
  preGlobalFilteredRows: Row<T>[];
  globalFilter: string;
  setGlobalFilter(value: string | undefined): void;
  showSearch?: boolean;
};

function CToolBar<T extends object>({
  title,
  onDeleteRows,
  selectedRows,
  setGlobalFilter,
  preGlobalFilteredRows,
  globalFilter,
  showSearch,
  onSubscribeRows,
  onEditMultipleRows,
}: ToolbarProps<T>) {
  const classes = useToolbarStyles();

  const [searchValue, setSearchValue] = useState(globalFilter);

  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <Toolbar className={clsx({ [classes.highlight]: selectedRows.length > 0 })}>
      {selectedRows.length > 0 ? (
        <>
          <div className={classes.tipContainer}>
            <Typography color="inherit" variant="subtitle1" component="div">
              {selectedRows.length} selected
            </Typography>
            {onDeleteRows ? (
              <Tooltip title="Delete" className={classes.tooltip}>
                <IconButton aria-label="delete" onClick={onDeleteRows}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            ) : (
              onSubscribeRows && (
                <>
                  <Tooltip title="Subscribe" className={classes.tooltip}>
                    <IconButton aria-label="select" onClick={onSubscribeRows}>
                      <AddIcon />
                    </IconButton>
                  </Tooltip>
                  <Typography variant="subtitle1" component="div">
                    Subscribe
                  </Typography>
                </>
              )
            )}{' '}
          </div>

          {selectedRows.length > 1 && onEditMultipleRows && (
            <Tooltip title="Edit" className={classes.tooltip}>
              {/* <div className={classes.button}> */}
              <Button
                variant="contained"
                color="primary"
                //  disabled={activeStep === 0}
                onClick={onEditMultipleRows}
              >
                Update Selected Things
              </Button>
              {/* </div> */}
            </Tooltip>
          )}
        </>
      ) : (
        <Fragment>
          <Typography variant="h6" id="tableTitle" component="div">
            {title}
          </Typography>
          {showSearch && (
            <TextField
              style={{ marginLeft: 20 }}
              value={searchValue}
              placeholder={`Search ${preGlobalFilteredRows.length} records...`}
              onChange={(e) => {
                onChange(e.target.value);
                setSearchValue(e.target.value);
              }}
            />
          )}
        </Fragment>
      )}
    </Toolbar>
  );
}

export default function CTable<T extends object>({
  columns,
  data,
  loading,
  count = 0,
  manualPagination,
  onChangePage,
  onSort,
  pageSize = 20,
  onRowClick,
  title,
  onDeleteRows,
  onUpdateRow,
  onSubscribeRows,
  onEditBuildingRow,
  onEditMultipleRows,
  onEditRow,
  selectedRow,
  onSelectedRow,
  globalSearch,
  cursor,
  controlledPageIndex, //Control the page index manually
}: TableProps<T>) {
  columns = useMemo(() => columns, []);
  data = useMemo(() => data, [data]);

  const {
    getTableProps,
    getTableBodyProps,
    setGlobalFilter,
    preGlobalFilteredRows,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    gotoPage,
    page,
    state: {
      sortBy,
      selectedRowIds,
      pageIndex,
      pageSize: statePageSize,
      globalFilter,
    },
  } = useTable<T>(
    {
      columns,
      data,
      initialState: {
        hiddenColumns: [
          'org_id',
          'id',
          'space_id',
          'thing_id',
          'parent_id',
          'type_id',
          'created_by',
          'category_id',
        ],
        pageSize,
        pageIndex: 0,
      },
      manualSortBy: true,
      disableSortBy: !onSort,
      manualPagination,
      onUpdateRow,
      onEditBuildingRow,
      pageCount: Math.ceil(count / pageSize),
      useControlledState: (state) => {
        return useMemo(
          () => ({
            ...state,
            ...(controlledPageIndex !== undefined
              ? { pageIndex: controlledPageIndex }
              : {}),
          }),
          [state, controlledPageIndex]
        );
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: 'selection',
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <Checkbox
                {...getToggleAllRowsSelectedProps()}
                inputProps={{ 'aria-label': 'select all' }}
              />
            </div>
          ),
          Cell: ({ row }: any) => (
            <div>
              <Checkbox {...row.getToggleRowSelectedProps({})} />
            </div>
          ),
        },
        ...columns,
      ]);
    }
  );

  const [pageCursors, setPageCursors] = useState<number[] | string[]>(['now']);

  function handlePageChange(event: unknown, newPage: number) {
    if (onChangePage) {
      if (cursor) {
        const newCursor =
          newPage > pageIndex
            ? rows[rows.length - 1].values[cursor]
            : pageCursors[newPage];
        // Store an array of cursors to use when navigating to previous pages
        setPageCursors((prev) => {
          let currentArr = prev.slice(0);
          currentArr[newPage] = newCursor;
          return currentArr;
        });
        onChangePage(newCursor, newPage);
      } else {
        onChangePage(newPage);
      }
    }
    gotoPage(newPage);
  }

  useEffect(() => {
    if (onSort) {
      onSort(sortBy);
    }
  }, [sortBy]);

  function handleDeleteRows() {
    if (onDeleteRows) {
      onDeleteRows(selectedFlatRows);
    }
  }

  function handleSubscribeRows() {
    if (onSubscribeRows) {
      onSubscribeRows(selectedFlatRows);
    }
  }

  function handleEditMultipleRows() {
    if (onEditMultipleRows) {
      onEditMultipleRows(selectedFlatRows);
    }
  }

  return (
    <Paper>
      <TableContainer {...getTableProps()}>
        {(title ||
          onDeleteRows ||
          globalSearch ||
          onSubscribeRows ||
          onEditMultipleRows) && (
          <CToolBar
            onDeleteRows={onDeleteRows && handleDeleteRows}
            onSubscribeRows={onSubscribeRows && handleSubscribeRows}
            onEditMultipleRows={onEditMultipleRows && handleEditMultipleRows}
            title={title}
            selectedRows={Object.keys(selectedRowIds)}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            preGlobalFilteredRows={preGlobalFilteredRows}
            showSearch={globalSearch}
          />
        )}
        <Table aria-label="customized table">
          <CTableHead headerGroups={headerGroups} />
          <TableBody {...getTableBodyProps}>
            {(count ? page : rows).map((row) => {
              prepareRow(row);
              return (
                <StyledTableRow
                  {...row.getRowProps()}
                  clickable={!!onRowClick}
                  selected={row.values.id === selectedRow}
                >
                  {row.cells.map((cell) => {
                    const isSelectColumn = cell.column.id === 'selection';
                    const isEditColumn = cell.column.id === 'edit';
                    const isUpdateColumn = cell.column.id === 'update';
                    const isEditBuilding = cell.column.id === 'subscribe';

                    return (
                      <StyledTableCell
                        {...cell.getCellProps()}
                        onClick={() => {
                          if (
                            onRowClick &&
                            !isSelectColumn &&
                            !isEditColumn &&
                            !isUpdateColumn &&
                            !isEditBuilding
                          )
                            onRowClick(row);
                          if (isEditColumn && onUpdateRow) onUpdateRow?.(row);
                          if (isUpdateColumn && onEditRow) onEditRow?.(row);
                          if (isEditBuilding && onEditBuildingRow)
                            onEditBuildingRow?.(row);
                          //  / if (selectedFlatRows) onSelectedRow?.(row);
                        }}
                        padding={
                          isSelectColumn ||
                          isEditColumn ||
                          isUpdateColumn ||
                          isEditBuilding
                            ? 'checkbox'
                            : 'default'
                        }
                      >
                        {cell.render('Cell')}
                      </StyledTableCell>
                    );
                  })}
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {loading && <LinearLoader />}
      {!!count && (
        <TablePagination
          component="div"
          count={count}
          page={pageIndex}
          onChangePage={handlePageChange}
          rowsPerPage={statePageSize}
          rowsPerPageOptions={[]}
        />
      )}
    </Paper>
  );
}
