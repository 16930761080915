import React, { Fragment, useEffect, useRef, useState } from 'react';
import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { createStyles, lighten, styled, Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import SnackMessage from 'components/SnackMessage';
import { Paper, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Entity, IIndexable } from 'types';
import { StyledTextField } from './StyledComponent';
import { GET_ENERGY_METERS } from 'pages/energy-meter';
import { GET_ENERGY_METER_INSTANCE } from 'pages/energy-meter/instance';
import Autocomplete from '@material-ui/lab/Autocomplete/Autocomplete';
import { GET_ALL_SPACES_FOR_BUILDING } from 'graphql/common-queries';
import { GET_ROOTS } from './CreateEnergyMeter';
import { useAuth } from 'hooks/useAuth';

type Props = {
  id: string;
  hardware_id?: string;
  limit: Number;
  cursor: Object;
  hardwareIdFilter: String;
  space_id?: string | null;
  building_id?: string | null;
  org_id?: string | null;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      width: '60%',
      alignContent: 'center',
      justifyContent: 'center',
      padding: theme.spacing(1),
      '& .MuiInputBase-root': {
        marginBottom: theme.spacing(1),
      },
      '& .MuiFormLabel-root': {
        marginBottom: theme.spacing(1),
      },
    },
    footer: {
      display: 'flex',
      width: '100%',
      backgroundColor: lighten(theme.palette.secondary.main, 0.85),
      padding: theme.spacing(1),
      justifyContent: 'flex-end',
    },
  })
);

const PaperWrapper = styled(Paper)(({ theme }) => ({
  borderTop: '5px solid',
  borderColor: theme.palette.secondary.main,
}));

// export const UPDATE_ENERGYMETER_INS = gql`
// mutation updateEnergyMeterIns($update_hardwareId: String, $id: uuid!) {
//   update_energy_meter_instance_by_pk(pk_columns: {id:$id}, _set: {hardware_id: $update_hardwareId}) {
//     id
//     hardware_id

//   }
// }
// `;

export const UPDATE_ENERGYMETER_INS = gql`
  mutation updateEnergyMeterIns($update_hardwareId: String, $id: uuid!) {
    update_energy_meter_instance_by_pk(
      pk_columns: { id: $id }
      _set: { hardware_id: $update_hardwareId }
    ) {
      id
      hardware_id
    }
  }
`;

// export const UPDATE_ENERGYMETER_INS = gql`
//   mutation upsertEnergyMeterIns(
//     $update_hardwareId: String
//     $id: uuid!
//     $key: String
//     $annotEMData: [energy_meter_instance_annotations_arr_rel_insert_input!]!
//     $value: String
//   ) {
//     insert_energy_meter_instance(
//       object: {
//         annotations: {
//           data: $annotEMData
//           on_conflict: {
//             constraint: instance_annotations_pkey
//             update_columns: $value
//             where: { _and: { key: { _in: "root_id" } } }
//           }
//         }
//         channels: {
//           data: {
//             annotations: {
//               data: {}
//               on_conflict: {
//                 constraint: channel_instance_annotations_pkey
//                 update_columns: channel_instance_id
//                 where: { key: { _in: "root_id" } }
//               }
//             }
//           }
//         }
//       }
//       on_conflict: { constraint: instance_pkey, update_columns: created_at }
//     ) {
//       channels {
//         name
//         id
//         created_at
//         org_id
//         energy_meter_instance_id
//         hardware_id
//         created_by
//       }
//       building {
//         name
//       }
//       annotations {
//         created_by
//         id
//         key
//         org_id
//         type
//         value
//         energy_meter_instance_id
//       }
//       space {
//         name
//         space_id
//       }
//       org_id
//       hardware_id
//       energy_meter_id
//       created_by
//       created_at
//       id
//     }
//   }
// `;

// export const UPSERT_ENERGYMETER_INST = gql`
//   mutation {
//     insert_energy_meter_instance(
//       objects: {
//         annotations: {
//           on_conflict: {
//             constraint: instance_annotations_pkey
//             update_columns: value
//           }
//           data: [{ id: "", value: "" }]
//         }
//         channels: {
//           data: [
//             {
//               id: ""
//               hardware_id: ""
//               annotations: {
//                 data: [{ id: "", value: "" }]
//                 on_conflict: {
//                   constraint: channel_instance_annotations_pkey
//                   update_columns: value
//                 }
//               }
//             }
//           ]
//           on_conflict: {
//             constraint: channel_instance_pkey
//             update_columns: [created_at]
//           }
//         }
//       }
//       on_conflict: {
//         constraint: instance_pkey
//         update_columns: [created_at, hardware_id]
//       }
//     ) {
//       affected_rows
//     }
//   }
// `;

export const GET_CHANNELS = gql`
  query getChannels($id: uuid!) {
    energy_meter_channel_instance(
      where: { energy_meter_instance_id: { _eq: $id } }
    ) {
      hardware_id
      id
      space {
        space_id
      }
      thing {
        thing_id
      }
      annotations {
        id
        channel_instance_id
        key
        org_id
        type
        value
      }
    }
  }
`;

export const UPDATE_CHANNEL_ENERGYMETER_INS = gql`
  mutation updateChannelEnergyMeter(
    $channel_hardware_id: String
    $channelID: uuid!
  ) {
    update_energy_meter_channel_instance_by_pk(
      pk_columns: { id: $channelID }
      _set: { hardware_id: $channel_hardware_id }
    ) {
      hardware_id
      id
    }
  }
`;

export const UPDATE_ENERGYMETER_CHANNEL_INS_ANNOT = gql`
  mutation updateEnergyMeterChannelInsAnnot(
    $update_hardwareId: String
    $id: uuid!
  ) {
    update_energy_meter_channel_instance_annotations_by_pk(
      pk_columns: { id: $id }
      _set: {
        data: [
          {
            key: "root_id"
            value: $root_id
            type: "system"
            org_id: $org_id
            created_by: $created_by
          }
        ]
      }
    ) {
      created_by
      creqated_at
      org_id
      type
      key
      id
      channel_instance_id
      value
    }
  }
`;

export default function Update({
  id,
  hardware_id,
  space_id,
  building_id,
  org_id,
  limit,
  cursor,
  hardwareIdFilter,
}: Props) {
  const classes: IIndexable = useStyles();
  const { user } = useAuth();
  const created_by = user?.sub;

  type annotDataType = {
    key: string;
    org_id: string | null | undefined;
    type: string;
    created_by: string | null | undefined;
    value: string | null | undefined;
  };

  const input = useRef<HTMLInputElement>(null);
  const [annotData, setAnnotData] = useState<annotDataType[]>([]);
  const [updateHardwareID, setUpdateHardwareID] = useState(hardware_id);
  const [updateSpace, setUpdateSpace] = useState({
    id: space_id,
    name: '',
  });

  const [rootID, setRootID] = useState({
    id: building_id,
    name: '',
  });

  const [updateSuccessMessage, setUpdateSuccessMessage] = useState('');

  // If value is changed externally sync it here

  useEffect(() => {
    setUpdateHardwareID(hardware_id);
  }, [hardware_id]);
  const [getRoots, { data: rootData }] = useLazyQuery(GET_ROOTS, {
    variables: {
      org_id: org_id,
    },
  });

  useEffect(() => {
    getRoots();
  }, []);

  const [getSpaces, { data: spacesData }] = useLazyQuery(
    GET_ALL_SPACES_FOR_BUILDING,
    {
      variables: {
        root_id: rootID.id,
        building_id: rootID.id,
      },
    }
  );

  useEffect(() => {
    getSpaces();
  }, [rootID.id]);

  const {
    data: channelsData,
    loading: channelsLoading,
    error: channelsError,
  } = useQuery(GET_CHANNELS, {
    variables: {
      id,
    },
  });

  const [updateEnergyMeterIns, { loading, error }] = useMutation(
    UPDATE_ENERGYMETER_INS,
    {
      variables: {
        id,
        update_hardwareId: updateHardwareID?.trim(),
      },
      update(
        cache,
        {
          data: {
            update_energy_meter_instance_by_pk: { id, hardware_id },
          },
        }
      ) {
        cache.writeQuery({
          query: GET_ENERGY_METER_INSTANCE,
          variables: {
            id,
          },

          data: {
            id,
            hardware_id,
          },
        });

        cache.gc();
      },

      refetchQueries: [
        {
          query: GET_ENERGY_METER_INSTANCE,
          variables: { id },
        },
      ],
      onCompleted: ({ update_energy_meter_instance_by_pk }) => {
        setUpdateHardwareID((prevVal) => prevVal?.trim());
        setUpdateSuccessMessage(
          `${update_energy_meter_instance_by_pk?.hardware_id} updated successfully!`
        );
      },
    }
  );

  const [
    updateEnergyMeterChannelIns,
    { loading: updateLoading, error: updateError },
  ] = useMutation(UPDATE_CHANNEL_ENERGYMETER_INS, {
    refetchQueries: [
      {
        query: GET_ENERGY_METER_INSTANCE,
        variables: { id },
      },
    ],
    onCompleted: ({ update_energy_meter_channel_instance_by_pk }) => {
      setUpdateSuccessMessage(
        `${update_energy_meter_channel_instance_by_pk.hardware_id} updated successfully!`
      );
    },
  });

  function handleKeyPress(e: React.KeyboardEvent) {
    if (e.code === 'Enter') {
      updateEnergyMeterIns();

      handleSaveButton();
      input?.current?.blur();
    }
  }

  const isChannelAssigned = channelsData?.energy_meter_channel_instance.some(
    (item) => {
      return item.space?.space_id || item.thing?.thing_id;
    }
  );

  function handleSaveButton() {
    //get array t of  all the child instances
    updateEnergyMeterIns();
    setAnnotData([
      {
        key: 'root_id',
        org_id,
        type: 'system',
        value: rootID.id,
        created_by,
      },
      {
        key: 'space_id',
        org_id,
        type: 'system',
        value: updateSpace.id,
        created_by,
      },
    ]);

    channelsData?.energy_meter_channel_instance?.map((channel: any) => {
      const channelHardwareID = channel?.hardware_id?.split('/')[1];
      const channelID = channel.id;
      return updateEnergyMeterChannelIns({
        variables: {
          channelID,
          channel_hardware_id: `${updateHardwareID?.trim()}/${channelHardwareID}`,
          annotations: {
            data: [
              {
                key: 'root_id',
                org_id: org_id,
                type: 'system',
                value: rootID.id,
                created_by,
              },
            ],
          },
        },
      });
    });

    // map/loop on array of child instances
    //for every instance craete new hardware id and then call update child mutation
    // childharfwareid = newhardwaredid + childinstance.hardware.split('/')[1]
    // updateChildEnergyMeterIns({variable:{harfwareid: child}}));

    input?.current?.blur();
  }

  return (
    <Fragment>
      <PaperWrapper elevation={3} square>
        <SnackMessage message={error?.message} type="error" />
        <SnackMessage
          message={updateSuccessMessage}
          onClose={() => setUpdateSuccessMessage('')}
          type="success"
        />

        <StyledTextField
          inputProps={{ ref: input }}
          style={{
            display: 'flex',
            width: '80%',
            marginBottom: 20,
            marginTop: 20,
            justifyContent: 'center',
            marginLeft: '10%',
          }}
          name={updateHardwareID}
          value={updateHardwareID}
          onChange={(e) => setUpdateHardwareID(e.target.value)}
          onKeyPress={handleKeyPress}
          disabled={loading}
          variant="filled"
          label="Hardware ID"
        />
        {/* {rootData && !isChannelAssigned && (
          <Autocomplete
            className={classes.autocomplete}
            id="root-select"
            fullWidth
            options={rootData?.spaces || []}
            getOptionLabel={(option: any) => {
              return (
                rootData?.spaces.find(
                  (space: Entity) => space.id === option?.id
                )?.name || ''
              );
            }}
            onChange={(event: any, newValue: string | null | any) => {
              setRootID({
                id: newValue.id,
                name: newValue.name,
              });
              //  setUpdateParent(newValue.id); // parent_id for thing should always be thing or null.
            }}
            value={rootID}
            renderInput={(params) => (
              <TextField
                {...params}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password',
                }}
                label="Building"
                variant="filled"
              />
            )}
          />
        )}

        {spacesData && !isChannelAssigned && (
          <Autocomplete
            className={classes.autocomplete}
            options={spacesData?.spaces || []}
            getOptionLabel={(option: any) => {
              return (
                spacesData?.spaces?.find(
                  (space: Entity) => space.id === option?.id
                )?.name || ''
              );
            }}
            onChange={(event: any, newValue: string | null | any) => {
              setUpdateSpace({
                id: newValue?.id,
                name: newValue?.name,
              });
            }}
            value={updateSpace}
            renderInput={(params) => (
              <TextField {...params} label="Space" variant="filled" />
            )}
          />
        )} */}

        <div className={classes.footer}>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleSaveButton}
          >
            Save
          </Button>
        </div>
      </PaperWrapper>
    </Fragment>
  );
}
