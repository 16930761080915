import React, { Fragment, useEffect, useRef, useState } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { createStyles, lighten, styled, Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import SnackMessage from 'components/SnackMessage';
import { Box, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { IIndexable } from 'types';
import { StyledTextField } from './StyledComponent';
import { GET_CLIENTS_ORG } from 'pages/orgs';
import {
  GET_CLIENTS,
  GET_CONTRACTORS,
  GET_ORGANISATIONS_BY_TYPE,
} from 'graphql/common-queries';

type Props = {
  id: string;
  name: string;
  type_id: string;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      width: '60%',
      alignContent: 'center',
      justifyContent: 'center',
      padding: theme.spacing(1),
      '& .MuiInputBase-root': {
        marginBottom: theme.spacing(1),
      },
      '& .MuiFormLabel-root': {
        marginBottom: theme.spacing(1),
      },
    },
    footer: {
      display: 'flex',
      width: '100%',
      backgroundColor: lighten(theme.palette.secondary.main, 0.85),
      padding: theme.spacing(1),
      justifyContent: 'flex-end',
    },
  })
);

const PaperWrapper = styled(Paper)(({ theme }) => ({
  borderTop: '5px solid',
  borderColor: theme.palette.secondary.main,
}));

export const UPDATE_ORGANISATION = gql`
  mutation updateOrg($update_name: String, $id: uuid!) {
    update_organisation_by_id(
      pk_columns: { id: $id }
      _set: { name: $update_name }
    ) {
      id
      name
    }
  }
`;

export default function Update({ id, name, type_id }: Props) {
  const classes: IIndexable = useStyles();

  const input = useRef<HTMLInputElement>(null);
  const [updateName, setUpdateName] = useState(name);
  const [updateSuccessMessage, setUpdateSuccessMessage] = useState('');
  const [helperTextOrg, setHelperTextOrg] = useState('');
  const [errorTextOrg, setErrorTextOrg] = useState(false);
  const [duplicateOrgFlag, setDuplicateOrgFlag] = useState(false);
  const { data: orgData } = useQuery(GET_ORGANISATIONS_BY_TYPE, {
    variables: {
      type_id,
    },
    fetchPolicy: 'network-only',
  });

  // If value is changed externally sync it here
  useEffect(() => {
    setUpdateName(name);
  }, [name]);

  const [updateOrg, { loading, error: updateError }] = useMutation(
    UPDATE_ORGANISATION,
    {
      variables: {
        id,
        update_name: updateName.trim(),
      },

      onCompleted: ({ update_organisation_by_id }) => {
        setUpdateName((prevVal) => prevVal?.trim());
        setUpdateSuccessMessage(
          `${update_organisation_by_id.name} updated successfully!`
        );
      },
      refetchQueries: [
        {
          query: GET_CLIENTS_ORG,
        },
        {
          query: GET_CONTRACTORS,
        },
        { query: GET_ORGANISATIONS_BY_TYPE, variables: { type_id } },
      ],
    }
  );

  useEffect(() => {
    if (updateName) {
      const isOrgNameExists = orgData?.organisation
        ?.filter((item) => item.id !== id)
        .find((org) => {
          return (
            org?.name.toLowerCase() === updateName?.toString().toLowerCase()
          );
        });

      if (isOrgNameExists) {
        setDuplicateOrgFlag(true);
        setErrorTextOrg(true);
        setHelperTextOrg(`Name already exists. Please choose a different name`);
      } else {
        setErrorTextOrg(false);
        setDuplicateOrgFlag(false);
        setHelperTextOrg('');
      }
    }
  }, [updateName]);

  function handleKeyPress(e: React.KeyboardEvent) {
    if (e.code === 'Enter') {
      updateOrg();
      input?.current?.blur();
    }
  }

  function handleSaveButton() {
    updateOrg();
    input?.current?.blur();
    setHelperTextOrg('');
    setErrorTextOrg(false);
    setDuplicateOrgFlag(false);
  }
  return (
    <Fragment>
      <PaperWrapper elevation={3} square>
        <SnackMessage message={updateError?.message} type="error" />
        <SnackMessage
          message={updateSuccessMessage}
          onClose={() => setUpdateSuccessMessage('')}
          type="success"
        />

        <StyledTextField
          inputProps={{ ref: input }}
          style={{
            display: 'flex',
            width: '50%',
            marginBottom: 20,
            marginTop: 20,
            justifyContent: 'center',
            marginLeft: 20,
          }}
          name={updateName}
          value={updateName}
          onChange={(e) => setUpdateName(e.target.value)}
          onKeyPress={handleKeyPress}
          disabled={loading}
          variant="filled"
          label="NAME"
          helperText={helperTextOrg}
          error={errorTextOrg}
        />
        <div className={classes.footer}>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleSaveButton}
          >
            Save
          </Button>
        </div>
      </PaperWrapper>
    </Fragment>
  );
}
