import React from 'react';
import { Box, Divider } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';

const HeaderStyles = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginBottom: '1em',
});

const Margin = styled(Box)({
  marginBottom: '1rem'
});

const Header: React.FC = ({ children }) => {
  return (
    <Margin>
      <HeaderStyles>{children}</HeaderStyles>
      <Divider />
    </Margin>
  );
};

export default Header;
