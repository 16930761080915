import {
  Box,
  Button,
  createStyles,
  InputBase,
  makeStyles,
  styled,
  withStyles,
} from '@material-ui/core';
import { useState } from 'react';
import { FaSearch, FaTimes } from 'react-icons/fa';

const useStyles = makeStyles((theme) => ({
  searchIcon: {
    height: 38,
    width: 20,
    backgroundColor: 'rgba(224, 224, 224, 1)',
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    color: 'blue',
    boxSizing: 'border-box',
    '&:hover': {
      backgroundColor: 'rgba(224, 224, 224, 1)',
    },
    padding: '10px 10px',
  },
}));

const SearchWrapperStyles = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  borderRadius: theme.shape.borderRadius,
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: 'whitesmoke',
  '&:hover': {
    backgroundColor: 'aliceblue',
  },
}));

const InputStyles = withStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      height: 40,
      alignItems: 'center',
      padding: theme.spacing(1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(1)}px)`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: '12ch',
      },
    },

    focused: {
      [theme.breakpoints.up('sm')]: {
        width: '20ch',
      },
    },
  })
)(InputBase);

type Props = {
  handleSearch(val: string): void;
  style?: object;
};

const SearchBar: React.FC<Props> = ({ handleSearch, ...props }) => {
  const [value, setValue] = useState('');
  const [focusedSearch, setFocusedSearch] = useState(false);
  const classes = useStyles();
  const handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (e.key === 'Enter') {
      handleSearch(value);
    }
    setFocusedSearch(false);
  };

  return (
    <SearchWrapperStyles {...props}>
      <InputStyles
        placeholder="Search…"
        inputProps={{ 'aria-label': 'search' }}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        type="search"
        onKeyPress={(e) => handleKeyPress(e)}
        onClick={() => {
          setFocusedSearch(true);
        }}
      />
      <Button
        className={classes.searchIcon}
        onClick={() => {
          handleSearch(value);
        }}
      >
        <FaSearch color="#152935" size={20} />
      </Button>
    </SearchWrapperStyles>
  );
};

export default SearchBar;
