import Button from '@material-ui/core/Button';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';

export type ButtonProps = {
  item: string;
  onClick: () => void;
  open?: boolean;
};

const IconLabelButton = ({ item, onClick, open }: ButtonProps) => {

  return (
    <Button
      variant="contained"
      size="large"
      startIcon={open ? <RemoveCircleIcon /> : <AddCircleIcon />}
      onClick={onClick}
      color="primary"
    >
      {item}
    </Button>
  );
};

export default IconLabelButton;
