import { useState } from 'react';
import { Box, FormLabel, TextField, styled } from '@material-ui/core';
import {
  gql,
  PureQueryOptions,
  RefetchQueriesFunction,
  useMutation,
} from '@apollo/client';
import Form from './Form';
import SnackMessage from './SnackMessage';
import useForm from '../hooks/useForm';
import { trimmedFields } from 'utils/helpers';

const ADD_MEASUREMENT_TYPE = gql`
  mutation add_measurement_type($name: String!, $description: String) {
    insert_measurement_type_one(
      object: { name: $name, description: $description }
    ) {
      id
      name
      description
    }
  }
`;

type Query = {
  query: RefetchQueriesFunction | (string | PureQueryOptions)[] | undefined;
};

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-around',
  margin: theme.spacing(2),
  transition: 'all .5s',
}));

export default function CreateMeasurementType({ query }: Query) {
  const { inputs, handleChange, clearForm } = useForm({
    name: '',
    description: '',
  });

  const [AddType, { loading, error }] = useMutation(ADD_MEASUREMENT_TYPE, {
    variables: {
      ...trimmedFields(inputs),
    },
    errorPolicy: 'all',
    refetchQueries: query,
    onCompleted: ({ insert_measurement_type_one }) => {
      setSuccessMessage(
        `${insert_measurement_type_one?.name} added successfully!`
      );
    },
  });

  const [successMessage, setSuccessMessage] = useState('');

  async function handleSubmit() {
    await AddType();
    clearForm();
  }

  return (
    <StyledBox>
      <SnackMessage message={error?.message} type="error" />
      <SnackMessage message={successMessage} type="success" />
      <Form onSubmit={handleSubmit} busy={loading}>
        <FormLabel>ADD CATEGORY</FormLabel>
        <TextField
          name="name"
          label="name"
          variant="filled"
          fullWidth
          disabled={loading}
          value={inputs.name}
          onChange={handleChange}
          autoComplete="new-password"
        />
        <TextField
          name="description"
          label="description"
          variant="filled"
          fullWidth
          disabled={loading}
          value={inputs.description}
          onChange={handleChange}
          autoComplete="new-password"
        />
      </Form>
    </StyledBox>
  );
}
