import { Fragment, useEffect, useMemo, useState } from 'react';
import {
  useTable,
  Column,
  useRowSelect,
  HeaderGroup,
  useSortBy,
  SortingRule,
  Row,
  usePagination,
  useGlobalFilter,
  useAsyncDebounce,
  useFilters,
} from 'react-table';
import {
  withStyles,
  Theme,
  createStyles,
  lighten,
  makeStyles,
} from '@material-ui/core/styles';

import {
  TableContainer,
  TableHead,
  TableRow,
  Table,
  TableBody,
  TableCell,
  Checkbox,
  Paper,
  TablePagination,
  TableSortLabel,
  Toolbar,
  Typography,
  Tooltip,
  IconButton,
  TextField,
  Button,
  Input,
} from '@material-ui/core';
import SwapVertSharpIcon from '@material-ui/icons/SwapVertSharp';
import ArrowDownwardSharpIcon from '@material-ui/icons/ArrowDownwardSharp';
import ArrowUpwardSharpIcon from '@material-ui/icons/ArrowUpwardSharp';
import DeleteIcon from '@material-ui/icons/Delete';
import clsx from 'clsx';
import { format } from 'date-fns';
import LinearLoader from './LinearLoader';
import { AddIcon } from '@material-ui/data-grid';
import { create } from 'domain';
import { IIndexable } from 'types';

type TableProps<T extends object> = {
  data: T[];
  columns: Column<T>[];
  loading?: boolean;
  count?: number;
  manualPagination?: boolean;
  manualSorting?: boolean;
  selectedRow?: string | null;
  onChangePage?(newPageOrCursor: number | string, newPage?: number): void;
  onSort?(sortArray: SortingRule<T>[]): void;
  pageSize?: number;
  onRowClick?(row: Row<T>): void;
  title?: string;
  // onDeleteRows?(rows: Row<T>[]): void;
  onUpdateRow?(row: Row<T>): void | undefined;
  onEditRow?(row: Row<T>): void | undefined;
  onEditBuildingRow?(row: Row<T>): void | undefined;
  onSelectedRow?(row: Row<T>[]): void | undefined;
  handleChange: (filterValue: any, columHeader: any) => void;
  handleClearFilter(): void;
  isFilter: boolean;
  inputFilter: boolean;
  // globalSearch?: boolean;

  offset?: number;
  controlledPageIndex?: number;
  filterState: {
    taskNum: string | null;
    client: string | null;
    building: string | null;
    contractor: string | null;
    assignedDateGte: string | null;
    assignedDateLt: string | null;
    completedDateGte: string | null;
    completedDateLt: string | null;
    dueDateGte: string | null;
    createdDateGte: string | null;
    dueDateLt: string | null;
    createdDateLt: string | null;
    status: string | null;
  };
  setFilterState: React.Dispatch<
    React.SetStateAction<{
      taskNum: string | null;
      client: string | null;
      building: string | null;
      contractor: string | null;
      assignedDateGte: string | null;
      assignedDateLt: string | null;
      completedDateGte: string | null;
      completedDateLt: string | null;
      dueDateGte: string | null;
      createdDateGte: string | null;
      dueDateLt: string | null;
      createdDateLt: string | null;
      status: string | null;
    }>
  >;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    date: {
      boderColor: 'rgba(0, 0, 0, 0.10)',
      borderWidth: 1,
      outlineColor: 'rgba(0, 0, 0, 0.10)',
    },
    dateStyle: (props: { date: string | null }) => ({
      color: props.date === '' ? 'rgba(0, 0, 0, 0.50)' : 'rgba(0, 0, 0, 0.80)',
    }),
  })
);

export function DateColumnFilter({
  onFilterChange,
  placeHolder,
  inputFilter,
  filterState,
}) {
  const [date, setDate] = useState<any>('');
  const classes: IIndexable = useStyles({ date });

  useEffect(() => {
    if (inputFilter) {
      setDate('');
    }
  }, [inputFilter, filterState]);

  const handleFilter = (e) => {
    const value = e.target.value || undefined;
    setDate(value);
    onFilterChange(value || null); // Call the handler function if needed
  };
  return (
    <TextField
      type="date"
      value={date}
      color="secondary"
      onChange={handleFilter}
      InputLabelProps={{
        className: classes.date,
        shrink: true,
      }}
      InputProps={{
        className: classes.dateStyle,
      }}
    />
  );
}
function DefaultColumnFilter({
  onFilterChange,
  placeHolder,
  inputFilter,
  filterState,
}) {
  const [text, setText] = useState('');

  useEffect(() => {
    if (inputFilter) {
      setText('');
    }
  }, [inputFilter, filterState]);

  const handleFilter = (e) => {
    const value = e.target.value || undefined;
    setText(value);
    onFilterChange(value || ''); // Call the handler function if needed
  };

  return (
    <input
      style={{
        outlineColor: 'rgb(109, 194, 179)',
        borderColor: 'rgba(0, 0, 0, 0.20)',
        borderWidth: 1,
        borderRadius: 4,
        fontWeight: 400,
        height: 30,
        fontSize: 15,
        padding: 2,
        width: 150,
      }}
      color="secondary"
      value={text}
      onChange={handleFilter}
      placeholder={`${
        typeof placeHolder === 'string'
          ? placeHolder.toLowerCase()
          : placeHolder
      }...`}
    />
  );
}

export const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      fontWeight: 'bold',
      // '&:first-child': {
      //   width: 80,
      //   padding: '0 0 0 4px',
      // },
      '&:nth-child(2)': {
        width: 20,
      },
      '&:nth-child': {
        width: 20,
      },

      fontFamily: 'Roboto , Helvetica, Arial, sans-serif',
    },
    body: {
      fontFamily: 'Roboto , Helvetica, Arial, sans-serif',
      // '&:first-child': {
      //   width: 80,
      //   padding: '0 0 0 4px',
      // },
      '&:nth-child(2)': {
        width: 20,
      },
      '&:nth-child': {
        width: 20,
      },
    },
  })
)(TableCell);

export const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: (props: { clickable?: boolean }) => ({
      '&:hover': {
        backgroundColor: lighten(theme.palette.secondary.main, 0.85),
        cursor: props.clickable ? 'pointer' : 'inherit',
      },
    }),
  })
)(TableRow);

const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    highlight: {
      color: theme.palette.secondary.main,
      backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      justifyContent: 'space-between',
    },
    tooltip: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    tipContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
  })
);

function CTableHead({ headerGroups }: any) {
  return (
    <TableHead>
      {headerGroups.map((headerGroup: HeaderGroup) => (
        <>
          <TableRow {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => {
              const {
                id,
                canSort,
                isSorted,
                isSortedDesc,
                getSortByToggleProps,
                getHeaderProps,
                render,
              } = column;

              return (
                <>
                  <StyledTableCell>
                    <div
                      {...getHeaderProps(getSortByToggleProps)}
                      style={{
                        padding: id === 'selection' ? 'checkbox' : 'default',
                      }}
                    >
                      {canSort ? (
                        <TableSortLabel
                          IconComponent={
                            isSortedDesc === undefined
                              ? SwapVertSharpIcon
                              : isSorted
                              ? ArrowDownwardSharpIcon
                              : ArrowUpwardSharpIcon
                          }
                          active={true}
                          direction={isSortedDesc ? 'desc' : 'asc'}
                          style={{
                            color: isSorted ? 'rgb(109, 194, 179)' : 'black',
                          }}
                        >
                          {render('Header')}
                        </TableSortLabel>
                      ) : (
                        <>
                          {render('Header')}{' '}
                          <div>
                            {column.canFilter ? column.render('Filter') : null}
                          </div>
                        </>
                      )}
                    </div>
                    <>{column.canFilter ? column.render('Filter') : null}</>
                  </StyledTableCell>
                </>
              );
            })}
          </TableRow>
        </>
      ))}
    </TableHead>
  );
}

type ToolbarProps<T extends object> = {
  title: string | undefined;
  onDeleteRows: (() => void) | undefined;
  selectedRows: string[];

  // filter props
  // preGlobalFilteredRows: Row<T>[];
  globalFilter: string;
  setGlobalFilter(value: string | undefined): void;
  showSearch?: boolean;
};

function CToolBar<T extends object>({
  title,
  // onDeleteRows,
  selectedRows,
  setGlobalFilter,
  // preGlobalFilteredRows,
  globalFilter,
  showSearch,
}: ToolbarProps<T>) {
  const classes = useToolbarStyles();

  return (
    <Toolbar className={clsx({ [classes.highlight]: selectedRows.length > 0 })}>
      {/* {selectedRows.length > 0 && (
        <>
          <div className={classes.tipContainer}>
            <Typography color="inherit" variant="subtitle1" component="div">
              {selectedRows.length} selected
            </Typography>
            {onDeleteRows && (
              <Tooltip title="Delete" className={classes.tooltip}>
                <IconButton aria-label="delete" onClick={onDeleteRows}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            )}
          </div>
        </>
      )} */}
    </Toolbar>
  );
}

export default function CTable<T extends object>({
  columns,
  data,
  loading,
  count = 0,
  manualPagination,

  onChangePage,
  onSort,
  pageSize = 2, //check page size
  onRowClick,
  title,
  // onDeleteRows,
  onUpdateRow,

  // onEditBuildingRow,

  onEditRow,
  selectedRow,
  filterState,
  setFilterState,
  handleChange,
  onSelectedRow,

  handleClearFilter,
  isFilter,
  inputFilter,
  offset,
  controlledPageIndex, //Control the page index manually
}: TableProps<T>) {
  columns = useMemo(() => columns, []);
  data = useMemo(() => data, [data]);

  const defaultColumn = useMemo(
    () => ({
      Filter: ({ column, inputFilter, filterState }) => {
        const { Header, id } = column;
        console.log(id, 'id');

        return id === 'assigned_date' ||
          id === 'created_at' ||
          id === 'due_date' ||
          id === 'COMPLETED' ? (
          <DateColumnFilter
            onFilterChange={(val) => handleChange(val, Header)}
            inputFilter={inputFilter}
            placeHolder={Header}
            filterState={filterState}
          />
        ) : (
          <DefaultColumnFilter
            onFilterChange={(val) => {
              handleChange(val, Header);
            }}
            placeHolder={Header}
            inputFilter={inputFilter}
            filterState={filterState}
          />
        );
      },
    }),
    [filterState, inputFilter]
  );

  const {
    getTableProps,
    getTableBodyProps,

    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    gotoPage,
    page,
    state: { sortBy, selectedRowIds, pageIndex, pageSize: statePageSize },
  } = useTable<T>(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        hiddenColumns: [
          'org_id',
          'id',
          'contractor_org_id',
          'client_org_id',
          'building_id',
          'space_id',
          'thing_id',
          'parent_id',
          'type_id',
          'created_by',
          'category_id',
        ],
        pageSize,
        pageIndex: 0,
      },

      manualSortBy: true,
      disableSortBy: !onSort,
      manualPagination,

      onUpdateRow,
      // onEditBuildingRow,
      filterState,
      inputFilter,
      pageCount: Math.ceil(count / pageSize),
      useControlledState: (state) => {
        return useMemo(
          () => ({
            ...state,
            ...(controlledPageIndex !== undefined
              ? { pageIndex: controlledPageIndex }
              : {}),
          }),
          [state, controlledPageIndex]
        );
      },
    },
    useFilters,
    // useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        // {
        //   id: 'selection',
        //   Header: ({ getToggleAllRowsSelectedProps }) => (
        //     <div>
        //       <Checkbox
        //         {...getToggleAllRowsSelectedProps()}
        //         inputProps={{ 'aria-label': 'select all' }}
        //       />
        //     </div>
        //   ),
        //   Cell: ({ row }: any) => (
        //     <div>
        //       <Checkbox {...row.getToggleRowSelectedProps({})} />
        //     </div>
        //   ),
        // },
        ...columns,
      ]);
    }
  );

  function handlePageChange(event: unknown, newPage: number) {
    if (onChangePage) {
      onChangePage(newPage);
    }
    gotoPage(newPage);
  }

  useEffect(() => {
    if (onSort) {
      onSort(sortBy);
    }
  }, [sortBy]);

  // function handleDeleteRows() {
  //   if (onDeleteRows) {
  //     onDeleteRows(selectedFlatRows);
  //   }
  // }

  return (
    <Paper>
      {isFilter ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginRight: 10,
            paddingTop: 10,
          }}
        >
          <Button
            onClick={handleClearFilter}
            variant="contained"
            style={{ backgroundColor: 'rgba(255, 0, 0, 0.5)', color: 'white' }}
          >
            CLEAR
          </Button>
        </div>
      ) : null}
      <TableContainer {...getTableProps()}>
        {/* {(title || onDeleteRows) && (
          <CToolBar
            //onDeleteRows={onDeleteRows && handleDeleteRows}
            // onSubscribeRows={onSubscribeRows && handleSubscribeRows}
            // onEditMultipleRows={onEditMultipleRows && handleEditMultipleRows}
            title={title}
            selectedRows={Object.keys(selectedRowIds)}
            // globalFilter={globalFilter}
            // setGlobalFilter={setGlobalFilter}
            // preGlobalFilteredRows={preGlobalFilteredRows}
            //showSearch={globalSearch}
          />
        )} */}
        <Table aria-label="customized table">
          <CTableHead headerGroups={headerGroups} />
          <TableBody {...getTableBodyProps}>
            {(count ? page : rows).map((row) => {
              prepareRow(row);
              return (
                <StyledTableRow
                  {...row.getRowProps()}
                  clickable={!!onRowClick}
                  selected={row.values.id === selectedRow}
                >
                  {row.cells.map((cell) => {
                    const isSelectColumn = cell.column.id === 'selection';
                    const isEditColumn = cell.column.id === 'edit';
                    const isUpdateColumn = cell.column.id === 'update';

                    return (
                      <StyledTableCell
                        {...cell.getCellProps()}
                        onClick={() => {
                          if (
                            onRowClick &&
                            !isSelectColumn &&
                            !isEditColumn &&
                            !isUpdateColumn
                          )
                            onRowClick(row);
                          if (isEditColumn && onUpdateRow) onUpdateRow?.(row);
                          if (isUpdateColumn && onEditRow) onEditRow?.(row);
                        }}
                        // padding={
                        //   isSelectColumn || isEditColumn || isUpdateColumn
                        // }
                      >
                        {cell.render('Cell')}
                      </StyledTableCell>
                    );
                  })}
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {loading && <LinearLoader />}
      {!!count && (
        <TablePagination
          component="div"
          count={count}
          page={pageIndex}
          onChangePage={handlePageChange}
          rowsPerPage={statePageSize}
          rowsPerPageOptions={[]}
        />
      )}
    </Paper>
  );
}
