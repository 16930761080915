import {
  useState,
  useEffect,
  useContext,
  createContext,
  PropsWithChildren,
} from 'react';

import jwt_decode, { JwtPayload } from 'jwt-decode';
import client from 'graphql/apollo-client';

type StateType = {
  user?: JwtPayload;
  loading: boolean;
  logOut(): void;
  logIn(token: string): void;
};

const initialState = {
  user: undefined,
  loading: false,
  logOut: () => null,
  logIn: () => null,
};

const authContext = createContext<StateType>(initialState);

export function ProvideAuth({ children }: PropsWithChildren<any>) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export const useAuth = () => {
  return useContext(authContext);
};

function useProvideAuth() {
  const [user, setUser] = useState<JwtPayload>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function checkAuth() {
      const token = await localStorage.getItem('token');
      if (token && !isTokenExpired(token)) {
        setUser(decodeToken(token));
      }
      setLoading(false);
    }
    checkAuth();
  }, []);

  function decodeToken(token: string) {
    return jwt_decode<JwtPayload>(token);
  }

  async function logIn(token: string) {
    setLoading(true);
    if (!isTokenExpired(token)) {
      setUser(decodeToken(token));
      await localStorage.setItem('token', JSON.stringify(token));
    }
    setLoading(false);
  }

  async function logOut() {
    setLoading(true);
    await localStorage.removeItem('token');
    setUser(undefined);
    setLoading(false);
    client.resetStore();
  }

  function isTokenExpired(token: string) {
    try {
      const decoded = decodeToken(token);
      if (decoded.exp) {
        const expiry = decoded.exp * 1000;
        return Date.now() >= expiry;
      }
      return false;
    } catch (e) {
      console.log(e);
      return true;
    }
  }

  return {
    user,
    loading,
    logOut,
    logIn,
  };
}
