import React, { Fragment } from 'react';
import { useQuery } from '@apollo/client';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { GET_CLIENTS } from 'graphql/common-queries';
import { AutocompleteProps } from 'types';
import { sortDataByAlpha } from 'utils/helpers';

const AutocompleteORG = ({
  selectedValue,
  onChange,
  className,
  mainUser,
}: AutocompleteProps) => {
  const { data } = useQuery(GET_CLIENTS);
  const orgDataArr = data?.organisation_clients;

  const orgSortedOptions = data && sortDataByAlpha([...orgDataArr]);
  return (
    <Fragment>
      {data && data.organisation_clients && (
        <Autocomplete
          disableClearable={true}
          className={className}
          onChange={(event: any, newValue: string | null | any) => {
            onChange(newValue);
          }}
          value={selectedValue}
          size="small"
          id="controllable-states-org"
          getOptionLabel={(option: any) => option.name}
          options={orgSortedOptions}
          getOptionSelected={(option, value) => {
            return option.name === value;
          }}
          renderInput={(params: any) => (
            <form noValidate>
              <TextField
                {...params}
                label={mainUser ? 'ORGANISATION' : 'Organisation'}
                variant={mainUser ? 'filled' : 'outlined'}
              />{' '}
            </form>
          )}
        />
      )}
    </Fragment>
  );
};

export default AutocompleteORG;
