import { gql, useMutation, useQuery } from '@apollo/client';
import { Fragment, useState } from 'react';
import { Row } from 'react-table';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Collapse,
  IconButton,
  Paper,
  Typography,
} from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Table from 'components/Table';
import Header from 'components/Header';
import SearchBar from 'components/SearchBar';
import Title from 'components/Title';
import CreateSensorNode from 'components/CreateSensorNode';
import IconLabelButton from 'components/IconLabelButton';

import { ALL_MEASUREMENT_CATEGORY, ALL_MEASUREMENT_TYPE } from 'constants/data';
import SnackMessage from 'components/SnackMessage';
import { Entity, SpaceThing } from 'types';
import { IIndexable } from 'types';
import { useThemeStyles } from 'theme';
import Update from '../../components/UpdateSensorNode';
import { ChevronRight } from '@material-ui/icons';
import CreateMeasurementType from 'components/CreateMeasurementType';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      marginBottom: 50,
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
    },

    chart: {
      display: 'flex',
      width: '40%',
    },

    createSpace: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginBottom: 10,
    },
  })
);

export const SENSOR_NODE_MEASUREMENT_TYPE = gql`
  query sensor_node_measurement_type($limit: Int, $offset: Int, $name: String) {
    measurement_type(
      where: { name: { _ilike: $name } }
      limit: $limit
      offset: $offset
    ) {
      description
      id
      name
    }
  }
`;

const SENSOR_NODE_MEASUREMENT_TYPE_AGG = gql`
  query sensor_node_measurement_type_agg($name: String) {
    measurement_type_aggregate(where: { name: { _ilike: $name } }) {
      aggregate {
        count
      }
    }
  }
`;

const ROWS_PER_PAGE = 20;

export default function SensorNodeMeasurementType() {
  const classes = useStyles();
  const themeClasses: IIndexable = useThemeStyles();

  const [offset, setOffset] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [name, setName] = useState('');
  const [orderBy, setOrderBy] = useState({});
  const [openForm, setOpenForm] = useState<boolean>(false);
  const [deleteSuccessMessage, setDeleteSuccessMessage] = useState('');
  const [update, setUpdate] = useState(false);
  const [updateID, setUpdateID] = useState('');
  const [updateName, setUpdateName] = useState('');
  const [updateModel, setUpdateModel] = useState('');
  const [updateDescription, setUpdateDescription] = useState('');

  const history = useHistory();

  const { data, loading } = useQuery(SENSOR_NODE_MEASUREMENT_TYPE, {
    variables: {
      offset: offset,
      limit: ROWS_PER_PAGE,
      name: `%${name}%`,
    },
  });

  const { data: Agg, loading: countLoading } = useQuery(
    SENSOR_NODE_MEASUREMENT_TYPE_AGG,
    {
      variables: {
        name: `%${name}%`,
      },
    }
  );

  //   const [deleteMeasurementCategory, { error: deleteError }] = useMutation(DELETE_MEASUREMENT_CATEGORY, {
  //     update(
  //       cache,
  //       {
  //         data: {
  //           delete_thing_things: { returning },
  //         },
  //       }
  //     ) {
  //       returning.forEach((thing: SpaceThing) =>
  //         cache.evict({ id: cache.identify(thing) })
  //       );
  //       cache.gc();

  //       const deletedIds = returning.map((thing: any) => thing.id);

  //       const cachedCount: any = cache.readQuery({
  //         query: THINGS_COUNT_QUERY,
  //         variables: {
  //           name: `%${name}%`,
  //         },
  //       });

  //       const updatedCount =
  //         cachedCount.rowcount.aggregate.count - deletedIds.length;

  //       cache.writeQuery({
  //         query: THINGS_COUNT_QUERY,

  //         variables: {
  //           name: `%${name}%`,
  //         },

  //         data: {
  //           rowcount: updatedCount,
  //         },
  //       });
  //     },
  //     onCompleted: ({ delete_thing_things: { returning } }) => {
  //       setDeleteSuccessMessage(
  //         `${
  //           returning.length > 1
  //             ? `${returning.length} things`
  //             : returning[0].name
  //         } deleted successfully`
  //       );
  //     },
  //   });

  function handlePageChange(newPage: number) {
    setPage(newPage);
    setOffset(newPage * ROWS_PER_PAGE);
  }

  const handleSearchClick = (val: string) => {
    setPage(0);
    setName(val);
    setOffset(0);
  };

  function handleSort(sortArray: []) {
    const orderObj = sortArray.reduce((acc, sort) => {
      let { id, desc } = sort;
      return { ...acc, [id]: desc ? 'desc' : 'asc' };
    }, {});
    setOrderBy(orderObj);
  }

  function handleRowClick({ values }: Row) {
    history.push(`/sensor_nodes/${values.id}`);
  }

  //   function handleDelete(rows: any) {
  //     const ids = rows.map((r: Row<Entity>) => r.values.id);
  //     deleteSensorNode({ variables: { ids } });
  //   }

  function handleUpdate({ values }: Row) {
    const { id, model, name, description } = values;

    setUpdate(!update);
    setUpdateID(id);
    setUpdateName(name);
    setUpdateDescription(description);
    setUpdateModel(model);
  }

  return (
    <>
      {/* <SnackMessage message={deleteError?.message} type="error" /> */}
      <SnackMessage message={deleteSuccessMessage} type="success" />
      <Header>
        <Title heading="Browse Measurement Type" />
        <SearchBar handleSearch={handleSearchClick} />
      </Header>
      <div className={classes.createSpace}>
        <IconLabelButton
          item={'Add Measurement Type'}
          onClick={() => setOpenForm(!openForm)}
          open={openForm}
        />
      </div>
      <Collapse in={openForm}>
        <CreateMeasurementType
          query={[
            'sensor_node_measurement_type',
            'sensor_node_measurement_type_agg',
          ]}
        />
      </Collapse>
      <Box className={themeClasses.detailWrapper}>
        <Box
          className={[
            themeClasses.maxWidthTransition,
            themeClasses[update ? 'tableSmall' : 'tableLarge'],
          ].join(' ')}
        >
          <Table
            loading={loading}
            columns={ALL_MEASUREMENT_TYPE}
            data={data?.measurement_type || []}
            count={Agg?.measurement_type_aggregate?.aggregate?.count}
            manualPagination
            pageSize={ROWS_PER_PAGE}
            onChangePage={handlePageChange}
            onSort={handleSort}
            onRowClick={handleRowClick}
            // onDeleteRows={handleDelete}
            onUpdateRow={handleUpdate}
          />
        </Box>
        <Box
          className={[
            themeClasses.maxWidthTransition,
            themeClasses[update ? 'contentOpen' : 'contentClosed'],
          ].join(' ')}
        >
          <Paper
            className={[
              themeClasses.maxWidthTransition,
              themeClasses.detailsTop,
              themeClasses[update ? 'padding' : ''],
            ].join(' ')}
          >
            <IconButton aria-label="close" onClick={() => setUpdate(!update)}>
              <ChevronRight fontSize={'large'} />
            </IconButton>
            <Typography variant="h5">Update</Typography>
          </Paper>
          {update && (
            <Update
              orderBy={orderBy}
              offset={offset}
              limit={ROWS_PER_PAGE}
              model={updateModel}
              description={updateDescription}
              name={updateName}
              id={updateID}
            />
          )}
        </Box>
      </Box>
    </>
  );
}
