import { gql, useMutation, useQuery } from '@apollo/client';
import { Fragment, useState } from 'react';
import { Row } from 'react-table';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Collapse,
  IconButton,
  Paper,
  Typography,
} from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Table from 'components/Table';
import Header from 'components/Header';
import SearchBar from 'components/SearchBar';
import Title from 'components/Title';
import IconLabelButton from 'components/IconLabelButton';
import { THING_GROUP_COLUMNS } from 'constants/data';
import SnackMessage from 'components/SnackMessage';
import { Entity } from 'types';
import { IIndexable } from 'types';
import { useThemeStyles } from 'theme';
import Update from '../../components/UpdateGroup';
import { ChevronRight } from '@material-ui/icons';
import CreateThingGroup from 'components/CreateThingGroup';
import { useAuth } from 'hooks/useAuth';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      marginBottom: 50,
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
    },

    chart: {
      display: 'flex',
      width: '40%',
    },

    createGroup: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginBottom: 10,
    },
  })
);

export const THING_GROUP = gql`
  query get_thing_group(
    $limit: Int
    $offset: Int
    $name: String
    $orderBy: [thing_group_order_by!]
  ) {
    thing_group(
      where: { name: { _ilike: $name } }
      limit: $limit
      offset: $offset
      order_by: $orderBy
    ) {
      created_date
      description
      id
      name
      created_by
    }
    rowcount: thing_group_aggregate(order_by: $orderBy) {
      aggregate {
        count
      }
    }
  }
`;

export const DEL_THING_GROUP = gql`
  mutation deleteThingGroup($ids: [uuid!]!) {
    delete_thing_group(where: { id: { _in: $ids } }) {
      returning {
        id
        name
        description
        created_date
        created_by
      }
    }
  }
`;

const ROWS_PER_PAGE = 20;

export default function Group() {
  const classes = useStyles();
  const themeClasses: IIndexable = useThemeStyles();

  const [offset, setOffset] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [name, setName] = useState('');
  const [orderBy, setOrderBy] = useState({});
  const [openForm, setOpenForm] = useState<boolean>(false);
  const [deleteSuccessMessage, setDeleteSuccessMessage] = useState('');
  const [update, setUpdate] = useState(false);
  const [updateID, setUpdateID] = useState('');
  const [updateName, setUpdateName] = useState('');
  const [updateDescription, setUpdateDescription] = useState('');

  const history = useHistory();

  const { user } = useAuth();
  const created_by = user?.sub;

  const { data, loading } = useQuery(THING_GROUP, {
    variables: {
      offset,
      limit: ROWS_PER_PAGE,
      name: `%${name}%`,
      orderBy,
    },
  });

  const [deleteGroup, { error: deleteError }] = useMutation(DEL_THING_GROUP, {
    update(
      cache,
      {
        data: {
          delete_thing_group: { returning },
        },
      }
    ) {
      returning.forEach((group: any) =>
        cache.evict({ id: cache.identify(group) })
      );
      cache.gc();

      const deletedIds = returning.map((thing: any) => thing.id);

      const cachedCount: any = cache.readQuery({
        query: THING_GROUP,
        variables: {
          name: `%${name}%`,
          orderBy,
        },
      });

      const updatedCount =
        cachedCount?.thing_group?.rowcount?.aggregate?.count -
        deletedIds.length;

      cache.writeQuery({
        query: THING_GROUP,

        variables: {
          name: `%${name}%`,
          orderBy,
        },

        data: {
          rowcount: updatedCount,
        },
      });
    },
    onCompleted: ({ delete_thing_group: { returning } }) => {
      setDeleteSuccessMessage(
        `${
          returning.length > 1
            ? `${returning.length} things`
            : returning[0].name
        } deleted successfully`
      );
    },
  });

  function handlePageChange(newPage: number) {
    setPage(newPage);
    setOffset(newPage * ROWS_PER_PAGE);
  }

  const handleSearchClick = (val: string) => {
    setPage(0);
    setName(val);
    setOffset(0);
  };

  function handleSort(sortArray: []) {
    const orderObj = sortArray.reduce((acc, sort) => {
      let { id, desc } = sort;
      return { ...acc, [id]: desc ? 'desc' : 'asc' };
    }, {});

    setOrderBy(orderObj);
  }

  function handleDelete(rows: any) {
    const ids = rows.map((r: Row<Entity>) => r.values.id);
    deleteGroup({ variables: { ids } });
  }

  function handleUpdate({ values }: Row) {
    const { id, name, description } = values;
    setUpdate(!update);
    setUpdateID(id);
    setUpdateName(name);
    setUpdateDescription(description);
  }

  return (
    <>
      {/* <SnackMessage message={deleteError?.message} type="error" /> */}
      <SnackMessage message={deleteSuccessMessage} type="success" />
      <Header>
        <Title heading="Browse Thing Group" />
        <SearchBar handleSearch={handleSearchClick} />
      </Header>
      <div className={classes.createGroup}>
        <IconLabelButton
          item={'Add Group'}
          onClick={() => setOpenForm(!openForm)}
          open={openForm}
        />
      </div>
      <Collapse in={openForm}>
        <CreateThingGroup resetForm={openForm} query={['get_thing_group']} />
      </Collapse>
      <Box className={themeClasses.detailWrapper}>
        <Box
          className={[
            themeClasses.maxWidthTransition,
            themeClasses[update ? 'tableSmall' : 'tableLarge'],
          ].join(' ')}
        >
          <Table
            loading={loading}
            columns={THING_GROUP_COLUMNS}
            data={data?.thing_group || []}
            count={data?.rowcount?.aggregate?.count}
            manualPagination
            pageSize={ROWS_PER_PAGE}
            onChangePage={handlePageChange}
            onSort={handleSort}
            //onRowClick={handleRowClick}
            onDeleteRows={handleDelete}
            onUpdateRow={handleUpdate}
          />
        </Box>
        <Box
          className={[
            themeClasses.maxWidthTransition,
            themeClasses[update ? 'contentOpen' : 'contentClosed'],
          ].join(' ')}
        >
          {update && (
            <>
              <Paper
                className={[
                  themeClasses.maxWidthTransition,
                  themeClasses.detailsTop,
                  themeClasses[update ? 'padding' : ''],
                ].join(' ')}
              >
                <IconButton
                  aria-label="close"
                  onClick={() => setUpdate(!update)}
                >
                  <ChevronRight fontSize={'large'} />
                </IconButton>
                <Typography variant="h5">Update</Typography>
              </Paper>
              <Update
                orderBy={orderBy}
                offset={offset}
                limit={ROWS_PER_PAGE}
                description={updateDescription}
                name={updateName}
                id={updateID}
              />
            </>
          )}
        </Box>
      </Box>
    </>
  );
}
