import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const AntSwitch = withStyles((theme) => ({
  root: (props: { disabled?: boolean }) => ({
    width: 38,
    height: 18,
    padding: 0,
    display: 'flex',
  }),
  switchBase: (props: { disabled?: boolean }) => ({
    padding: 2,
    color: theme.palette.grey[500],
    '&$checked': {
      transform: 'translateX(12px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  }),
  thumb: (props: { disabled?: boolean }) => ({
    width: 14,
    height: 14,
    boxShadow: 'none',
  }),
  track: (props: { disabled?: boolean }) => ({
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 18 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  }),
  checked: {},
}))(Switch);

type Props = {
  handleChange: () => void;
  checked: any;
  disabled?: boolean;
};
export default function VariableSwitch({
  handleChange,
  checked,
  disabled,
}: Props) {
  return (
    <Typography
      component="div"
      style={{
        width: '80%',
        marginBottom: 20,
        marginRight: 20,
      }}
    >
      <Grid component="label" container alignItems="center" spacing={1}>
        <Grid item>
          <div
            style={{
              color: disabled ? 'rgba(0, 0, 0, 0.38)' : 'rgba(0, 0, 0, 0.87)',
            }}
          >
            Fixed
          </div>
        </Grid>
        <Grid item>
          <AntSwitch
            checked={checked}
            onChange={handleChange}
            name="variable"
            id="switch"
            disabled={disabled}
          />
        </Grid>
        <Grid item>
          <div
            style={{
              color: disabled ? 'rgba(0, 0, 0, 0.38)' : 'rgba(0, 0, 0, 0.87)',
            }}
          >
            Variable
          </div>
        </Grid>
      </Grid>
    </Typography>
  );
}
