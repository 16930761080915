import React, { Fragment, useEffect, useRef, useState } from 'react';
import {
  PureQueryOptions,
  RefetchQueriesFunction,
  gql,
  useLazyQuery,
  useMutation,
  useQuery,
} from '@apollo/client';
import { createStyles, lighten, styled, Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { THINGS_QUERY } from '../pages/things/index';
import SnackMessage from 'components/SnackMessage';
import {
  Box,
  FormLabel,
  Modal,
  Paper,
  TextField,
  Typography,
  colors,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Entity, IIndexable } from 'types';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { THING_TYPE_BY_CATEGORY } from './CreateThing';
import VariableSwitch from './Switch';
import { sortDataByAlpha } from 'utils/helpers';
import MIMMI from './MiMMi';
import { THING_GROUP } from 'pages/things/group';
import { GROUPTYPE } from './UpdateCategory';
import { StyledBox, THING_GROUP_CATEGORY } from './CreateIndexThing';
import { useAuth } from 'hooks/useAuth';
import EditIcon from '@material-ui/icons/Edit';
import Form from './Form';
import useForm from 'hooks/useForm';
import {
  GET_CLIENTS,
  GET_CONTRACTORS,
  GET_CLIENT_BUILDINGS,
} from 'graphql/common-queries';
import { GET_ROOTS } from './CreateEnergyMeter';
import { GET_TASKS_QUERY } from 'pages/prism-install';

type TasksProps = {
  openForm: boolean;

  searchFilters?: any;
  closeForm: any;
};
type Task = {
  task_num: string;
  created_at: string;
};

export const ADD_PRISM_TASK_INSTALL = gql`
  mutation addPrismTask(
    $assigned_date: timestamptz
    $building_id: uuid!
    $client_org_id: uuid!
    $contractor_org_id: uuid!
    $created_by: uuid!
    $description: String
    $status_id: uuid
    $due_date: timestamptz
    $complete_date: timestamptz
  ) {
    insert_task_tasks_one(
      object: {
        assigned_date: $assigned_date
        building_id: $building_id
        client_org_id: $client_org_id
        contractor_org_id: $contractor_org_id
        created_by: $created_by
        description: $description
        status_id: $status_id
        due_date: $due_date
        complete_date: $complete_date
      }
    ) {
      assigned_date
      client_org {
        name
      }
      building_id
      building {
        name
      }
      client_org_id
      complete_date
      created_at
      created_by
      due_date
      description
      id
      contractor_org_id
      contractor_org {
        name
      }
      number
      status_id
      status {
        name
      }
    }
  }
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      width: '60%',
      alignContent: 'center',
      justifyContent: 'center',
      padding: theme.spacing(1),
      '& .MuiInputBase-root': {
        marginBottom: theme.spacing(1),
      },
      '& .MuiFormLabel-root': {
        marginBottom: theme.spacing(1),
      },
    },
    autocomplete: {
      width: '70%',
      marginBottom: 20,
      marginLeft: '10%',
    },
    switch: {
      width: '80%',
      marginBottom: 20,
      marginLeft: '10%',
      marginRight: 20,
    },
    date: {
      boderColor: 'rgba(0, 0, 0, 0.10)',
      borderWidth: 1,
      outlineColor: 'rgba(0, 0, 0, 0.10)',
      marginRight: 20,
      marginBottom: 20,
    },
    dateStyle: (props: { dueDate: string | null }) => ({
      color:
        props.dueDate === '' ? 'rgba(0, 0, 0, 0.50)' : 'rgba(0, 0, 0, 0.80)',
      marginRight: 20,
      marginBottom: 20,
    }),

    icon: {
      width: 30,
      display: 'flex',
      justifyContent: 'flex-end',
      '&:hover': {
        color: lighten(theme.palette.secondary.light, 0),
        fontSize: 30,
      },
    },

    footer: {
      display: 'flex',
      width: '100%',
      backgroundColor: lighten(theme.palette.secondary.main, 0.85),
      padding: theme.spacing(1),
      justifyContent: 'space-between',
    },
    mainList: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
    listItemText: {
      color: theme.palette.secondary.main,
      marginLeft: 10,
    },
  })
);

const PaperWrapper = styled(Paper)(({ theme }) => ({
  borderTop: '5px solid',
  borderColor: theme.palette.secondary.main,
  width: 800,

  left: '30%',
  top: '10%',
  position: 'absolute',
}));

const CHARACTER_LIMIT = 2000;

export default function ADD_PRISM_INSTALL({
  openForm,
  closeForm,
  searchFilters,
}: TasksProps) {
  const { user } = useAuth();

  const created_by = user?.sub;

  const input = useRef<HTMLInputElement>(null);
  const { inputs, handleChange, clearForm, setInputs } = useForm({
    contractor_org_id: null,
    client_org_id: null,
    root_id: null,
  });

  const [successMessage, setSuccessMessage] = useState('');

  const [characterValue, setCharacterValue] = React.useState('');
  // const [existingCountJobId, setExistingCountJobId] = useState('');
  const [dueDate, setDueDate] = useState('');

  const classes: IIndexable = useStyles({ dueDate });

  const { data: clientData } = useQuery(GET_CLIENTS, {
    fetchPolicy: 'no-cache',
  });
  const { data: contractorData } = useQuery(GET_CONTRACTORS, {
    fetchPolicy: 'no-cache',
  });

  // const [getJobID, { data: prismTaskNumData }] = useLazyQuery(
  //   GET_TASKS_NUM_QUERY,
  //   {
  //     fetchPolicy: 'network-only',
  //     onCompleted: ({ task_tasks }) => {
  //       let dataArr = [...task_tasks] as Task[];
  //       let a = dataArr.sort((r, b) =>
  //         b.created_at.localeCompare(r.created_at)
  //       );
  //       let f = parseInt(a[0].task_num);
  //       f = ++f;

  //       return setExistingCountJobId(f.toString() || '0000'); //this logic will come from backend adding 0000 for no initial value
  //     },
  //   }
  // );
  // useEffect(() => {
  //   getJobID();
  // }, [existingCountJobId]);

  const [getRoots, { data: clientBuildingData }] = useLazyQuery(
    GET_CLIENT_BUILDINGS,
    {
      variables: {
        org_id: inputs.client_org_id,
      },

      fetchPolicy: 'no-cache',
    }
  );

  const [addPrismTask, { loading, error }] = useMutation(
    ADD_PRISM_TASK_INSTALL,
    {
      errorPolicy: 'all',

      refetchQueries: [
        {
          query: GET_TASKS_QUERY,
          variables: {
            ...searchFilters,
          },
        },
      ],
      fetchPolicy: 'no-cache',
      onCompleted: ({ insert_task_tasks_one: { number } }) => {
        setSuccessMessage(`new task with id ${number} for added successfully!`);
      },
    }
  );

  useEffect(() => {
    if (inputs.client_org_id) {
      getRoots();
    }
  }, [inputs.client_org_id]);

  const handleCharacterChange = (event) => {
    setCharacterValue(event.target.value);
  };

  const contractorsDataArr = contractorData?.organisation_contractors;
  const clientDataArr = clientData?.organisation_clients;

  const contractorSortedOptions =
    contractorData && sortDataByAlpha([...contractorsDataArr]);
  const clientSortedOptions = clientData && sortDataByAlpha([...clientDataArr]);

  const buildingData = clientBuildingData?.spaces;

  const buildingSortedOptions =
    clientBuildingData && sortDataByAlpha([...buildingData]);
  const handleAssignedDateChange = (e) => {
    const value = e.target.value || undefined;
    setDueDate(value);
  };

  function handleReset() {
    input?.current?.blur();
    closeForm(!openForm);
    setCharacterValue('');
    // getJobID();
    // setExistingCountJobId('');
    setDueDate('');
    setInputs({
      contractor_org_id: null,
      client_org_id: null,
      root_id: null,
    });
  }

  async function handleSaveButton(params: any) {
    if (
      inputs.contractor_org_id &&
      inputs.client_org_id &&
      inputs.root_id &&
      characterValue.length !== 0 &&
      dueDate !== ''
    ) {
      await addPrismTask({
        variables: {
          assigned_date: new Date(),
          building_id: inputs.root_id,
          client_org_id: inputs.client_org_id,

          contractor_org_id: inputs.contractor_org_id,
          created_by,
          description: characterValue,
          // task_num: existingCountJobId,
          status_id: '95d433dc-50b2-484c-bf08-8ee445faed02',
          due_date: dueDate,
          complete_date: dueDate,
        },
      });
      input?.current?.blur();
      closeForm(!openForm);
      setCharacterValue('');
      // getJobID();
      // setExistingCountJobId('');
      setDueDate('');
      setInputs({
        contractor_org_id: null,
        client_org_id: null,
        root_id: null,
      });
    }
  }

  return (
    <Fragment>
      <SnackMessage
        message={successMessage}
        onClose={() => setSuccessMessage('')}
        type="success"
      />
      <Modal open={openForm} onClose={closeForm}>
        <>
          <StyledBox>
            <PaperWrapper elevation={3} square>
              {/* <SnackMessage message={updateError?.message} type="error" /> */}
              <div style={{ margin: 10 }}>
                <FormLabel>New Prism Install Task</FormLabel>
              </div>
              {/* <TextField
                className={classes.autocomplete}
                // label={existingCountJobId}
                variant="filled"
                disabled
              /> */}
              <Autocomplete
                className={classes.autocomplete}
                id="client-org-select"
                options={
                  clientSortedOptions?.map((org: Entity) => org.id) || []
                }
                getOptionLabel={(option: string) =>
                  clientDataArr?.find((org: Entity) => org.id === option)
                    ?.name || ''
                }
                value={inputs.client_org_id}
                onChange={(_, value) => {
                  handleChange({ name: 'client_org_id', value });
                }}
                disableClearable={true}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Client Organisation"
                    variant="filled"
                  />
                )}
              />
              <Autocomplete
                className={classes.autocomplete}
                id="root-prism-select"
                fullWidth
                options={
                  buildingSortedOptions?.map((space: Entity) => space.id) || []
                }
                disableClearable={true}
                getOptionLabel={(option: string) =>
                  clientBuildingData?.spaces.find(
                    (space: Entity) => space.id === option
                  )?.name || ''
                }
                value={inputs.root_id}
                onChange={(_, value) =>
                  handleChange({ name: 'root_id', value })
                }
                renderInput={(params) => (
                  <TextField {...params} label="Building" variant="filled" />
                )}
              />
              <TextField
                id="standard-multiline-flexible"
                label="Description"
                multiline
                variant="filled"
                className={classes.autocomplete}
                rows={4}
                inputProps={{ maxLength: CHARACTER_LIMIT, minLength: 3 }}
                error={
                  (characterValue.length > 0 && characterValue.length < 20) ||
                  characterValue.length > 2000
                }
                helperText={
                  <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <span>
                      {`(min 20 - max 2000 characters )${characterValue.length}/${CHARACTER_LIMIT}`}
                    </span>
                  </Box>
                }
                onChange={handleCharacterChange}
              />
              <Autocomplete
                className={classes.autocomplete}
                id="contractor-org-select"
                options={
                  contractorSortedOptions?.map((org: Entity) => org.id) || []
                }
                getOptionLabel={(option: string) =>
                  contractorData?.organisation_contractors.find(
                    (org: Entity) => org.id === option
                  )?.name || ''
                }
                value={inputs.contractor_org_id}
                onChange={(_, value) => {
                  handleChange({ name: 'contractor_org_id', value });
                }}
                disableClearable={true}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Contractor Organisation"
                    variant="filled"
                  />
                )}
              />
              <div
                style={{
                  justifyContent: 'space-between',
                  display: 'flex',

                  width: '100%',
                }}
              >
                <div
                  style={{
                    justifyContent: 'space-between',
                    marginTop: 5,
                    marginLeft: 10,
                    marginBottom: 10,

                    display: 'flex',
                    width: '65%',
                  }}
                >
                  <Typography color="textSecondary">Install Date:</Typography>
                  <Typography color="textSecondary">From:</Typography>
                </div>

                <TextField
                  id="datetime-local-prism"
                  type="datetime-local"
                  value={dueDate}
                  color="secondary"
                  InputLabelProps={{
                    className: classes.date,
                    shrink: true,
                  }}
                  InputProps={{
                    className: classes.dateStyle,
                  }}
                  onChange={handleAssignedDateChange}
                />
              </div>
              <div className={classes.footer}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleReset}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleSaveButton}
                  disabled={
                    !inputs.contractor_org_id ||
                    !inputs.client_org_id ||
                    !inputs.root_id ||
                    characterValue === '' ||
                    characterValue.length < 20 ||
                    characterValue.length > 2000 ||
                    dueDate === ''
                  }
                >
                  Save
                </Button>
              </div>
            </PaperWrapper>
          </StyledBox>
        </>
      </Modal>
    </Fragment>
  );
}
