import { gql, useMutation, useQuery } from '@apollo/client';
import { Fragment, useState } from 'react';
import { Row } from 'react-table';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Collapse,
  IconButton,
  Paper,
  Typography,
} from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Table from 'components/Table';
import Header from 'components/Header';
import SearchBar from 'components/SearchBar';
import Title from 'components/Title';
import CreateSensorNode from 'components/CreateSensorNode';
import IconLabelButton from 'components/IconLabelButton';

import { ALL_SENSOR_NODE_NODES } from 'constants/data';
import SnackMessage from 'components/SnackMessage';
import { Entity, SpaceThing } from 'types';
import { IIndexable } from 'types';
import { useThemeStyles } from 'theme';
import Update from '../../components/UpdateSensorNode';
import { ChevronRight } from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      marginBottom: 50,
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
    },

    chart: {
      display: 'flex',
      width: '40%',
    },

    createSpace: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginBottom: 10,
    },
  })
);

export const SENSOR_NODES_QUERY = gql`
  query get_sensor_node_nodes(
    $name: String
    $limit: Int
    $offset: Int
    $orderBy: [sensor_node_nodes_order_by!]
  ) {
    sensor_node_nodes(
      where: { name: { _ilike: $name } }
      limit: $limit
      offset: $offset
      order_by: $orderBy
    ) {
      description
      id
      model
      name
    }
    rowcount: sensor_node_nodes_aggregate {
      aggregate {
        count
      }
    }
  }
`;

const DELETE_SENSOR_NODES = gql`
  mutation deleteSensorNodes($ids: [uuid!]!) {
    delete_sensor_node_nodes(where: { id: { _in: $ids } }) {
      returning {
        id
        name
      }
    }
  }
`;

const ROWS_PER_PAGE = 20;

export default function SENSOR_NODE_NODES() {
  const classes = useStyles();
  const themeClasses: IIndexable = useThemeStyles();

  const [offset, setOffset] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [name, setName] = useState('');
  const [orderBy, setOrderBy] = useState({});
  const [openForm, setOpenForm] = useState<boolean>(false);
  const [deleteSuccessMessage, setDeleteSuccessMessage] = useState('');
  const [update, setUpdate] = useState(false);
  const [updateID, setUpdateID] = useState('');
  const [updateName, setUpdateName] = useState('');
  const [updateModel, setUpdateModel] = useState('');
  const [updateDescription, setUpdateDescription] = useState('');

  const history = useHistory();

  const { data, loading } = useQuery(SENSOR_NODES_QUERY, {
    variables: {
      offset: offset,
      limit: ROWS_PER_PAGE,
      name: `%${name}%`,
      orderBy,
    },
  });

  const [deleteSensorNode, { error: deleteError }] = useMutation(
    DELETE_SENSOR_NODES,
    {
      update(
        cache,
        {
          data: {
            delete_sensor_node_nodes: { returning },
          },
        }
      ) {
        returning.forEach((thing: SpaceThing) =>
          cache.evict({ id: cache.identify(thing) })
        );
        cache.gc();

        const deletedIds = returning.map((sensorNode: Entity) => sensorNode.id);
        const cachedCount: any = cache.readQuery({
          query: SENSOR_NODES_QUERY,
          variables: {
            offset: offset,
            limit: ROWS_PER_PAGE,
            name: `%${name}%`,
            orderBy,
          },
        });
        const updatedCount =
          cachedCount.rowcount.aggregate.count - deletedIds.length;

        cache.writeQuery({
          query: SENSOR_NODES_QUERY,
          variables: {
            offset: offset,
            limit: ROWS_PER_PAGE,
            name: `%${name}%`,
            orderBy,
          },

          data: {
            rowcount: {
              aggregate: {
                count: updatedCount,
              },
            },
          },
        });
      },
      onCompleted: ({ delete_sensor_node_nodes: { returning } }) => {
        setDeleteSuccessMessage(
          `${
            returning.length > 1
              ? `${returning.length} sensor nodes`
              : returning[0].name
          } deleted successfully`
        );
      },
    }
  );

  function handlePageChange(newPage: number) {
    setPage(newPage);
    setOffset(newPage * ROWS_PER_PAGE);
  }

  const handleSearchClick = (val: string) => {
    setPage(0);
    setName(val);
    setOffset(0);
  };

  function handleSort(sortArray: []) {
    const orderObj = sortArray.reduce((acc, sort) => {
      let { id, desc } = sort;
      return { ...acc, [id]: desc ? 'desc' : 'asc' };
    }, {});
    setOrderBy(orderObj);
  }

  function handleRowClick({ values }: Row) {
    history.push(`/sensor_nodes/${values.id}`);
  }

  function handleDelete(rows: any) {
    const ids = rows.map((r: Row<Entity>) => r.values.id);
    deleteSensorNode({ variables: { ids } });
  }

  function handleUpdate({ values }: Row) {
    const { id, model, name, description } = values;

    setUpdate(!update);
    setUpdateID(id);
    setUpdateName(name);
    setUpdateDescription(description);
    setUpdateModel(model);
  }

  return (
    <>
      <SnackMessage message={deleteError?.message} type="error" />
      <SnackMessage message={deleteSuccessMessage} type="success" />
      <Header>
        <Title heading="Browse Sensor Nodes" />
        <SearchBar handleSearch={handleSearchClick} />
      </Header>
      <div className={classes.createSpace}>
        <IconLabelButton
          item={'Add Sensor Node'}
          onClick={() => setOpenForm(!openForm)}
          open={openForm}
        />
      </div>
      <Collapse in={openForm}>
        <CreateSensorNode query={['get_sensor_node_nodes']} />
      </Collapse>
      <Box className={themeClasses.detailWrapper}>
        <Box
          className={[
            themeClasses.maxWidthTransition,
            themeClasses[update ? 'tableSmall' : 'tableLarge'],
          ].join(' ')}
        >
          <Table
            loading={loading}
            columns={ALL_SENSOR_NODE_NODES}
            data={data?.sensor_node_nodes || []}
            count={data?.rowcount?.aggregate?.count}
            manualPagination
            pageSize={ROWS_PER_PAGE}
            onChangePage={handlePageChange}
            onSort={handleSort}
            onRowClick={handleRowClick}
            onDeleteRows={handleDelete}
            onUpdateRow={handleUpdate}
          />
        </Box>
        <Box
          className={[
            themeClasses.maxWidthTransition,
            themeClasses[update ? 'contentOpen' : 'contentClosed'],
          ].join(' ')}
        >
          <Paper
            className={[
              themeClasses.maxWidthTransition,
              themeClasses.detailsTop,
              themeClasses[update ? 'padding' : ''],
            ].join(' ')}
          >
            <IconButton aria-label="close" onClick={() => setUpdate(!update)}>
              <ChevronRight fontSize={'large'} />
            </IconButton>
            <Typography variant="h5">Update</Typography>
          </Paper>
          {update && (
            <Update
              orderBy={orderBy}
              offset={offset}
              limit={ROWS_PER_PAGE}
              model={updateModel}
              description={updateDescription}
              name={updateName}
              id={updateID}
            />
          )}
        </Box>
      </Box>
    </>
  );
}
