import { FormLabel, styled, TextField } from '@material-ui/core';


export const StyledTextField = styled(TextField)(({ theme }) => ({
    flexGrow: 1,
    marginRight: theme.spacing(2),
  }));

  export const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
    width: '100%',
  }));

  
