import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { FormControlLabel } from '@material-ui/core';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

const GreenCheckbox = withStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,
    '&$checked': {
      color: theme.palette.primary.main,
    },
  },
  checked: {},
}))((props: CheckboxProps) => <Checkbox color="default" {...props} />);

type Props = {
  handleChangeMMI: () => void;
  handleChangeMI: () => void;
  checkedMI: boolean;
  checkedMMI: boolean;
  disabled?: boolean;
};

export default function MIMMI({
  handleChangeMMI,
  handleChangeMI,
  checkedMI,
  checkedMMI,
  disabled,
}: Props) {
  return (
    <div
      style={{
        //  flex: 1,
        display: 'flex',
        flexDirection: 'row',
      }}
    >
      <FormControlLabel
        control={
          <GreenCheckbox
            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
            checkedIcon={<CheckBoxIcon fontSize="small" />}
            name="MI"
            onChange={handleChangeMI}
            checked={checkedMI}
            disabled={disabled}
          />
        }
        label="MI"
      />
      <FormControlLabel
        control={
          <GreenCheckbox
            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
            checkedIcon={<CheckBoxIcon fontSize="small" />}
            name="MMI"
            onChange={handleChangeMMI}
            checked={checkedMMI}
            disabled={disabled}
          />
        }
        label="MMI"
      />
    </div>
  );
}
