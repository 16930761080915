import React from 'react';
import {
  createStyles,
  makeStyles,
  Theme,
  styled,
  lighten,
} from '@material-ui/core/styles';
import { Button, Paper } from '@material-ui/core';

import { IIndexable } from 'types';
import LinearLoader from './LinearLoader';

type FormProps = {
  onSubmit?(): void;
  busy?: boolean;
  inline?: boolean;
  inComplete?: boolean;
};

const FormWrapper = styled(Paper)(({ theme }) => ({
  borderTop: '5px solid',
  borderColor: theme.palette.secondary.main,
}));

const SubmitButton = styled(Button)(({ theme }) => ({
  marginRight: theme.spacing(1),
  marginLeft: theme.spacing(1),
}));

const InlineSubmitButton = styled(Button)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  display: 'flex',
  justifyContent: 'flex-end',
  marginLeft: 'auto',
}));

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      padding: theme.spacing(2),
      '& .MuiInputBase-root': {
        marginBottom: theme.spacing(2),
      },
      '& .MuiFormLabel-root': {
        marginBottom: theme.spacing(2),
      },
    },
    footer: {
      display: 'flex',
      width: '100%',
      backgroundColor: lighten(theme.palette.secondary.main, 0.85),
      padding: theme.spacing(2),
      justifyContent: 'flex-end',
    },
    inline: {
      display: 'inline',
    },
  })
);

const Form: React.FC<FormProps> = ({
  children,
  onSubmit,
  busy,
  inline,
  inComplete,
}) => {
  const classes: IIndexable = useStyles();

  return (
    <FormWrapper elevation={3} square>
      <form className={classes.root} autoComplete="off">
        {children}
        {inline && onSubmit && (
          <InlineSubmitButton
            variant="contained"
            color="secondary"
            onClick={onSubmit}
            disabled={busy || inComplete}
            type="submit"
          >
            Submit
          </InlineSubmitButton>
        )}
      </form>
      {!inline && onSubmit && (
        <div className={classes.footer}>
          <SubmitButton
            variant="contained"
            color="secondary"
            onClick={onSubmit}
            disabled={busy || inComplete}
          >
            Submit
          </SubmitButton>
        </div>
      )}
      {busy && <LinearLoader />}
    </FormWrapper>
  );
};

export default Form;
