import {
  Breadcrumbs as MUIBreadcrumbs,
  Typography,
  Link,
  LinkProps,
} from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import { Link as RouterLink } from 'react-router-dom';
import useBreadcrumbs from 'use-react-router-breadcrumbs';

import { routes } from 'App';

const MUIStyledBreadcrumbs = styled(MUIBreadcrumbs)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  marginRight: theme.spacing(1),
}));

interface LinkRouterProps extends LinkProps {
  to: string;
  replace?: boolean;
}

const LinkRouter = (props: LinkRouterProps) => (
  <Link {...props} component={RouterLink as any} />
);

const Breadcrumbs = () => {
  const breadcrumbs = useBreadcrumbs(routes);

  return (
    <MUIStyledBreadcrumbs aria-label="breadcrumb">
      {breadcrumbs.map(({ breadcrumb, match }, index) => {
        const isLast = index === breadcrumbs.length - 1;

        return isLast ? (
          <Typography variant="h5" key={index}>
            {breadcrumb}
          </Typography>
        ) : (
          <LinkRouter to={match.url} key={index}>
            {breadcrumb}
          </LinkRouter>
        );
      })}
    </MUIStyledBreadcrumbs>
  );
};

export default Breadcrumbs;
