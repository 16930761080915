import { useState } from 'react';
import { Box, FormLabel, TextField, styled } from '@material-ui/core';
import {
  gql,
  PureQueryOptions,
  RefetchQueriesFunction,
  useMutation,
} from '@apollo/client';
import Form from './Form';
import SnackMessage from './SnackMessage';
import useForm from '../hooks/useForm';
import { trimmedFields } from 'utils/helpers';

const ADD_SENSOR_NODE = gql`
  mutation ($name: String!, $model: String!, $description: String) {
    insert_sensor_node_nodes_one(
      object: { model: $model, name: $name, description: $description }
    ) {
      id
      model
      name
      description
    }
  }
`;

type Query = {
  query: RefetchQueriesFunction | (string | PureQueryOptions)[] | undefined;
};

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-around',
  margin: theme.spacing(2),
  transition: 'all .5s',
}));

export default function CreateSensorNode({ query }: Query) {
  const { inputs, handleChange, clearForm } = useForm({
    name: '',
    description: '',
    model: '',
  });

  const [AddSensorNode, { loading, error }] = useMutation(ADD_SENSOR_NODE, {
    variables: trimmedFields(inputs),
    errorPolicy: 'all',
    refetchQueries: query,
    onCompleted: ({ insert_sensor_node_nodes_one }) => {
      setSuccessMessage(
        `${insert_sensor_node_nodes_one?.name} added successfully!`
      );
    },
  });

  const [successMessage, setSuccessMessage] = useState('');

  async function handleSubmit() {
    await AddSensorNode();
    clearForm();
  }

  return (
    <StyledBox>
      <SnackMessage message={error?.message} type="error" />
      <SnackMessage message={successMessage} type="success" />
      <Form onSubmit={handleSubmit} busy={loading}>
        <FormLabel>ADD SENSOR NODE</FormLabel>
        <TextField
          name="name"
          label="name"
          variant="filled"
          fullWidth
          disabled={loading}
          value={inputs.name}
          onChange={handleChange}
        />
        <TextField
          name="model"
          label="model"
          variant="filled"
          fullWidth
          disabled={loading}
          value={inputs.model}
          onChange={handleChange}
        />
        <TextField
          name="description"
          label="description"
          variant="filled"
          fullWidth
          disabled={loading}
          value={inputs.description}
          onChange={handleChange}
        />
      </Form>
    </StyledBox>
  );
}
