import { useState } from 'react';

export default function useForm(initial: any = {}) {
  // create a state object for inputs
  const [inputs, setInputs] = useState(initial);

  // TODO: initial error state before any inputs are changed?
  const [errors, setErrors] = useState(initial);
  const [isValid, setIsValid] = useState(true);

  function handleChange(e: any) {
    let { value, name, type, required } = e.target !== undefined ? e.target : e;
    let valueCopy: any = value;

    if (type === 'number') {
      valueCopy = parseInt(value);
    }

    if (required && !value) {
      setErrors({
        ...errors,
        [name]: `${name} is required`,
      });
    } else {
      setErrors({
        ...errors,
        [name]: '',
      });
    }

    setInputs({
      ...inputs,
      [name]: valueCopy,
    });
  }

  function overrideValue(name: string, value: any) {
    setInputs({ [name]: value });
  }

  function resetForm() {
    setInputs(initial);
  }

  function clearForm() {
    const blankState = Object.fromEntries(
      Object.entries(inputs).map(([key, value]) => [key, ''])
    );
    setInputs(blankState);
  }

  // TODO: validation requires more work
  function validateForm() {
    setIsValid(Object.values(errors).every((error) => error === ''));
  }

  return {
    inputs,
    setInputs,
    errors,
    handleChange,
    validateForm,
    overrideValue,
    isValid,
    resetForm,
    clearForm,
  };
}
