import { useEffect, useState } from 'react';
import { Snackbar } from '@material-ui/core';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

type ErrorProps = {
  message?: String;
  type?: 'error' | 'warning' | 'info' | 'success';
  onClose?(): void;
};

const ErrorMessage: React.FC<ErrorProps> = ({ message, type = 'info', onClose }) => {
  const [open, setOpen] = useState(false);

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    if (onClose) {
      onClose();
    }
    setOpen(false);
  };

  useEffect(() => {
    if (message) {
      setOpen(true);
    }
  }, [message]);

  if (!message) return null;
  
  return (
    <Snackbar open={open} autoHideDuration={6000}  onClose={handleClose}>
      <Alert onClose={handleClose} severity={type}>
        {message.replace('GraphQL error: ', '')}
      </Alert>
    </Snackbar>
  );
};

export default ErrorMessage;
