import { Fragment, useEffect, useState } from 'react';
import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  Box,
  Collapse,
  IconButton,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import { Column, Row } from 'react-table';
import { useHistory } from 'react-router-dom';
import IconLabelButton from 'components/IconLabelButton';
import Title from 'components/Title';
import Header from 'components/Header';
import Table from 'components/Table';
import CreateEnergyMeterInstance, {
  GET_ROOTS,
} from 'components/CreateEnergyMeter';
import SnackMessage from 'components/SnackMessage';
import SearchBar from 'components/SearchBar';
import { EnergyMeter, IIndexable } from 'types';
import { columns } from 'constants/data';
import Update from '../../components/UpdateEnergyMeter';
import { ChevronRight } from '@material-ui/icons';
import { useThemeStyles } from 'theme';
import Autocomplete from '@material-ui/lab/Autocomplete/Autocomplete';
import AutocompleteORG from 'components/OrgAutoComplete';
import { GET_ALL_SPACES_FOR_BUILDING } from 'graphql/common-queries';
import { sortDataByAlpha } from 'utils/helpers';

const ENERGY_METER_ANNOTATIONS_ATTRIBUTES = gql`
  fragment energyMeterAnnotationsAttributes on energy_meter_instance {
    annotations {
      created_at
      created_by
      energy_meter_instance_id
      id
      key
      org_id
      type
      value
    }
  }
`;

const ENERGY_METER_ATTRIBUTES = gql`
  fragment energyMeterAttributes on energy_meter_instance {
    id
    org_id
    energy_meter_id
    hardware_id
    created_at
    ...energyMeterAnnotationsAttributes

    building {
      name
      building_id
    }
    space {
      name
      space_id
    }
    organisation {
      name
    }
    spec {
      id
      model
      name
      description
    }
  }
  ${ENERGY_METER_ANNOTATIONS_ATTRIBUTES}
`;

export const GET_ENERGY_METERS = gql`
  query getEnergyMeters(
    $limit: Int
    $hardware_id: String
    $cursor: timestamptz
    $building_id: uuid
    $org_id: uuid
    $space_id: uuid
  ) {
    energy_meter_instance(
      limit: $limit
      where: {
        hardware_id: { _ilike: $hardware_id }
        created_at: { _lt: $cursor }
        org_id: { _eq: $org_id }
        _and: [
          { building: { building_id: { _eq: $building_id } } }
          { space: { space_id: { _eq: $space_id } } }
        ]
      }
      order_by: { created_at: desc }
    ) {
      ...energyMeterAttributes
    }
  }
  ${ENERGY_METER_ATTRIBUTES}
`;

const DELETE_ENERGY_METER_INSTANCES = gql`
  mutation deleteEnergyMeterInstances($ids: [uuid!]!) {
    delete_energy_meter_instance(where: { id: { _in: $ids } }) {
      returning {
        ...energyMeterAttributes
      }
    }
  }
  ${ENERGY_METER_ATTRIBUTES}
`;

export const GET_ENERGY_METER_COUNT = gql`
  query getEnergyMeterCount(
    $hardware_id: String
    $building_id: uuid
    $org_id: uuid
    $space_id: uuid
  ) {
    energy_meter_instance_aggregate(
      where: {
        org_id: { _eq: $org_id }
        hardware_id: { _ilike: $hardware_id }
        _and: [
          { space: { space_id: { _eq: $space_id } } }
          { building: { building_id: { _eq: $building_id } } }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const SpecList = ({ spec }: any) => (
  <>
    <Typography>{spec.model}</Typography>
    <Typography>{spec.description}</Typography>
  </>
);

const ROWS_PER_PAGE = 20;

export default function EnergyMeters() {
  const themeClasses: IIndexable = useThemeStyles();
  const [inputs, setInputs] = useState({
    name: '',
    org_id: null,
  });
  const [root, setRoot] = useState<string | null>(null);
  const [cursor, setCursor] = useState<string>('now');
  const [openForm, setOpenForm] = useState(false);
  const [deleteSuccessMessage, setDeleteSuccessMessage] = useState('');
  const [hardwareIdFilter, setHardwareIdFilter] = useState('');
  const [controlledPageIndex, setControlledPage] = useState(0);
  const [update, setUpdate] = useState(false);
  const [updateID, setUpdateID] = useState('');
  const [orgName, setOrgName] = useState('');
  const [updateORGID, setUpdateORGID] = useState<string | null>(null);
  const [filterSpace, setFilterSpace] = useState({
    id: null,
    name: '',
  });
  const [space, setSpace] = useState<string | null>(null);

  const [rootID, setRootID] = useState({
    id: root,
    name: '',
  });
  const [updateHardwareID, setUpdateHardwareID] = useState('');

  const history = useHistory();

  const { data, loading } = useQuery(GET_ENERGY_METERS, {
    variables: {
      limit: ROWS_PER_PAGE,
      hardware_id: `%${hardwareIdFilter}%`,
      org_id: inputs?.org_id,
      building_id: rootID?.id,
      space_id: filterSpace?.id,
      cursor,
    },
  });

  const { data: emAggData } = useQuery(GET_ENERGY_METER_COUNT, {
    variables: {
      hardware_id: `%${hardwareIdFilter}%`,
      org_id: inputs?.org_id,
      building_id: rootID?.id,
      space_id: filterSpace?.id,
    },
  });
  const [getRoots, { data: rootData }] = useLazyQuery(GET_ROOTS, {
    variables: {
      org_id: inputs?.org_id,
    },
  });

  useEffect(() => {
    getRoots();
  }, []);

  const [getSpaces, { data: spacesData }] = useLazyQuery(
    GET_ALL_SPACES_FOR_BUILDING,
    {
      variables: {
        root_id: rootID.id,
        building_id: rootID.id,
      },
    }
  );

  useEffect(() => {
    getSpaces();
  }, [rootID.id]);

  const [deleteEnergyMeter, { error: deleteError }] = useMutation(
    DELETE_ENERGY_METER_INSTANCES,
    {
      update(
        cache,
        {
          data: {
            delete_energy_meter_instance: { returning },
          },
        }
      ) {
        returning.forEach((em: EnergyMeter) =>
          cache.evict({ id: cache.identify(em) })
        );
        cache.gc();
      },
      refetchQueries: ['getEnergyMeterCount'],
      onCompleted: ({ delete_energy_meter_instance: { returning } }) => {
        setDeleteSuccessMessage(
          `${
            returning.length > 1
              ? `${returning.length} energy meters`
              : returning[0].hardware_id
          } deleted successfully!`
        );
      },
    }
  );

  let pageData = data?.energy_meter_instance || [];

  function handleDelete(rows: Row<EnergyMeter>[]) {
    const ids = rows.map((r: Row<EnergyMeter>) => r.values.id);
    deleteEnergyMeter({ variables: { ids } });
  }

  function handleRowClick({ values }: Row<EnergyMeter>) {
    history.push(`/energy_meter/${values.id}`);
  }

  function handlePageChange(newCursor: string, newPage: number) {
    setControlledPage(newPage);
    setCursor(newCursor);
  }

  function handleSearch(value: string) {
    setCursor('now');
    setControlledPage(0);
    setHardwareIdFilter(value);
  }

  function handleUpdate({ values }: Row) {
    const selectedEM = data?.energy_meter_instance?.find(
      (c: any) => c.id === values.id
    );

    const existingRoot = selectedEM?.building?.building_id;

    setRoot(existingRoot);
    setUpdateHardwareID(selectedEM.hardware_id);
    setUpdateID(selectedEM.id);

    setUpdateORGID(selectedEM.org_id);

    setSpace(selectedEM.space.space_id);

    setUpdate(!update);
  }

  const buildingData = rootData?.spaces;

  const buildingSortedOptions = rootData && sortDataByAlpha([...buildingData]);
  const spaceData = spacesData?.spaces;

  const spaceSortedOptions = spacesData && sortDataByAlpha([...spaceData]);
  return (
    <Fragment>
      <SnackMessage message={deleteError?.message} type="error" />
      <SnackMessage message={deleteSuccessMessage} type="success" />
      <Header>
        <Title heading="Browse Energy Meter Instances" />
        <div className={themeClasses.search}>
          <AutocompleteORG
            onChange={(newValue: string | null | any) => {
              setInputs({ ...inputs, org_id: newValue?.id });
              setRootID({
                id: null,
                name: '',
              });
              setFilterSpace({
                id: null,
                name: '',
              });
            }}
            className={themeClasses.autocomplete}
          />

          {rootData && (
            <Autocomplete
              className={themeClasses.autocomplete}
              id="root-select"
              size="small"
              options={buildingSortedOptions || []}
              getOptionLabel={(option: any) => option?.name || ''}
              onChange={(event: any, newValue: string | null | any) => {
                setRootID({
                  id: newValue?.id,
                  name: newValue?.name,
                });
              }}
              value={rootID.id === null ? null : rootID}
              renderInput={(params) => (
                <TextField {...params} label="Building" variant="outlined" />
              )}
            />
          )}

          <Autocomplete
            className={themeClasses.autocomplete}
            id="space-select"
            size="small"
            options={spaceSortedOptions || []}
            getOptionLabel={(option: any) => {
              return option?.name || '';
            }}
            onChange={(event: any, newValue: string | null | any) => {
              setFilterSpace({
                id: newValue?.id,
                name: newValue?.name,
              });
            }}
            value={filterSpace.id === null ? null : filterSpace}
            renderInput={(params) => (
              <TextField {...params} label="Space" variant="outlined" />
            )}
          />
          <SearchBar
            //style={{ marginRight: '1em' }}
            handleSearch={handleSearch}
          />
        </div>
        <Box style={{ display: 'flex' }}>
          <IconLabelButton
            item={'Add Energy Meter Instance'}
            onClick={() => setOpenForm(!openForm)}
            open={openForm}
          />
        </Box>
      </Header>
      <Box>
        <Collapse in={openForm}>
          <CreateEnergyMeterInstance />
        </Collapse>

        <Box className={themeClasses.detailWrapper}>
          <Box
            className={[
              themeClasses.maxWidthTransition,
              themeClasses[update ? 'tableSmall' : 'tableLarge'],
            ].join(' ')}
          >
            <Table
              title="Energy Meters"
              data={pageData}
              columns={columns}
              count={
                emAggData?.energy_meter_instance_aggregate.aggregate.count || 0
              }
              manualPagination
              onChangePage={handlePageChange}
              pageSize={ROWS_PER_PAGE}
              loading={loading}
              onRowClick={handleRowClick}
              onDeleteRows={handleDelete}
              cursor="created_at"
              controlledPageIndex={controlledPageIndex}
              onUpdateRow={handleUpdate}
            />
          </Box>
          <Box
            className={[
              themeClasses.maxWidthTransition,
              themeClasses[update ? 'contentOpen' : 'contentClosed'],
            ].join(' ')}
          >
            <Paper
              className={[
                themeClasses.maxWidthTransition,
                themeClasses.detailsTop,
                themeClasses[update ? 'padding' : ''],
              ].join(' ')}
            >
              <IconButton aria-label="close" onClick={() => setUpdate(!update)}>
                <ChevronRight fontSize={'large'} />
              </IconButton>
              <Typography variant="h5">Update</Typography>
            </Paper>
            {update && (
              <Update
                hardware_id={updateHardwareID}
                limit={ROWS_PER_PAGE}
                cursor={cursor}
                hardwareIdFilter={hardwareIdFilter}
                id={updateID}
                space_id={space}
                building_id={root}
                org_id={updateORGID}
              />
            )}
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
}
