import { useEffect, useState } from 'react';
import {
  Box,
  FormLabel,
  TextField,
  styled,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import Form from './Form';
import SnackMessage from './SnackMessage';
import useForm from 'hooks/useForm';
import { EnergyMeter, Entity, SensorNodeSpec } from 'types';
import {
  GET_CLIENTS,
  GET_ALL_SPACES_FOR_BUILDING,
} from 'graphql/common-queries';
import { useAuth } from 'hooks/useAuth';
import { GET_ENERGY_METERS } from 'pages/energy-meter';
import { sortDataByAlpha, trimmedFields } from 'utils/helpers';

//This channel creation feature within the channels page is disabled from internal portal. Use this component when adding the feature back to portal. Also, user isnt able to delete the channels now"

const ADD_ENERGY_METER_INSTANCE = gql`
  mutation addEnergyMeterInstance(
    $energy_meter_id: uuid!
    $org_id: uuid!
    $created_by: uuid!
    $hardware_id: String!
    $root_id: String!
    $space_id: String!
    $colData: [energy_meter_channel_instance_insert_input!]!
  ) {
    insert_energy_meter_instance_one(
      object: {
        energy_meter_id: $energy_meter_id
        hardware_id: $hardware_id
        org_id: $org_id
        created_by: $created_by
        channels: { data: $colData }
        annotations: {
          data: [
            {
              key: "root_id"
              value: $root_id
              type: "system"
              org_id: $org_id
              created_by: $created_by
            }
            {
              key: "space_id"
              value: $space_id
              type: "system"
              org_id: $org_id
              created_by: $created_by
            }
          ]
        }
      }
    ) {
      id
      org_id
      created_at
      energy_meter_id
      hardware_id
      spec {
        id
        model
        name
        description
      }
    }
  }
`;

const GET_ENERGY_METER_SPECS = gql`
  query {
    energy_meter_energy_meter {
      id
      name
      model
      channels
    }
  }
`;

export const GET_ROOTS = gql`
  query getRoots($org_id: uuid!) {
    spaces(where: { org_id: { _eq: $org_id }, parent_id: { _is_null: true } }) {
      id
      name
    }
  }
`;

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-around',
  margin: theme.spacing(2),
  transition: 'all .5s',
}));

type ENERGY_METER_CACHE = {
  energy_meter_instance: EnergyMeter[];
};

export default function CreateEnergyMeterInstance() {
  const { user } = useAuth();
  const created_by = user?.sub;

  const [successMessage, setSuccessMessage] = useState('');
  const [errorDuplicate, setErrorDuplicate] = useState(false);
  const [helperText, setHelperText] = useState('');
  const [hardwareID, setHardwareID] = useState('');

  const { inputs, handleChange, resetForm, overrideValue } = useForm({
    org_id: null,
    root_id: null,
    space_id: null,
    hardware_id: '',
    energy_meter_id: '',
  });

  const [colData, setColData] = useState<any>([]);

  const { data } = useQuery(GET_CLIENTS);
  const { data: energyMeterData } = useQuery(GET_ENERGY_METERS);
  const { data: specData } = useQuery(GET_ENERGY_METER_SPECS);
  const [getRoots, { data: rootData }] = useLazyQuery(GET_ROOTS, {
    variables: {
      org_id: inputs.org_id,
    },
    fetchPolicy: 'no-cache',
  });
  const [getSpaces, { data: spacesData }] = useLazyQuery(
    GET_ALL_SPACES_FOR_BUILDING,
    {
      variables: {
        root_id: inputs.root_id,
        building_id: inputs.root_id,
      },
      fetchPolicy: 'no-cache',
    }
  );

  const [addEnergyMeter, { loading, error }] = useMutation(
    ADD_ENERGY_METER_INSTANCE,
    {
      variables: {
        ...trimmedFields(inputs),
        created_by,
        colData,
      },
      errorPolicy: 'all',
      update(cache, { data: { insert_energy_meter_instance_one } }) {
        // Params of the first page
        const params = {
          query: GET_ENERGY_METERS,
          variables: {
            limit: 8,
            hardware_id: '%%',
            cursor: 'now',
          },
        };

        const cachedItems: ENERGY_METER_CACHE | null = cache.readQuery(params);

        cache.writeQuery({
          ...params,
          data: {
            energy_meter_instance: [
              { ...insert_energy_meter_instance_one },
              ...(cachedItems?.energy_meter_instance || []),
            ],
          },
        });
      },
      refetchQueries: ['getEnergyMeterCount'],
      onCompleted: ({ insert_energy_meter_instance_one }) => {
        setSuccessMessage(
          `${insert_energy_meter_instance_one?.hardware_id} added successfully!`
        );
      },
    }
  );

  useEffect(() => {
    if (inputs.hardware_id) {
      const ishardwareIDexists = energyMeterData?.energy_meter_instance.find(
        (item) => {
          return item.hardware_id === inputs.hardware_id;
        }
      );
      if (ishardwareIDexists) {
        setErrorDuplicate(true);
        setHelperText(
          `Hardware id already exists. Please choose a different hardware id`
        );
      } else {
        setErrorDuplicate(false);
        setHelperText('');
      }
    }
  }, [inputs.hardware_id]);

  useEffect(() => {
    //overrideValue('root_id', null);
    getRoots();
  }, [inputs.org_id]);

  useEffect(() => {
    getSpaces();
  }, [inputs.org_id, inputs.root_id]);

  async function handleSubmit() {
    await addEnergyMeter();
    resetForm();
  }

  function handleColumn(org_id, hardware_id, root_id) {
    const energyMeter = specData?.energy_meter_energy_meter.find(
      (m) => m.id === inputs.energy_meter_id
    );

    setColData(
      [...Array(energyMeter?.channels)].map((u, i) => {
        return {
          created_by,
          hardware_id: `${hardware_id}/${i}`,
          name: `${i}`,
          org_id: org_id,
          annotations: {
            data: [
              {
                key: 'root_id',
                org_id: org_id,
                type: 'system',
                value: root_id,
                created_by,
              },
            ],
          },
        };
      })
    );
  }

  const orgData = data?.organisation_clients;

  const orgSortedOptions = data && sortDataByAlpha([...orgData]);

  const buildingData = rootData?.spaces;

  const buildingSortedOptions = rootData && sortDataByAlpha([...buildingData]);
  const spaceData = spacesData?.spaces;

  const spaceSortedOptions = spacesData && sortDataByAlpha([...spaceData]);

  return (
    <StyledBox>
      <SnackMessage message={error?.message} type="error" />
      <SnackMessage message={successMessage} type="success" />
      <Form
        onSubmit={handleSubmit}
        busy={loading}
        inComplete={
          errorDuplicate ||
          !inputs.hardware_id ||
          !inputs.space_id ||
          !inputs.root_id ||
          !inputs.org_id
        }
      >
        <FormLabel>ADD ENERGY METER</FormLabel>
        <TextField
          error={errorDuplicate}
          helperText={helperText}
          name="hardware_id"
          label="hardware_id"
          variant="filled"
          fullWidth
          disabled={loading}
          value={inputs.hardware_id}
          onChange={(e) => {
            handleChange(e);
            //  setHardwareID(e.target.value);
          }}
        />
        <FormControl fullWidth variant="filled">
          <InputLabel id="energy-meter-select-label">Spec</InputLabel>
          <Select
            labelId="energy-meter-select-label"
            name="energy_meter_id"
            value={inputs.energy_meter_id}
            onChange={handleChange}
          >
            {specData?.energy_meter_energy_meter.map(
              ({ id, name, model }: SensorNodeSpec) => (
                <MenuItem key={id} value={id}>
                  {name} {model}
                </MenuItem>
              )
            )}
          </Select>
        </FormControl>

        <Autocomplete
          id="org-select"
          fullWidth
          options={orgSortedOptions?.map((org: Entity) => org.id) || []}
          getOptionLabel={(option: string) =>
            data?.organisation_clients.find((org: Entity) => org.id === option)
              ?.name || ''
          }
          value={inputs.org_id}
          onChange={(_, value) => {
            handleChange({ name: 'org_id', value });
          }}
          renderInput={(params) => (
            <TextField {...params} label="Organisation" variant="filled" />
          )}
        />
        <Autocomplete
          id="root-select"
          fullWidth
          options={
            buildingSortedOptions?.map((space: Entity) => space.id) || []
          }
          getOptionLabel={(option: string) =>
            rootData?.spaces.find((space: Entity) => space.id === option)
              ?.name || ''
          }
          value={inputs.root_id}
          onChange={(_, value) => handleChange({ name: 'root_id', value })}
          renderInput={(params) => (
            <TextField {...params} label="Building" variant="filled" />
          )}
        />
        <Autocomplete
          id="space-select"
          fullWidth
          options={spaceSortedOptions?.map((space: Entity) => space.id) || []}
          getOptionLabel={(option: string) =>
            spacesData?.spaces.find((space: Entity) => space.id === option)
              ?.name || ''
          }
          value={inputs.space_id}
          onChange={(_, value) => {
            handleChange({ name: 'space_id', value });
            handleColumn(inputs.org_id, inputs.hardware_id, inputs.root_id);
          }}
          renderInput={(params) => (
            <TextField {...params} label="Space" variant="filled" />
          )}
        />
      </Form>
    </StyledBox>
  );
}
