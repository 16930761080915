import { gql, useQuery } from "@apollo/client";
import Title from "components/Title";
import { RouteComponentProps } from "react-router-dom";

type Params = {
  thing_id: string;
};

const GET_THING_NAME = gql`
  query getThing($thing_id: uuid!) {
    get_thing_by_id(id: $thing_id) {
      id
      name
    }
  }
`;

const ThingCrumb:React.FC<RouteComponentProps<Params>> = ({ match, location }) => {

  const { thing_id } = match.params;
  const { pathname } = location;

  const { data } = useQuery(GET_THING_NAME, {
    variables: { thing_id },
    fetchPolicy: 'cache-first'
  });

  return (
    // is the matched path the current location (is thing the current path?)
    pathname === match.url 
    ?
    <Title heading={data?.get_thing_by_id?.name }/>
    :
    <span>{ data?.get_thing_by_id?.name }</span>
  )
};

export default ThingCrumb;