import { gql, useQuery } from "@apollo/client";
import { RouteComponentProps } from "react-router-dom";

import Title from "components/Title";

type Params = {
  org_id: string;
};

const GET_ORGANISATION_NAME = gql`
  query getOrganisation($org_id: uuid!) {
    organisation_by_id(id: $org_id) {
      id
      name
    }
  }
`;

const OrganisationCrumb:React.FC<RouteComponentProps<Params>> = ({ match, location }) => {

  const { org_id } = match.params;
  const { pathname } = location;

  const { data } = useQuery(GET_ORGANISATION_NAME, {
    variables: { org_id },
    fetchPolicy: 'cache-first'
  });

  return (
    // is the matched path the current location (is org the current path?)
    pathname === match.url 
      ?
      <Title heading={data?.organisation_by_id?.name}/>
      :
      <span>{data?.organisation_by_id?.name}</span>
  )
};

export default OrganisationCrumb;