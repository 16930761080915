import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Link as RouterLink } from 'react-router-dom';
import Resource from '../types';
import { Tooltip } from '@material-ui/core';

export default function ListItemLink(props: Resource) {
  const { icon, primary, to } = props;

  const renderLink = React.useMemo(
    () =>
      React.forwardRef((itemProps, ref) => (
        <RouterLink to={to} {...itemProps} />
      )),
    [to]
  );

  return (
    <li>
      <ListItem button component={renderLink}>
        {icon ? (
          <>
            <Tooltip title={primary} arrow>
              <ListItemIcon>{icon}</ListItemIcon>
            </Tooltip>
          </>
        ) : null}
        <ListItemText color="white" primary={primary} />
      </ListItem>
    </li>
  );
}
