import { useEffect, useState } from 'react';
import { Box, FormLabel, TextField, styled } from '@material-ui/core';
import {
  gql,
  PureQueryOptions,
  RefetchQueriesFunction,
  useMutation,
} from '@apollo/client';
import Form from './Form';
import SnackMessage from './SnackMessage';
import useForm from '../hooks/useForm';
import { useAuth } from 'hooks/useAuth';
import { trimmedFields } from 'utils/helpers';

const ADD_THING_GROUP = gql`
  mutation add_thing_group_one(
    $name: String!
    $description: String
    $created_by: uuid
  ) {
    insert_thing_group_one(
      object: {
        name: $name
        description: $description
        created_by: $created_by
      }
    ) {
      name
      id
      description
      created_date
      created_by
    }
  }
`;

type GroupProps = {
  query: RefetchQueriesFunction | (string | PureQueryOptions)[] | undefined;

  resetForm: boolean;
};

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-around',
  margin: theme.spacing(2),
  transition: 'all .5s',
}));

export default function CreateThingCategory({ query, resetForm }: GroupProps) {
  const { inputs, handleChange, clearForm } = useForm({
    name: '',
    description: '',
    created_by: null || '',
  });
  const { user } = useAuth();

  const created_by = user?.sub;

  const [AddGroup, { loading, error }] = useMutation(ADD_THING_GROUP, {
    variables: {
      ...trimmedFields(inputs),
      created_by,
    },
    errorPolicy: 'all',
    refetchQueries: query,
    onCompleted: ({ insert_thing_group_one }) => {
      setSuccessMessage(`${insert_thing_group_one?.name} added successfully!`);
    },
  });

  const [successMessage, setSuccessMessage] = useState('');
  useEffect(() => {
    if (resetForm) {
      clearForm();
    }
  }, [resetForm]);

  async function handleSubmit() {
    await AddGroup();
    clearForm();
  }

  return (
    <StyledBox>
      <SnackMessage message={error?.message} type="error" />
      <SnackMessage message={successMessage} type="success" />
      <Form
        onSubmit={handleSubmit}
        busy={loading}
        inComplete={!inputs.name || !inputs.description}
      >
        <FormLabel>ADD GROUP</FormLabel>
        <TextField
          name="name"
          label="name"
          variant="filled"
          fullWidth
          disabled={loading}
          value={inputs.name}
          onChange={handleChange}
        />
        <TextField
          name="description"
          label="description"
          variant="filled"
          fullWidth
          disabled={loading}
          value={inputs.description}
          onChange={handleChange}
        />
      </Form>
    </StyledBox>
  );
}
