import React, { lazy, Suspense, ReactNode, useEffect } from 'react';
import {
  Switch,
  Route,
  Redirect,
  RouteProps,
  RouteComponentProps,
  useLocation,
} from 'react-router-dom';
import { StaticContext } from 'react-router';
import { BreadcrumbsRoute } from 'use-react-router-breadcrumbs';

import Navbar from './components/Navbar';
import Loader from './components/Loader';
import OrganisationCrumb from './components/breadcrumbs/OrganisationCrumb';
import SpaceCrumb from './components/breadcrumbs/SpaceCrumb';
import ThingCrumb from './components/breadcrumbs/ThingCrumb';
import SensorNodeCrumb from './components/breadcrumbs/SensorNodeCrumb';
import EnergyMeterCrumb from './components/breadcrumbs/EnergyMeterCrumb';

import { useAuth } from './hooks/useAuth';
//import UserProfile from 'pages/user/user_profile';
import SensorNodeMeasurementCategory from './pages/measurements/sensor_node_category';
import SensorNodeMeasurementType from './pages/measurements/sensor_node_type';
import SensorNodeMeasurementSpec from './pages/measurements/sensor_node_spec';
import PrismInstall from 'pages/prism-install';

import ContractorOrganisation from 'pages/contractor-org';

// Code splitting
const Home = lazy(() => import('pages/home'));
const Organisation = lazy(() => import('pages/orgs'));
const Engineers = lazy(() => import('pages/contractor-org/engineers'));
const RootSpacesOrg = lazy(() => import('pages/orgs/org'));
const Spaces = lazy(() => import('pages/spaces'));
const User = lazy(() => import('pages/user'));
const UserProfile = lazy(() => import('pages/user/user_profile'));
const EnergyMeter = lazy(() => import('pages/energy-meter'));
const EnergyMeterInstance = lazy(() => import('pages/energy-meter/instance'));
const Settings = lazy(() => import('pages/settings_page'));
const Things = lazy(() => import('pages/things/index'));
const ThingsThing = lazy(() => import('pages/things/thing'));
const Group = lazy(() => import('pages/things/group'));
const Category = lazy(() => import('pages/things/category'));
const ThingType = lazy(() => import('pages/things/thing_type'));
const SpacesSpace = lazy(() => import('pages/spaces/space'));
const SensorNode = lazy(() => import('pages/sensor-nodes/sensor_node'));
const SensorNodes = lazy(() => import('pages/sensor-nodes'));
const SensorNodeInstances = lazy(() => import('pages/sensor-nodes-instances'));
const SensorNodeInstanceMeasurements = lazy(
  () => import('pages/sensor-nodes-instances/sensor-node-instance-features')
);
const Tags = lazy(() => import('pages/tags'));
const TagTypes = lazy(() => import('pages/tags/tagType'));
const DashboardIndex = lazy(() => import('pages/dashboard/index'));

export const routes: (BreadcrumbsRoute & RouteProps)[] = [
  {
    path: '/organisation/:org_id',
    breadcrumb: OrganisationCrumb,
    children: <RootSpacesOrg />,
    exact: true,
  },
  {
    path: '/organisation/:org_id/spaces',
    // children: <Redirect to="/organisation/:org_id" />,
    // breadcrumb: SpaceCrumb,
    children: <RootSpacesOrg />,
    exact: true,
  },
  { path: '/organisation/:org_id/space', children: <Spaces />, exact: true },
  {
    path: '/contractor/:org_id',
    breadcrumb: OrganisationCrumb,
    children: <Engineers />,
    exact: true,
  },

  { path: '/spaces', children: <Spaces />, exact: true },
  {
    path: '/organisation/:org_id/user/:user_id',
    //breadcrumb: SpaceCrumb,
    children: <UserProfile />,
    exact: true,
  },

  {
    path: '/organisation/:org_id/spaces/:space_id',
    breadcrumb: SpaceCrumb,
    children: <SpacesSpace />,
    exact: true,
  },

  {
    path: '/organisation/:org_id/spaces/:space_id/things',
    // breadcrumb: SpaceCrumb,
    children: <SpacesSpace />,
    exact: true,
  },

  {
    path: '/organisation/:org_id/spaces/:space_id/things/:thing_id',
    breadcrumb: ThingCrumb,
    children: <ThingsThing />,
    exact: true,
  },

  { path: '/organisation', children: <Organisation />, exact: true },
  {
    path: '/contractor',

    children: <ContractorOrganisation />,
    exact: true,
  },
  { path: '/user', children: <User /> },
  {
    path: '/prism_install',
    breadcrumb: 'Prism Install',
    children: <PrismInstall />,
  },
  {
    path: '/sensor_node_category',
    //breadcrumb: SensorNodeCrumb,
    children: <SensorNodeMeasurementCategory />,
    exact: true,
  },
  {
    path: '/sensor_node_type',
    //breadcrumb: SensorNodeCrumb,
    children: <SensorNodeMeasurementType />,
    exact: true,
  },
  {
    path: '/sensor_node_spec',
    //breadcrumb: SensorNodeCrumb,
    children: <SensorNodeMeasurementSpec />,
    exact: true,
  },
  {
    path: '/sensor_nodes/:id',
    breadcrumb: SensorNodeCrumb,
    children: <SensorNode />,
    exact: true,
  },
  { path: '/sensor_nodes', children: <SensorNodes /> },
  {
    path: '/sensor_nodes_instances/:id',
    children: <SensorNodeInstanceMeasurements />,
    exact: true,
  },
  {
    path: '/sensor_nodes_instances',
    children: <SensorNodeInstances />,
    exact: true,
  },
  {
    path: '/group',
    children: <Group />,
    exact: true,
  },
  {
    path: '/category',
    children: <Category />,
    exact: true,
  },
  {
    path: '/thing_type',
    children: <ThingType />,
    exact: true,
  },
  {
    path: '/energy_meter/:id',
    breadcrumb: EnergyMeterCrumb,
    children: <EnergyMeterInstance />,
    exact: true,
  },
  {
    path: '/energy_meter',
    breadcrumb: 'Energy Meters',
    children: <EnergyMeter />,
  },
  { path: '/settings', children: <Settings /> },
  { path: '/tags/:id', children: <TagTypes />, exact: true },
  { path: '/tags', children: <Tags /> },
  { path: '/things', children: <Things />, exact: true },
  { path: '/', breadcrumb: 'Home', children: <Home /> },
];

function App() {
  let { loading: authLoading, logIn } = useAuth();
  const location = useLocation();

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(
      decodeURIComponent(location.search)
    );
    const params = Object.fromEntries(urlSearchParams.entries());
    if (params.token) {
      logIn(params.token);
    }
  }, []);

  if (authLoading) return <Loader />;

  return (
    <Navbar>
      <Suspense fallback={<Loader />}>
        <Switch>
          <Route
            exact
            path="/login"
            render={({
              location,
            }: RouteComponentProps<{}, StaticContext, any>): ReactNode => {
              const url = new URL(location.state.from);
              if (url.searchParams.has('token')) {
                // remove old token
                url.searchParams.delete('token');
              }
              const from = `${url.origin}${
                url.searchParams.toString()
                  ? '?' + url.searchParams.toString()
                  : ''
              }`;
              const redirect = `${
                process.env.REACT_APP_AUTH_URL
              }/#/login?redirect=${encodeURIComponent(from)}`;
              window.location.replace(redirect);
              return null;
            }}
          />
          {routes.map(({ breadcrumb, children, ...props }, i) => (
            <PrivateRoute {...props} key={i}>
              {children}
            </PrivateRoute>
          ))}
        </Switch>
      </Suspense>
    </Navbar>
  );
}

const PrivateRoute: React.FC<RouteProps> = ({
  children,
  component,
  ...rest
}) => {
  let { user } = useAuth();

  return (
    <Route
      {...rest}
      render={() =>
        user ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: window.location.href },
            }}
          />
        )
      }
    />
  );
};

export default App;
