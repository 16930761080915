import { Fragment, useEffect, useRef, useState } from 'react';
import { CellProps, Column, Row } from 'react-table';
import { ApolloCache, gql, useMutation } from '@apollo/client';
import { TextField } from '@material-ui/core';
import { Annotation, Sensor } from 'types';
import SnackMessage from 'components/SnackMessage';
import Form from 'components/Form';
import Table from 'components/Table';
import useForm from 'hooks/useForm';
import { useAuth } from 'hooks/useAuth';
import { StyledTextField, StyledFormLabel } from './StyledComponent';
import {
  CHANNEL_ANNOTATIONS,
  CHANNEL_ANNOTATION_ATTRIBUTES,
  GET_ENERGY_METER_INSTANCE,
} from 'pages/energy-meter/instance';
import { trimmedFields } from 'utils/helpers';

type Props = {
  channel: Sensor;
  onDone: () => void;
};

export const annotation_columns: Column<Annotation>[] = [
  { accessor: 'id', Header: 'ID' },
  { accessor: 'key', Header: 'Key' },
  { accessor: 'value', Header: 'Value', Cell: EditableCell },
];

const getAnnotationColumns = (onDone): Column<Annotation>[] => {
  return [
    { accessor: 'id', Header: 'ID' },
    { accessor: 'key', Header: 'Key' },
    {
      accessor: 'value',
      Header: 'Value',
      Cell: (props: any) => {
        return <EditableCell {...props} />;
      },
    },
  ];
};

// Graphql

const DELETE_CHANNEL_ANNOTATION = gql`
  mutation deleteChannelAnnotation(
    $ids: [uuid!]!
    $channel_instance_id: uuid!
  ) {
    delete_energy_meter_channel_instance_annotations(
      where: {
        id: { _in: $ids }
        channel_instance_id: { _eq: $channel_instance_id }
      }
    ) {
      returning {
        id
        key
      }
    }
  }
`;

const ADD_CHANNEL_ANNOTATION = gql`
  mutation addChannelAnnotation(
    $key: String!
    $value: String!
    $channel_instance_id: uuid!
    $org_id: uuid!
    $created_by: uuid!
  ) {
    insert_energy_meter_channel_instance_annotations_one(
      object: {
        key: $key
        value: $value
        type: "system"
        channel_instance_id: $channel_instance_id
        org_id: $org_id
        created_by: $created_by
      }
    ) {
      id
      key
    }
  }
`;

const UPDATE_CHANNEL_ANNOTATION = gql`
  mutation updateChannelAnnotation($id: uuid!, $value: String!) {
    update_energy_meter_channel_instance_annotations_by_pk(
      pk_columns: { id: $id }
      _set: { value: $value }
    ) {
      id
      key
      value
    }
  }
`;

// component styles

// function for updating cache

const __typename = 'energy_meter_channel_instance';
const cachedChannel = (cache: ApolloCache<any>, id: string) =>
  cache.readFragment({
    id: `${__typename}:${id}`,
    fragment: CHANNEL_ANNOTATIONS,
    fragmentName: 'channelAnnotations',
  });

const writeAnnotations = (
  cache: ApolloCache<any>,
  id: string,
  annotations: Annotation[]
) =>
  cache.writeFragment({
    id: `${__typename}:${id}`,
    fragment: CHANNEL_ANNOTATIONS,
    fragmentName: 'channelAnnotations',
    data: {
      __typename,
      annotations,
    },
  });

// Editable Cell

function EditableCell({ value: initialValue, row }: CellProps<object>) {
  const input = useRef<HTMLInputElement>(null);
  const [value, setValue] = useState(initialValue);
  const [updateSuccessMessage, setUpdateSuccessMessage] = useState('');
  // If value is changed externally sync it here
  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const [updateChannelAnnotation, { loading, error: updateError }] =
    useMutation(UPDATE_CHANNEL_ANNOTATION, {
      variables: {
        id: row?.values?.id,
        value: value?.trim(),
      },
      update(
        cache,
        {
          data: {
            update_energy_meter_channel_instance_annotations_by_pk: {
              id,
              value,
            },
          },
        }
      ) {
        cache.writeFragment({
          id: `energy_meter_channel_instance_annotations:${id}`,
          fragment: CHANNEL_ANNOTATION_ATTRIBUTES,
          data: {
            value: value,
          },
        });
      },

      onCompleted: ({
        update_energy_meter_channel_instance_annotations_by_pk: { key },
      }) => {
        //onDone();
        setValue(value.trim());
        setUpdateSuccessMessage(`${key} updated successfully!`);
      },

      refetchQueries: [
        {
          query: GET_ENERGY_METER_INSTANCE,
          variables: {
            id: row?.values?.id,
          },
        },
      ],
    });

  function handleKeyPress(e: React.KeyboardEvent) {
    if (e.code === 'Enter') {
      updateChannelAnnotation();
      input?.current?.blur();
    }
  }

  return (
    <Fragment>
      <SnackMessage message={updateError?.message} type="error" />
      <SnackMessage
        message={updateSuccessMessage}
        onClose={() => setUpdateSuccessMessage('')}
        type="success"
      />
      <TextField
        inputProps={{ ref: input }}
        style={{ display: 'flex' }}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onKeyPress={handleKeyPress}
        disabled={loading}
      />
    </Fragment>
  );
}

// Add channel form

function ChannelAnnotationsForm({ channel: { org_id, id }, onDone }: Props) {
  const { inputs, handleChange } = useForm({
    key: '',
    value: '',
  });
  const { user } = useAuth();

  const created_by = user?.sub;

  const [addChannel, { error: addError, loading }] = useMutation(
    ADD_CHANNEL_ANNOTATION,
    {
      variables: {
        ...trimmedFields(inputs),
        org_id,
        channel_instance_id: id,
        created_by,
      },
      update(
        cache,
        { data: { insert_energy_meter_channel_instance_annotations_one } }
      ) {
        const currentChannel: any = cachedChannel(cache, id);

        writeAnnotations(cache, id, [
          ...trimmedFields(currentChannel?.annotations),
          insert_energy_meter_channel_instance_annotations_one,
        ]);
      },
      onCompleted: ({
        insert_energy_meter_channel_instance_annotations_one: { key },
      }) => {
        onDone();
        setAddSuccessMessage(`${key} added successfully!`);
      },
    }
  );

  function handleSubmit() {
    addChannel({ variables: {} });
  }

  const [addSuccessMessage, setAddSuccessMessage] = useState('');

  return (
    <Form onSubmit={handleSubmit} inline>
      <SnackMessage message={addError?.message} type="error" />
      <SnackMessage message={addSuccessMessage} type="success" />
      <StyledFormLabel>Add Annotation</StyledFormLabel>
      <StyledTextField
        name="key"
        label="Key"
        variant="filled"
        disabled={loading}
        value={inputs.key}
        onChange={handleChange}
      />
      <StyledTextField
        name="value"
        label="Value"
        variant="filled"
        disabled={loading}
        value={inputs.value}
        onChange={handleChange}
      />
    </Form>
  );
}

// Main channel annotation component

export default function ChannelAnnotations({ channel, onDone }: Props) {
  const { id, annotations } = channel;

  const [deleteChannelAnnotation, { error: deleteError }] = useMutation(
    DELETE_CHANNEL_ANNOTATION,
    {
      update(
        cache,
        {
          data: {
            delete_energy_meter_channel_instance_annotations: { returning },
          },
        }
      ) {
        const currentChannel: any = cachedChannel(cache, id);
        const deletedIds = returning.map((a: Annotation) => a.id);

        // Filter out deleted record and insert filtered array back into cache
        const fileredAnnotations = currentChannel?.annotations.filter(
          (a: Annotation) => !deletedIds.includes(a.id)
        );
        writeAnnotations(cache, id, fileredAnnotations);
      },
      onCompleted: ({
        delete_energy_meter_channel_instance_annotations: { returning },
      }) => {
        onDone();
        setDeleteSuccessMessage(
          `${
            returning.length > 1
              ? `${returning.length} annotations`
              : returning[0].hardware_id
          } deleted successfully!`
        );
      },
    }
  );

  function handleDelete(rows: Row<Annotation>[]) {
    const ids = rows.map((r: Row<Annotation>) => r.values.id);
    deleteChannelAnnotation({
      variables: {
        ids,
        channel_instance_id: id,
      },
    });
  }

  const [deleteSuccessMessage, setDeleteSuccessMessage] = useState('');

  return (
    <Fragment>
      <SnackMessage message={deleteError?.message} type="error" />
      <SnackMessage message={deleteSuccessMessage} type="success" />
      <ChannelAnnotationsForm channel={channel} onDone={onDone} />
      <Table
        title="Annotations"
        data={annotations}
        columns={annotation_columns}
        //onDeleteRows={handleDelete}
      />
    </Fragment>
  );
}
