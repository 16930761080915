import React, { Fragment, useEffect, useRef, useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { createStyles, lighten, styled, Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import SnackMessage from 'components/SnackMessage';
import { Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { IIndexable } from 'types';
import { StyledTextField } from './StyledComponent';

import { THING_GROUP } from 'pages/things/group';

type Props = {
  id: string;
  description: string;
  name?: string;
  limit?: Number;
  offset?: Number;
  orderBy?: Object;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      width: '60%',
      alignContent: 'center',
      justifyContent: 'center',
      padding: theme.spacing(1),
      '& .MuiInputBase-root': {
        marginBottom: theme.spacing(1),
      },
      '& .MuiFormLabel-root': {
        marginBottom: theme.spacing(1),
      },
    },
    footer: {
      display: 'flex',
      width: '100%',
      backgroundColor: lighten(theme.palette.secondary.main, 0.85),
      padding: theme.spacing(1),
      justifyContent: 'flex-end',
    },
  })
);

const PaperWrapper = styled(Paper)(({ theme }) => ({
  borderTop: '5px solid',
  borderColor: theme.palette.secondary.main,
}));

export const UPDATE_THING_GROUP = gql`
  mutation updateThingGroup(
    $update_name: String
    $id: uuid!
    $update_description: String
  ) {
    update_thing_group_by_pk(
      _set: { name: $update_name, description: $update_description }
      pk_columns: { id: $id }
    ) {
      id
      name
      description
    }
  }
`;

export default function Update({
  id,
  name,
  description,
  limit,
  offset,
}: Props) {
  const classes: IIndexable = useStyles();

  const input = useRef<HTMLInputElement>(null);

  const [updateName, setUpdateName] = useState<string | undefined>(name);
  const [updateSuccessMessage, setUpdateSuccessMessage] = useState('');
  const [updateDescription, setUpdateDescription] = useState(description);

  // If value is changed externally sync it here
  useEffect(() => {
    setUpdateName(name);
  }, [name]);

  useEffect(() => {
    setUpdateDescription(description);
  }, [description]);

  const [updateGroup, { loading, error: updateError }] = useMutation(
    UPDATE_THING_GROUP,
    {
      variables: {
        id,
        update_name: updateName?.trim(),
        update_description: updateDescription.trim(),
      },
      update(
        cache,
        {
          data: {
            update_thing_group_by_pk: { id, name, description },
          },
        }
      ) {
        cache.writeQuery({
          query: THING_GROUP,
          variables: {
            offset,
            limit,
            name: `%${name}%`,
          },
          data: {
            name,
            description,
          },
        });
      },
      onCompleted: ({ update_thing_group_by_pk }) => {
        setUpdateName((prevVal) => prevVal?.trim());
        setUpdateDescription(description.trim());
        setUpdateSuccessMessage(
          `${update_thing_group_by_pk.name} updated successfully!`
        );
      },
    }
  );

  function handleKeyPress(e: React.KeyboardEvent) {
    if (e.code === 'Enter') {
      updateGroup();
      input?.current?.blur();
    }
  }

  function handleSaveButton() {
    updateGroup();
    input?.current?.blur();
  }
  return (
    <Fragment>
      <PaperWrapper elevation={3} square>
        <SnackMessage message={updateError?.message} type="error" />
        <SnackMessage
          message={updateSuccessMessage}
          onClose={() => setUpdateSuccessMessage('')}
          type="success"
        />

        <StyledTextField
          inputProps={{ ref: input }}
          style={{
            display: 'flex',
            width: '80%',
            marginBottom: 20,
            marginTop: 20,
            justifyContent: 'center',
            marginLeft: '10%',
          }}
          name={updateName}
          value={updateName}
          onChange={(e) => setUpdateName(e.target.value)}
          onKeyPress={handleKeyPress}
          disabled={loading}
          variant="filled"
          label="Name"
        />
        <StyledTextField
          inputProps={{ ref: input }}
          style={{
            display: 'flex',
            width: '80%',
            marginBottom: 20,
            marginTop: 20,
            justifyContent: 'center',
            marginLeft: '10%',
          }}
          name={updateDescription}
          value={updateDescription}
          onChange={(e) => setUpdateDescription(e.target.value)}
          onKeyPress={handleKeyPress}
          disabled={loading}
          variant="filled"
          label="Description"
        />
        <div className={classes.footer}>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleSaveButton}
          >
            Save
          </Button>
        </div>
      </PaperWrapper>
    </Fragment>
  );
}
