import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, FormLabel, TextField, styled } from '@material-ui/core';
import {
  gql,
  PureQueryOptions,
  RefetchQueriesFunction,
  useLazyQuery,
  useMutation,
  useQuery,
} from '@apollo/client';
import Form from './Form';
import SnackMessage from './SnackMessage';
import VariableSwitch from './Switch';
import useForm from 'hooks/useForm';
import { useAuth } from 'hooks/useAuth';
import { THING_CATEGORY } from 'pages/things/category';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  GET_CLIENTS,
  GET_ALL_SPACES_FOR_BUILDING,
} from 'graphql/common-queries';
import { GET_ROOTS } from './CreateEnergyMeter';
import { Entity } from 'types';
import { sortDataByAlpha, trimmedFields } from 'utils/helpers';
import MIMMI from './MiMMi';
// import { getTime } from 'date-fns';
import { THING_GROUP } from 'pages/things/group';

export const THING_GROUP_CATEGORY = gql`
  query get_thing_category(
    $limit: Int
    $offset: Int
    $name: String
    $orderBy: [thing_category_order_by!]
    $group_id: uuid
  ) {
    thing_category(
      where: { name: { _ilike: $name }, group_id: { _eq: $group_id } }
      limit: $limit
      offset: $offset
      order_by: $orderBy
    ) {
      created_date
      description
      id
      name
      created_by
      group {
        name
        id
      }
    }
    rowcount: thing_category_aggregate(order_by: $orderBy) {
      aggregate {
        count
      }
    }
  }
`;

const ADD_THING = gql`
  mutation AddThing(
    $name: String!
    $org_id: uuid!
    $type_id: uuid
    $parent_id: uuid
    $created_by: uuid!
    $variable: Boolean! = true
    $data: [thing_annotations_insert_input!]!
  ) {
    insert_one_thing(
      object: {
        created_by: $created_by
        name: $name
        parent_id: $parent_id
        org_id: $org_id
        type_id: $type_id
        annotations: { data: $data }
        variable: $variable
      }
    ) {
      id
      name
      parent_id
      created_date
      type {
        name
        category {
          name
        }
      }
    }
  }
`;

export const THING_TYPE_BY_CATEGORY = gql`
  query get_thing_type($category_id: uuid!) {
    thing_type(where: { category_id: { _eq: $category_id } }) {
      id
      name
      category {
        name
      }
    }
  }
`;

type ThingProps = {
  query: RefetchQueriesFunction | (string | PureQueryOptions)[] | undefined;
  annotations?: {
    key: String;
    value: String;
    thing_id: Number;
  }[];
  resetForm: boolean;
};

export const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-around',
  margin: theme.spacing(2),
  transition: 'all .5s',
  flexDirection: 'column',
  flex: 1,
}));

export default function CreateIndexThing({
  query,
  annotations,
  resetForm,
}: ThingProps) {
  const params: any = useParams();

  const { org_id, thing_id, space_id } = params;

  const rootAnnotation = annotations?.find((el) => el.key === 'root_id');

  const rootValue = rootAnnotation?.value;

  const { inputs, handleChange, clearForm, setInputs } = useForm({
    name: '',

    org_id: null,
    parent_id: null,
    root_id: null,
    space_id: null,
  });
  const { user } = useAuth();
  const created_by = user?.sub;

  const [isVariable, setIsVariable] = useState(true);
  const [isMI, setIsMI] = useState(false);
  const [isMMI, setIsMMI] = useState(false);

  const [group, setGroup] = useState({ id: null, name: '' });

  const [category, setCategory] = useState({ id: null, name: '' });
  const [type, setType] = useState({ id: null, name: '' });

  const { data: categoryData, loading: categoryLoading } = useQuery(
    THING_GROUP_CATEGORY,
    {
      variables: {
        group_id: group.id ? group.id : null,
      },
    }
  );

  const { data: typeData, loading: typeLoading } = useQuery(
    THING_TYPE_BY_CATEGORY,
    {
      variables: {
        category_id: category.id,
      },
      fetchPolicy: 'network-only',
    }
  );
  const { data: orgData } = useQuery(GET_CLIENTS);
  const [getRoots, { data: rootData }] = useLazyQuery(GET_ROOTS, {
    variables: {
      org_id: inputs.org_id,
    },
    fetchPolicy: 'no-cache',
  });
  const [getSpaces, { data: spacesData }] = useLazyQuery(
    GET_ALL_SPACES_FOR_BUILDING,
    {
      variables: {
        root_id: inputs.root_id,
        building_id: inputs.root_id,
      },
      fetchPolicy: 'no-cache',
    }
  );
  const { data: groupData, loading: groupLoading } = useQuery(THING_GROUP);

  const [AddThing, { loading, error }] = useMutation(ADD_THING, {
    errorPolicy: 'all',
    refetchQueries: query,

    onCompleted: ({ insert_one_thing }) => {
      if (insert_one_thing) {
        // const currentDateChecked = new Date().toLocaleTimeString();
        // setCreatedDate(insert_one_thing.created_date);
        setSuccessMessage(`${insert_one_thing?.name} added successfully!`);
      }
    },
  });

  useEffect(() => {
    if (resetForm) {
      setCategory({ id: null, name: '' });
      setType({ id: null, name: '' });
      setIsVariable(true);
      setGroup({ id: null, name: '' });
      setInputs({
        name: '',
        category: '',
        type: '',
        variable: true,
        org_id: null,
        parent_id: null,
        root_id: null,
        space_id: null,
      });
      clearForm();
      setIsMI(false);
      setIsMMI(false);
    }
  }, [resetForm]);

  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    setGroup(group);
  }, [group]);

  useEffect(() => {
    setCategory(category);
  }, [category, group]);

  useEffect(() => {
    //overrideValue('root_id', null);
    getRoots();
  }, [inputs.org_id]);

  useEffect(() => {
    getSpaces();
  }, [inputs.org_id, inputs.root_id]);

  async function handleSubmit() {
    const data: any = [
      {
        type: 'system',
        key: 'root_id',
        value: inputs.root_id,
        org_id: inputs.org_id,
        created_by,
      },
    ];
    data.push({
      type: 'system',
      key: 'space_id',
      value: inputs.space_id || inputs.root_id,
      org_id: inputs.org_id,
      created_by,
    });
    if (isMI) {
      data.push({
        type: 'metric',
        key: 'MI',
        value: 'true',
        org_id: inputs.org_id,
        created_by,
      });
    }

    if (isMMI) {
      data.push({
        type: 'metric',
        key: 'MMI',
        value: 'true',
        org_id: inputs.org_id,
        created_by,
      });
    }

    const id = thing_id;

    await AddThing({
      variables: {
        ...trimmedFields(inputs),
        created_by,
        parent_id: id,
        org_id: inputs.org_id,
        variable: isVariable,
        type_id: type.id,
        data: data,
      },
    });
    setCategory({ id: null, name: '' });
    setType({ id: null, name: '' });
    setIsVariable(true);
    setInputs({
      name: '',

      variable: true,
      org_id: null,
      parent_id: null,
      root_id: null,
      space_id: null,
    });
    setGroup({ id: null, name: '' });
    setIsMI(false);
    setIsMMI(false);

    clearForm();
  }

  const handleVariableChange = () => {
    setIsVariable((current) => !current);
  };

  const handleMIChange = () => {
    setIsMI((current) => !current);
  };
  const handleMMIChange = () => {
    setIsMMI((current) => !current);
  };

  const categoryOptions = categoryData && categoryData?.thing_category;
  const typeOptions = typeData && typeData?.thing_type;
  const orgDataArr = orgData?.organisation_clients;

  const orgSortedOptions = orgData && sortDataByAlpha([...orgDataArr]);

  const buildingData = rootData?.spaces;

  const buildingSortedOptions = rootData && sortDataByAlpha([...buildingData]);
  const spaceData = spacesData?.spaces;

  const spaceSortedOptions = spacesData && sortDataByAlpha([...spaceData]);
  const groupOptions = groupData && groupData?.thing_group;

  const handleFocus = (event) => {
    event.target.setAttribute('autocomplete', 'new-password');
  };

  return (
    <StyledBox>
      <SnackMessage message={error?.message} type="error" />
      <SnackMessage message={successMessage} type="success" />
      <Form
        onSubmit={handleSubmit}
        busy={loading}
        inComplete={!inputs || !category.id || !type.id}
      >
        <FormLabel>ADD THING</FormLabel>

        <TextField
          id="field1"
          name="name"
          label="name"
          variant="filled"
          fullWidth
          disabled={loading}
          value={inputs.name}
          onChange={handleChange}
          autoComplete="off"
          onFocus={handleFocus}
        />

        {groupData && (
          <Autocomplete
            onChange={(event: any, newValue: any) => {
              setGroup({ id: newValue?.id, name: newValue?.name });
              setCategory({ id: null, name: '' });
              setType({ id: null, name: '' });
            }}
            fullWidth
            value={group}
            id="controllable-states-index-thing"
            getOptionLabel={(option: any) => option.name}
            options={sortDataByAlpha([...groupOptions])}
            renderInput={(params: any) => (
              <TextField
                {...params}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password',
                }}
                label="group"
                variant="filled"
                // onFocus={handleFocus}
              />
            )}
          />
        )}
        {groupData && categoryData && (
          <Autocomplete
            onChange={(event: any, newValue: string | null | any) => {
              newValue && setCategory({ id: newValue.id, name: newValue.name });
              setType({ id: null, name: '' });
            }}
            fullWidth
            value={category}
            id="controllable-states-index-thing1"
            getOptionLabel={(option: any) => {
              return option.name;
            }}
            disableClearable={true}
            options={sortDataByAlpha([...categoryOptions])}
            renderInput={(params: any) => {
              return (
                <TextField
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password',
                  }}
                  variant="filled"
                  label="category"
                  // onFocus={handleFocus}
                />
              );
            }}
          />
        )}

        {categoryData && typeData && (
          <Autocomplete
            onChange={(event: any, newValue: string | null | any) => {
              newValue && setType({ id: newValue.id, name: newValue.name });
            }}
            fullWidth
            disableClearable={true}
            id="controllable-states-index-thing-2"
            value={type}
            getOptionLabel={(option: any) => option.name}
            options={sortDataByAlpha([...typeOptions])}
            renderInput={(params: any) => (
              <TextField
                {...params}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password',
                }}
                label="type"
                variant="filled"
              />
            )}
          />
        )}
        <Autocomplete
          id="org-select"
          fullWidth
          options={orgSortedOptions?.map((org: Entity) => org.id) || []}
          getOptionLabel={(option: string) =>
            orgData?.organisation_clients.find(
              (org: Entity) => org.id === option
            )?.name || ''
          }
          value={inputs.org_id}
          onChange={(_, value) => {
            handleChange({ name: 'org_id', value });
          }}
          disableClearable={true}
          renderInput={(params) => (
            <TextField {...params} label="Organisation" variant="filled" />
          )}
        />
        <Autocomplete
          id="root-select"
          fullWidth
          options={
            buildingSortedOptions?.map((space: Entity) => space.id) || []
          }
          disableClearable={true}
          getOptionLabel={(option: string) =>
            rootData?.spaces.find((space: Entity) => space.id === option)
              ?.name || ''
          }
          value={inputs.root_id}
          onChange={(_, value) => handleChange({ name: 'root_id', value })}
          renderInput={(params) => (
            <TextField {...params} label="Building" variant="filled" />
          )}
        />
        <Autocomplete
          id="space-select"
          disableClearable={true}
          fullWidth
          options={spaceSortedOptions?.map((space: Entity) => space.id) || []}
          getOptionLabel={(option: string) =>
            spacesData?.spaces.find((space: Entity) => space.id === option)
              ?.name || ''
          }
          value={inputs.space_id}
          onChange={(_, value) => {
            handleChange({ name: 'space_id', value });
            //    handleColumn(inputs.org_id, inputs.hardware_id, inputs.root_id);
          }}
          renderInput={(params) => (
            <TextField {...params} label="Space" variant="filled" />
          )}
        />
        <div
          style={{
            flex: 1,
            flexDirection: 'column',
            display: 'flex',

            padding: 10,
          }}
        >
          <VariableSwitch
            checked={isVariable}
            handleChange={handleVariableChange}
          />
          <MIMMI
            checkedMMI={isMMI}
            checkedMI={isMI}
            handleChangeMI={handleMIChange}
            handleChangeMMI={handleMMIChange}
          />
        </div>
      </Form>
    </StyledBox>
  );
}
