import React, { Fragment, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Row } from 'react-table';
import { gql, useMutation, useQuery } from '@apollo/client';
import {
  Box,
  Collapse,
  IconButton,
  Paper,
  Typography,
} from '@material-ui/core';
import { ORG_COLUMNS } from 'constants/data';
import IconLabelButton from 'components/IconLabelButton';
import Title from 'components/Title';
import Table from 'components/Table';
import SnackMessage from 'components/SnackMessage';
import Header from 'components/Header';
import SearchBar from 'components/SearchBar';
import CreateOrg from 'components/CreateOrg';
import { ORGANISATION } from 'types';
import { IIndexable } from 'types';
import { useThemeStyles } from 'theme';
import Update from '../../components/UpdateOrg';
import { ChevronRight } from '@material-ui/icons';

import { GET_CLIENTS } from 'graphql/common-queries';

export const GET_CLIENTS_ORG = gql`
  query getClientOrganisations(
    $offset: Int
    $limit: Int
    $name: String
    $orderBy: [organisation_clients_order_by!]
  ) {
    organisation_clients(
      offset: $offset
      limit: $limit
      order_by: $orderBy
      where: { name: { _ilike: $name } }
    ) {
      id
      name
    }
  }
`;

const DELETE_ORGANISATION = gql`
  mutation deleteOrganisation($ids: [uuid!]!) {
    delete_organisations(where: { id: { _in: $ids } }) {
      returning {
        id
        name
      }
    }
  }
`;

export const GET_AGGREGATE_ORG = gql`
  query getAggData($name: String) {
    rowcount: organisation_clients_aggregate(
      where: { name: { _ilike: $name } }
    ) {
      aggregate {
        count
      }
    }
  }
`;

const ROWS_PER_PAGE = 20;

export default function Organisation() {
  const themeClasses: IIndexable = useThemeStyles();

  const history = useHistory();
  const [offset, setOffset] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [openForm, setOpenForm] = useState<boolean>(false);
  const [name, setName] = useState<string | null>('');
  const [orderBy, setOrderBy] = useState({});
  const [deleteSuccessMessage, setDeleteSuccessMessage] = useState('');
  const [update, setUpdate] = React.useState(false);
  const [updateID, setUpdateID] = React.useState('');
  const [updateName, setUpdateName] = React.useState('');

  const { data: aggData } = useQuery(GET_AGGREGATE_ORG, {
    variables: {
      name: `%${name}%`,
    },
    fetchPolicy: 'network-only',
  });

  const { data, loading } = useQuery(GET_CLIENTS_ORG, {
    variables: {
      offset,
      limit: ROWS_PER_PAGE,
      name: `%${name}%`,
      orderBy,
    },
    fetchPolicy: 'network-only',
  });

  const [deleteOrganisation, { error: deleteError }] = useMutation(
    DELETE_ORGANISATION,
    {
      onCompleted: ({ delete_organisations: { returning } }) => {
        setDeleteSuccessMessage(
          `${
            returning.length > 1
              ? `${returning.length} organisations`
              : returning[0].name
          } deleted successfully`
        );
      },
      refetchQueries: [
        {
          query: GET_CLIENTS_ORG,
          variables: {
            offset,
            limit: ROWS_PER_PAGE,
            name: `%${name}%`,
            orderBy,
          },
        },
        {
          query: GET_CLIENTS,
        },
        {
          query: GET_AGGREGATE_ORG,
          variables: { name: `%${name}%` },
        },
      ],
    }
  );

  const onPageChange = (page: number) => {
    setPage(page);
    const currentOffset = page === 0 ? 0 : page * 20;
    setOffset(currentOffset);
  };

  const handleSearchClick = (val: string) => {
    setName(val);
    setPage(0);
    setOffset(0);
  };

  // function handleSort(sortArray: []) {
  //   const orderObj = sortArray.reduce((acc, sort) => {
  //     let { id, desc } = sort;
  //     return { ...acc, [id]: desc ? 'desc' : 'asc' };
  //   }, {});
  //   setOrderBy(orderObj);
  // }
  function handleSort(sortArray: []) {
    const orderObj = sortArray.reduce(
      (acc, sort: { id: string; desc: string | number }) => {
        let { id, desc } = sort;

        let idArr = id.split('.');
        let sortDir = desc ? 'desc' : 'asc';
        let value = idArr.length > 1 ? { [idArr[1]]: sortDir } : sortDir;
        return { ...acc, [idArr[0]]: value };
      },
      {}
    );
    setOrderBy(orderObj);
  }

  function handleRowClick({ values }: Row) {
    const { id } = values;
    history.push(`/organisation/${id}`);
  }

  function handleDelete(rows: any) {
    const ids = rows.map((r: Row<ORGANISATION>) => r.values.id);
    deleteOrganisation({ variables: { ids } });
  }

  function handleUpdate({ values }: Row) {
    const id = values.id;
    const editName = values.name;
    setUpdate(!update);
    setUpdateID(id);
    setUpdateName(editName);
  }

  return (
    <Fragment>
      <SnackMessage message={deleteError?.message} type="error" />
      <SnackMessage message={deleteSuccessMessage} type="success" />
      <Header>
        <Title heading="Browse Organisation" />
        <SearchBar handleSearch={handleSearchClick} />
        <IconLabelButton
          item={'Add Organisation'}
          onClick={() => setOpenForm(!openForm)}
          open={openForm}
        />
      </Header>
      <Collapse in={openForm}>
        <CreateOrg
          checked={openForm}
          type_id="07f958c8-409a-4dcb-9441-02ce6d372800"
          searchFilters={{
            offset,
            limit: ROWS_PER_PAGE,
            name: `%${name}%`,
            orderBy,
          }}
        />
      </Collapse>
      <Box className={themeClasses.detailWrapper}>
        <Box
          className={[
            themeClasses.maxWidthTransition,
            themeClasses[update ? 'tableSmall' : 'tableLarge'],
          ].join(' ')}
        >
          <Table
            data={data?.organisation_clients || []}
            columns={ORG_COLUMNS}
            pageSize={20}
            count={aggData?.rowcount?.aggregate?.count}
            manualPagination
            onChangePage={onPageChange}
            loading={loading}
            onSort={handleSort}
            onRowClick={handleRowClick}
            onUpdateRow={handleUpdate}
            onDeleteRows={handleDelete}
          />
        </Box>
        <Box
          className={[
            themeClasses.maxWidthTransition,
            themeClasses[update ? 'contentOpen' : 'contentClosed'],
          ].join(' ')}
        >
          {update && (
            <>
              {' '}
              <Paper
                className={[
                  themeClasses.maxWidthTransition,
                  themeClasses.detailsTop,
                  themeClasses[update ? 'padding' : ''],
                ].join(' ')}
              >
                <IconButton
                  aria-label="close"
                  onClick={() => setUpdate(!update)}
                >
                  <ChevronRight fontSize={'large'} />
                </IconButton>
                <Typography variant="h5">Update</Typography>
              </Paper>
              <Update
                name={updateName}
                id={updateID}
                type_id="07f958c8-409a-4dcb-9441-02ce6d372800"
              />
            </>
          )}
        </Box>
      </Box>
    </Fragment>
  );
}
