import React from 'react';
import clsx from 'clsx';
import {
  Box,
  Drawer,
  AppBar,
  Toolbar,
  List,
  Typography,
  Divider,
  IconButton,
  CssBaseline,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  Tooltip,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import {
  Menu,
  Domain,
  Group,
  Settings,
  SettingsInputAntenna,
  EvStation,
  MeetingRoom,
  Kitchen,
  ExpandLess,
  ExpandMore,
  Memory,
  LocalOffer,
  ExitToApp,
  LockOpen,
  Lock,
  AssignmentInd,
  Build,
} from '@material-ui/icons';
import SettingsRemoteIcon from '@material-ui/icons/SettingsRemote';

import CategoryIcon from '@material-ui/icons/Category';

import ListItemLink from './ListItemLink';
import { IIndexable } from '../types';
import { Link } from 'react-router-dom';
import { useAuth } from 'hooks/useAuth';

const drawerWidth = 280;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    fontWeight: 'bold',

    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    backgroundColor: theme.palette.primary.main,
    whiteSpace: 'nowrap',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  lock: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    backgroundColor: theme.palette.background.default,
  },
  link: {
    letterSpacing: '.2rem',
    textDecoration: 'none',
    color: 'white',
  },
  subtitle: {
    display: 'inline-block',
    lineHeight: 0,
  },
  nested: {
    // paddingLeft: theme.spacing(3),
  },
  info: {
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
    flexGrow: 1,
    color: 'grey',
  },
}));

const Icons: IIndexable = {
  Organisation: Domain,
  Contractor: Build,
  Spaces: MeetingRoom,
  User: Group,
  'Prism Install': AssignmentInd,
  Settings: Settings,
  'Energy Meter': EvStation,
  Tags: LocalOffer,
};

const MenuItem = ({ name }: { name: string }) => {
  const Icon = Icons[name];

  if (name === '_') return <Divider variant="middle" />;

  return (
    <>
      <ListItemLink
        to={`/${name.toLowerCase().replace(' ', '_')}`}
        primary={name}
        icon={<Icon style={{ fontSize: 30, color: 'white' }} />}
      />
    </>
  );
};

const Navbar: React.FC = ({ children }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [openNestThing, setOpenNestThing] = React.useState(false);
  const [openNest, setOpenNest] = React.useState(false);
  const [lock, setLock] = React.useState(false);

  const auth = useAuth();

  const handleNestItemOn = () => {
    setOpenNest(!openNest);
  };

  const handleNestThingOn = () => {
    setOpenNestThing(!openNestThing);
  };

  const handleLockButton = () => {
    setOpen(!open);
    setLock(!lock);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        color="primary"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={() => setLock(!lock)}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <Menu style={{ fontSize: 30, color: 'white' }} />
          </IconButton>
          <Link to="/" className={classes.link}>
            <img
              src="Mindsett Logo_White.png"
              width={150}
              alt="Mindsett Logo"
            />
          </Link>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        color="primary"
        className={clsx(classes.drawer, {
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx(classes.drawer, {
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.lock}>
          <IconButton onClick={handleLockButton}>
            {lock ? (
              <LockOpen style={{ fontSize: 30, color: 'white' }} />
            ) : (
              <Lock style={{ fontSize: 30, color: 'white' }} />
            )}
          </IconButton>
        </div>
        <Divider />
        <List>
          {[
            'Organisation',
            'Contractor',
            'Spaces',
            'User',
            'Prism Install',
            '_',
            'Energy Meter',
            'Tags',
          ].map((key) => (
            <MenuItem name={key} key={key} />
          ))}
          <Divider variant="middle" />

          <ListItem
            style={{ fontSize: 30, color: 'white', paddingLeft: 0 }}
            button
            onClick={handleNestThingOn}
          >
            <ListItemLink
              to={`/things`}
              primary="Things"
              icon={<Kitchen style={{ fontSize: 30, color: 'white' }} />}
            />

            {openNestThing ? (
              <ExpandLess style={{ marginLeft: 100 }} />
            ) : (
              <ExpandMore style={{ marginLeft: 100 }} />
            )}
          </ListItem>
          <Collapse in={openNestThing} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem button className={classes.nested}>
                <ListItemLink
                  to={`/group`}
                  primary="Group"
                  icon={
                    <CategoryIcon style={{ fontSize: 30, color: 'white' }} />
                  }
                />
              </ListItem>
              <ListItem button className={classes.nested}>
                <ListItemLink
                  to={`/category`}
                  primary="Category"
                  icon={
                    <SettingsRemoteIcon
                      style={{ fontSize: 30, color: 'white' }}
                    />
                  }
                />
              </ListItem>
              <ListItem button className={classes.nested}>
                <ListItemLink
                  to={`/thing_type`}
                  primary="Type"
                  icon={<Memory style={{ fontSize: 30, color: 'white' }} />}
                />
              </ListItem>
            </List>
          </Collapse>
          <ListItem
            button
            style={{ fontSize: 30, color: 'white' }}
            onClick={handleNestItemOn}
          >
            {' '}
            <Tooltip title={'Sensor Nodes'} arrow>
              <ListItemIcon>
                <SettingsInputAntenna
                  style={{ fontSize: 30, color: 'white' }}
                />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary="Sensor Nodes" />
            {openNest ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={openNest} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem button className={classes.nested}>
                <ListItemLink
                  to={`/sensor_nodes`}
                  primary="Sensor Node Nodes"
                  icon={
                    <SettingsRemoteIcon
                      style={{ fontSize: 30, color: 'white' }}
                    />
                  }
                />
              </ListItem>
              <ListItem button className={classes.nested}>
                <ListItemLink
                  to={`/sensor_nodes_instances`}
                  primary="Sensor Node Instances"
                  icon={<Memory style={{ fontSize: 30, color: 'white' }} />}
                />
              </ListItem>
              <ListItem
                button
                style={{ fontSize: 30, color: 'white' }}
                onClick={handleNestItemOn}
              >
                {' '}
                <Tooltip title="Measurements" arrow>
                  <ListItemIcon>
                    <SettingsInputAntenna
                      style={{ fontSize: 30, color: 'white' }}
                    />
                  </ListItemIcon>
                </Tooltip>
                <ListItemText primary="Measurements" />
                {openNest ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={openNest} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem button className={classes.nested}>
                    <ListItemLink
                      to={`/sensor_node_category`}
                      primary="Category"
                      icon={
                        <SettingsRemoteIcon
                          style={{ fontSize: 30, color: 'white' }}
                        />
                      }
                    />
                  </ListItem>
                  <ListItem button className={classes.nested}>
                    <ListItemLink
                      to={`/sensor_node_spec`}
                      primary="Spec"
                      icon={<Memory style={{ fontSize: 30, color: 'white' }} />}
                    />
                  </ListItem>
                  <ListItem button className={classes.nested}>
                    <ListItemLink
                      to={`/sensor_node_type`}
                      primary="Type"
                      icon={<Memory style={{ fontSize: 30, color: 'white' }} />}
                    />
                  </ListItem>
                </List>
              </Collapse>
            </List>
          </Collapse>
          <Divider variant="middle" />
          <ListItem button onClick={() => auth.logOut()}>
            <ListItemIcon>
              <ExitToApp style={{ fontSize: 30, color: 'white' }} />
            </ListItemIcon>
            <ListItemText primary="Log Out" />
          </ListItem>
        </List>
        <Box className={classes.info}>
          {process.env.REACT_APP_BUILD_INFO && open ? (
            <Typography>Build: {process.env.REACT_APP_BUILD_INFO}</Typography>
          ) : null}
        </Box>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {children}
      </main>
    </div>
  );
};

export default Navbar;
