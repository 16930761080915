import { CellProps } from 'react-table';
import { formatDateString, formatDateStringPricing } from 'utils/helpers';
import EditIcon from '@material-ui/icons/Edit';
import ApartmentIcon from '@material-ui/icons/Apartment';
import { Button, createStyles, lighten, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Column } from 'react-table';
import { ColumnDef as TanColumn } from '@tanstack/react-table';
import { SpecList } from 'pages/energy-meter';
import { SpaceSchedule } from 'types';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import PersonAddDisabledIcon from '@material-ui/icons/PersonAddDisabled';
import PersonIcon from '@material-ui/icons/Person';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import Tooltip from '@material-ui/core/Tooltip';
import { GlobalThing } from 'utils/makeData';
import { DateColumnFilter } from 'components/PrismInstallTableO';

export const formatDate = ({ value }: CellProps<any>) => {
  return value === 'No Data' ? 'No Data' : formatDateString(value);
};

export const formatPricingDate = ({ value }: CellProps<any>) =>
  formatDateStringPricing(value);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      marginBottom: 50,
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
    },

    icon: {
      width: 30,
      display: 'flex',
      justifyContent: 'flex-end',
      '&:hover': {
        color: lighten(theme.palette.secondary.light, 0),
        fontSize: 30,
      },
    },

    deactivateicon: {
      width: 30,
      color: 'green',
      display: 'flex',
      justifyContent: 'flex-end',
      '&:hover': {
        color: lighten('rgba(0,128,0,0.5)', 0),
        fontSize: 30,
      },
    },

    activateicon: {
      width: 30,
      color: 'red',
      display: 'flex',
      justifyContent: 'flex-end',
      '&:hover': {
        color: lighten('rgba(255,0,0,0.5)', 0),
        fontSize: 30,
      },
    },
  })
);

export const ORG_COLUMNS = [
  { accessor: 'name', Header: 'NAME' },
  { accessor: 'id', Header: 'ID' },
  {
    accessor: 'edit',
    Cell: (props: any) => {
      const classes = useStyles();
      return <EditIcon className={classes.icon}> </EditIcon>;
    },
  },
];

export const AlL_USERS_COLUMNS = [
  {
    id: 'name',
    accessor: (user: any) => {
      return `${user.first_name} ${user.last_name}`;
    },
    Header: 'NAME',
  },
  {
    accessor: (user: any) => {
      return `${user.member_of?.organisation?.name || 'unassigned'}`;
    },
    Header: 'ORGANISATION',
  },
  {
    accessor: (user: any) => {
      return user.space_subscriptions
        .map((space) => space.space.name)
        .join(', ');
    },
    width: 100,
    Header: 'BUILDINGS',
  },

  { accessor: 'created_date', Header: 'CREATED DATE', Cell: formatDate },
  { accessor: 'email', Header: 'EMAIL' },
  { accessor: 'id', Header: 'USER ID' },
  {
    accessor: 'active',
    Header: 'STATUS',
    Cell: (props: any) => {
      const classes = useStyles();

      return props.row.original.active ? (
        <PersonAddDisabledIcon className={classes.activateicon}>
          {' '}
        </PersonAddDisabledIcon>
      ) : (
        <PersonIcon className={classes.deactivateicon}> </PersonIcon>
      );
    },
  },
  {
    accessor: 'subscribe',
    Cell: ({ props }: any) => {
      const classes = useStyles();
      return <ApartmentIcon className={classes.icon}> </ApartmentIcon>;
    },
  },
  {
    accessor: 'edit',
    Cell: ({ props }: any) => {
      const classes = useStyles();
      return <EditIcon className={classes.icon}> </EditIcon>;
    },
  },
];

export const ENGINEERS_COLUMNS = [
  {
    id: 'name',
    accessor: (user: any) => {
      return `${user.first_name} ${user.last_name}`;
    },
    Header: 'NAME',
  },

  { accessor: 'created_date', Header: 'CREATED DATE', Cell: formatDate },
  { accessor: 'email', Header: 'EMAIL' },
  { accessor: 'id', Header: 'USER ID' },
  {
    accessor: 'active',
    Header: 'STATUS',
    Cell: (props: any) => {
      const classes = useStyles();

      return props.row.original.active ? (
        <PersonAddDisabledIcon className={classes.activateicon}>
          {' '}
        </PersonAddDisabledIcon>
      ) : (
        <PersonIcon className={classes.deactivateicon}> </PersonIcon>
      );
    },
  },

  {
    accessor: 'edit',
    Cell: ({ props }: any) => {
      const classes = useStyles();
      return <EditIcon className={classes.icon}> </EditIcon>;
    },
  },
];
export const USERS_COLUMNS = [
  {
    id: 'name',
    accessor: (user: any) => {
      return `${user.first_name} ${user.last_name}`;
    },
    Header: 'NAME',
  },

  { accessor: 'created_date', Header: 'CREATED DATE', Cell: formatDate },
  { accessor: 'email', Header: 'EMAIL' },
  { accessor: 'id', Header: 'USER ID' },
  {
    accessor: 'active',
    Header: 'STATUS',
    Cell: (props: any) => {
      const classes = useStyles();

      return props.row.original.active ? (
        <PersonAddDisabledIcon className={classes.activateicon}>
          {' '}
        </PersonAddDisabledIcon>
      ) : (
        <PersonIcon className={classes.deactivateicon}> </PersonIcon>
      );
    },
  },
];
// export const USERS_COLUMNS = [
//   {
//     accessor: ({ user }: any) => {
//       return `${user.first_name} ${user.last_name}`;
//     },
//     Header: 'NAME',
//   },

//   { accessor: 'user.email', Header: 'EMAIL' },

//   {
//     accessor: 'user.active',
//     Header: 'STATUS',
//     Cell: (props: any) => {
//       const classes = useStyles();

//       return props.row.original.user.active ? (
//         <PersonAddDisabledIcon className={classes.activateicon}>
//           {' '}
//         </PersonAddDisabledIcon>
//       ) : (
//         <PersonIcon className={classes.deactivateicon}> </PersonIcon>
//       );
//     },
//   },
// ];

export const SPACE_USERS = [
  {
    accessor: (user: any) => {
      return `${
        user?.space?.organisation?.members?.find(
          (mem: any) => mem.user.id === user.user_id
        )?.user.first_name
      } ${
        user?.space?.organisation?.members?.find(
          (mem: any) => mem.user.id === user.user_id
        )?.user.last_name
      }`;
    },
    Header: 'Subscribed Users',
  },
];

export const SPACE_COLUMNS = [
  { accessor: 'name', Header: 'NAME' },
  { accessor: 'org_id', Header: 'ORG ID' },
  {
    id: 'organisation.name',
    accessor: 'organisation.name',
    Header: 'ORGANISATION',
    width: 100,
  },
  { accessor: 'building.name', Header: 'BUILDING' },
  { accessor: 'parent_id', Header: 'PARENT ID' },
  { accessor: 'created_date', Header: 'CREATED DATE', Cell: formatDate },
  {
    id: 'created_by_user.first_name',
    accessor: ({ created_by_user }: any) => {
      return `${created_by_user?.first_name} ${created_by_user?.last_name}`;
    },
    Header: 'CREATED BY',
  },
  { accessor: 'id', Header: 'ID' },
  {
    accessor: 'edit',
    Cell: ({ props }: any) => {
      const classes = useStyles();
      return <EditIcon className={classes.icon}> </EditIcon>;
    },
  },
];

export const SUBSCRIBED_BUILDINGS_COLUMNS = [
  { accessor: 'name', Header: 'NAME' },
  { accessor: 'id', Header: 'ID' },
];

export const SPACES_SPACE_COLUMNS = [
  { accessor: 'name', Header: 'NAME' },
  {
    accessor: (annot: any) => annot?.annotations[0].organisation?.name,
    Header: 'ORGANISATION',
  },
  { accessor: 'building.name', Header: 'BUILDING' },

  { accessor: 'created_date', Header: 'CREATED DATE', Cell: formatDate },
  {
    id: 'created_by_user.first_name',
    accessor: ({ created_by_user }: any) => {
      return `${created_by_user?.first_name} ${created_by_user?.last_name}`;
    },
    Header: 'CREATED BY',
  },
  { accessor: 'id', Header: 'ID' },
  { accessor: 'parent_id', Header: 'PARENT ID' },
  { accessor: 'org_id', Header: 'ORG ID' },
  {
    accessor: 'edit',
    Cell: (props: any) => {
      const classes = useStyles();
      return <EditIcon className={classes.icon}> </EditIcon>;
    },
  },
];

export const GLOBAL_THINGS_COLUMNS = [
  { accessor: 'id' },
  { accessor: 'name', Header: 'NAME' },
  { accessor: 'type.category.group.name', Header: 'GROUP' },
  { accessor: 'type.category.name', Header: 'CATEGORY' },
  { accessor: 'type.typeName', Header: 'TYPE' },
  {
    accessor: ({ annotations }: any) => {
      const val = annotations.find((a) => a.key === 'MMI')?.value && 'MMI';

      const d =
        annotations.find((a) => a.key === 'MMI')?.created_at ?? 'No Data';
      return { v: val, d: formatDateString(d) };
    },
    Cell: ({ value }: any) => {
      const classes = useStyles();
      return value?.v === 'MMI' ? (
        <Tooltip title={value?.d} arrow>
          <CheckIcon className={classes.deactivateicon} />
        </Tooltip>
      ) : (
        <CloseIcon className={classes.activateicon}> </CloseIcon>
      );
    },

    Header: 'MMI',
  },
  {
    accessor: ({ annotations }: any) => {
      const val = annotations.find((a) => a.key === 'MI')?.value && 'MI';

      const d =
        annotations.find((a) => a.key === 'MI')?.created_at ?? 'No Data';
      return { v: val, d: formatDateString(d) };
    },
    Cell: ({ value }: any) => {
      const classes = useStyles();

      return value?.v === 'MI' ? (
        <Tooltip title={value?.d} arrow>
          <CheckIcon className={classes.deactivateicon} />
        </Tooltip>
      ) : (
        <CloseIcon className={classes.activateicon}> </CloseIcon>
      );
    },

    Header: 'MI',
  },

  {
    accessor: 'organisation.name',
    Header: 'ORGANISATION',
  },
  { accessor: 'building.name', Header: 'BUILDING' },
  { accessor: 'space.name', Header: 'SPACE' },

  { accessor: 'parent_id', Header: 'PARENT ID' },

  { accessor: 'org_id' },

  { accessor: 'type_id' },
  {
    accessor: ({ variable }: any) => {
      return variable ? 'variable' : 'fixed';
    },
    Header: 'VARIABLE/FIXED',
  },
  { accessor: 'created_date', Header: 'CREATED', Cell: formatDate },
  {
    accessor: 'edit',
    Cell: (props: any) => {
      const classes = useStyles();
      return <EditIcon className={classes.icon}> </EditIcon>;
    },
  },
];
export const GLOBAL_PRISM_COLUMNS = [
  { accessor: 'id' },
  { accessor: 'building_id' },
  { accessor: 'client_org_id' },
  { accessor: 'contractor_org_id' },
  { accessor: 'number', Header: 'TASK ID' },
  {
    accessor: 'client_org.name',
    Header: 'CLIENT',
  },
  { accessor: 'building.name', Header: 'BUILDING' },

  {
    accessor: 'contractor_org.name',
    Header: 'CONTRACTOR',
  },
  { accessor: 'status.name', Header: 'STATUS' },
  // { accessor: 'description', Header: 'DESCRIPTION', disableFilters: true },

  {
    accessor: 'assigned_date',
    Header: 'ASSIGNED',
    Cell: formatDate,
    // Filter: DateColumnFilter,
  },
  {
    accessor: 'due_date',
    Header: 'DUE',
    Cell: formatDate,
    // Filter: DateColumnFilter,
  },
  {
    // accessor: 'complete_date',
    accessor: (complete_date: any) => {
      return 'N/A'; // temporary fix for completed column till Null
    },

    Header: 'COMPLETED',
    // Cell: formatDate,
    //Filter: DateColumnFilter,
  },
  { accessor: 'created_at', Header: 'CREATED', Cell: formatDate },

  {
    accessor: 'actions',
    disableFilters: true,
    Header: 'ACTIONS',
    Cell: (props: any) => {
      const classes = useStyles();
      return (
        <Button variant="contained" color="secondary">
          Details
        </Button>
      );
    },
  },
];

export const THING_THING_COLUMNS = [
  { accessor: 'id' },
  { accessor: 'name', Header: 'NAME' },

  { accessor: 'type.category.group.name', Header: 'GROUP' },

  { accessor: 'type.category.name', Header: 'CATEGORY' },
  { accessor: 'type.typeName', Header: 'TYPE' },
  { accessor: 'type_id' },
  {
    accessor: ({ variable }: any) => {
      return variable ? 'variable' : 'fixed';
    },
    Header: 'VARIABLE/FIXED',
  },
  {
    accessor: ({ annotations }: any) => {
      const val = annotations.find((a) => a.key === 'MMI')?.value && 'MMI';

      const d =
        annotations.find((a) => a.key === 'MMI')?.created_at ?? 'No Data';
      return { v: val, d: formatDateString(d) };
    },
    Cell: ({ value }: any) => {
      const classes = useStyles();
      return value?.v === 'MMI' ? (
        <Tooltip title={value?.d} arrow>
          <CheckIcon className={classes.deactivateicon} />
        </Tooltip>
      ) : (
        <CloseIcon className={classes.activateicon}> </CloseIcon>
      );
    },

    Header: 'MMI',
  },
  {
    accessor: ({ annotations }: any) => {
      const val = annotations.find((a) => a.key === 'MI')?.value && 'MI';

      const d =
        annotations.find((a) => a.key === 'MI')?.created_at ?? 'No Data';
      return { v: val, d: formatDateString(d) };
    },
    Cell: ({ value }: any) => {
      const classes = useStyles();

      return value?.v === 'MI' ? (
        <Tooltip title={value?.d} arrow>
          <CheckIcon className={classes.deactivateicon} />
        </Tooltip>
      ) : (
        <CloseIcon className={classes.activateicon}> </CloseIcon>
      );
    },

    Header: 'MI',
  },
  { accessor: 'space.name', Header: 'SPACE' },
  { accessor: 'parent_id', Header: 'PARENT ID' },
  { accessor: 'created_date', Header: 'CREATED DATE', Cell: formatDate },
  { accessor: 'org_id' },

  {
    accessor: 'edit',
    Cell: (props: any) => {
      const classes = useStyles();
      return <EditIcon className={classes.icon}> </EditIcon>;
    },
  },
  { id: 'space_id', accessor: 'space.space_id' },
];

export const THING_GROUP_COLUMNS = [
  { accessor: 'id' },
  { accessor: 'name', Header: 'Name' },
  { accessor: 'created_by', Header: 'Created By' },
  { accessor: 'description', Header: 'Description' },
  { accessor: 'created_date', Header: 'Created Date', Cell: formatDate },
  { accessor: 'org_id' },
  {
    accessor: 'edit',
    Cell: (props: any) => {
      const classes = useStyles();
      return <EditIcon className={classes.icon}> </EditIcon>;
    },
  },
];

export const THING_CATEGORY_COLUMNS = [
  { accessor: 'id' },
  { accessor: 'name', Header: 'Name' },
  { accessor: 'group.name', Header: 'Group' },
  { accessor: 'description', Header: 'Description' },
  { accessor: 'created_date', Header: 'Created Date', Cell: formatDate },
  { accessor: 'org_id' },
  {
    accessor: 'edit',
    Cell: (props: any) => {
      const classes = useStyles();
      return <EditIcon className={classes.icon}> </EditIcon>;
    },
  },
];

export const THING_TYPE_COLUMNS = [
  { accessor: 'id' },
  { accessor: 'name', Header: 'Name' },
  { accessor: 'description', Header: 'Description' },
  { accessor: 'created_date', Header: 'Created Date', Cell: formatDate },
  { accessor: 'org_id' },
  { accessor: 'category_id', Header: 'Category ID' },
  {
    accessor: 'category.name',
    Header: 'Category',
  },
  {
    accessor: 'category.group.name',
    Header: 'Group',
  },

  {
    accessor: 'edit',
    Cell: (props: any) => {
      const classes = useStyles();
      return <EditIcon className={classes.icon}> </EditIcon>;
    },
  },
];

export const SPACE_THING_COLUMNS = [
  { accessor: 'id' },
  { accessor: 'name', Header: 'NAME' },

  { accessor: 'type.category.group.name', Header: 'GROUP' },
  { accessor: 'type.category.name', Header: 'CATEGORY' },
  { accessor: 'type.typeName', Header: 'TYPE' },
  { accessor: 'type_id' },

  {
    accessor: ({ variable }: any) => {
      return variable ? 'variable' : 'fixed';
    },
    Header: 'VARIABLE/FIXED',
  },
  {
    accessor: ({ annotations }: any) => {
      const val = annotations.find((a) => a.key === 'MMI')?.value && 'MMI';

      const d =
        annotations.find((a) => a.key === 'MMI')?.created_at ?? 'No Data';
      return { v: val, d: formatDateString(d) };
    },
    Cell: ({ value }: any) => {
      const classes = useStyles();
      return value?.v === 'MMI' ? (
        <Tooltip title={value?.d} arrow>
          <CheckIcon className={classes.deactivateicon} />
        </Tooltip>
      ) : (
        <CloseIcon className={classes.activateicon}> </CloseIcon>
      );
    },

    Header: 'MMI',
  },
  {
    accessor: ({ annotations }: any) => {
      const val = annotations.find((a) => a.key === 'MI')?.value && 'MI';

      const d =
        annotations.find((a) => a.key === 'MI')?.created_at ?? 'No Data';
      return { v: val, d: formatDateString(d) };
    },
    Cell: ({ value }: any) => {
      const classes = useStyles();

      return value?.v === 'MI' ? (
        <Tooltip title={value?.d} arrow>
          <CheckIcon className={classes.deactivateicon} />
        </Tooltip>
      ) : (
        <CloseIcon className={classes.activateicon}> </CloseIcon>
      );
    },

    Header: 'MI',
  },
  { accessor: 'space.name', Header: 'SPACE' },

  { accessor: 'parent_id', Header: 'PARENT ID' },
  { accessor: 'created_date', Header: 'CREATED DATE', Cell: formatDate },
  { accessor: 'org_id' },

  {
    accessor: 'edit',
    Cell: (props: any) => {
      const classes = useStyles();
      return <EditIcon className={classes.icon}> </EditIcon>;
    },
  },
  { accessor: 'space_id' },
];
export const PRICING_COLUMNS = [
  { accessor: 'start', Header: 'START DATE', Cell: formatPricingDate },
  { accessor: 'end', Header: 'END DATE', Cell: formatPricingDate },
  { accessor: 'price', Header: 'PRICE' },
  { accessor: 'id', Header: 'ID' },
  {
    accessor: 'edit',
    Cell: (props: any) => {
      const classes = useStyles();
      return <EditIcon className={classes.icon}> </EditIcon>;
    },
  },
];

export const ANNOTATIONS_COLUMNS = [
  { accessor: 'key', Header: 'KEY' },
  { accessor: 'type', Header: 'TYPE' },
  { accessor: 'id', Header: 'ID' },
  { accessor: 'value', Header: 'VALUE' },
  { accessor: 'created_at', Header: 'CREATED AT', Cell: formatDate },
];
// export const ANNOTATIONS_COLUMNS_: Column<SpaceAnnotation>[] = [
//   { accessor: 'id', Header: 'ID' },
//   { accessor: 'key', Header: 'Key' },
//   { accessor: 'value', Header: 'Value' },
//   { accessor: 'type', Header: 'Type' },
//   {}
// ];

export const ENERGY_CHANNEL_COLUMNS = [
  { accessor: 'id', Header: 'ID' },
  { accessor: 'hardware_id', Header: 'Hardware ID' },
  { accessor: 'name', Header: 'Name' },
  { accessor: 'space.name', Header: 'SPACE' },
  {
    accessor: 'thing.name',

    Header: 'THING',
  },

  {
    accessor: 'edit',
    Cell: (props: any) => {
      const classes = useStyles();
      return <AddCircleIcon className={classes.icon}> </AddCircleIcon>;
    },
  },
  {
    accessor: 'update',
    Cell: (props: any) => {
      const classes = useStyles();
      return <EditIcon className={classes.icon}> </EditIcon>;
    },
  },
];

export const ALL_SENSOR_NODE_NODES = [
  { accessor: 'id', Header: 'ID' },
  { accessor: 'name', Header: 'NAME' },
  { accessor: 'model', Header: 'MODEL' },
  { accessor: 'description', Header: 'DESCRIPTION' },
  {
    accessor: 'edit',
    Cell: (props: any) => {
      const classes = useStyles();
      return <EditIcon className={classes.icon}> </EditIcon>;
    },
  },
];

export const SENSOR_NODE_FEATURES = [
  { accessor: 'id', Header: 'ID' },
  { accessor: 'spec.name', Header: 'NAME' },
  { accessor: 'spec.description', Header: 'DESCRIPTION' },
  { accessor: 'spec.maximum', Header: 'MAXIMUM' },
  { accessor: 'spec.minimum', Header: 'MINIMUM' },
  { accessor: 'spec.unit', Header: 'UNIT' },
  { accessor: 'spec.category.name', Header: 'CATEGORY NAME' },
  { accessor: 'spec.category.description', Header: 'CATEGORY DESCRIPTION' },
  { accessor: 'spec.type.name', Header: 'TYPE NAME' },
  { accessor: 'spec.type.description', Header: 'TYPE DESCRIPTION' },
  { accessor: 'suffix', Header: 'SUFFIX' },
  {
    accessor: 'edit',
    Cell: (props: any) => {
      const classes = useStyles();
      return <EditIcon className={classes.icon}> </EditIcon>;
    },
  },
];

export const ALL_SENSOR_NODE_INSTANCES = [
  { accessor: 'id', Header: 'ID' },
  { accessor: 'spec.model', Header: 'MODEL' },
  {
    id: 'organisation.name',
    accessor: 'organisation.name',
    Header: 'ORGANISATION',
    width: 100,
  },
  {
    id: 'location',
    accessor: (value: any) =>
      value?.space?.name ? value?.space?.name : value?.thing?.name,
    Header: 'LOCATION',
    width: 100,
  },
  {
    id: 'kind',
    accessor: (value: any) => (value?.space?.name ? `Space` : `Thing`),
    Header: 'Kind',
    width: 100,
  },

  { accessor: 'node_id', Header: 'NODE ID' },
  { accessor: 'created_by', Header: 'CREATED BY' },
  { accessor: 'created_at', Header: 'CREATED AT', Cell: formatDate },
  {
    accessor: 'edit',
    Cell: (props: any) => {
      const classes = useStyles();
      return <EditIcon className={classes.icon}> </EditIcon>;
    },
  },
];

export const SENSOR_NODE_INSTANCE_MEASUREMENTS = [
  { accessor: 'id', Header: 'ID' },
  { accessor: 'name', Header: 'NAME' },
  { accessor: 'hardware_id', Header: 'HARDWARE ID' },
  { accessor: 'created_by', Header: 'CREATED BY' },
  { accessor: 'created_at', Header: 'CREATED AT', Cell: formatDate },
  {
    accessor: 'edit',
    Cell: (props: any) => {
      const classes = useStyles();
      return <EditIcon className={classes.icon}> </EditIcon>;
    },
  },
];

export const SENSOR_NODE_INSTANCE_ANNOTATIONS = [
  { accessor: 'id', Header: 'ID' },
  { accessor: 'key', Header: 'KEY' },
  { accessor: 'node_instance_id', Header: 'NODE INSTANCE ID' },
  { accessor: 'org_id', Header: 'ORG ID' },
  { accessor: 'type', Header: 'TYPE' },
  { accessor: 'value', Header: 'VALUE' },
  { accessor: 'created_at', Header: 'CREATED AT' },
];

export const RANGE = [
  { accessor: 'id', Header: 'ID' },
  { accessor: 'minimum', Header: 'MINIMUM' },
  { accessor: 'maximum', Header: 'MAXIMUM' },
  { accessor: 'created_by', Header: 'CREATED BY' },
  { accessor: 'created_at', Header: 'CREATED AT', Cell: formatDate },
];

export const ALL_MEASUREMENT_CATEGORY = [
  { accessor: 'id', Header: 'ID' },
  { accessor: 'name', Header: 'NAME' },
  { accessor: 'description', Header: 'DESCRIPTION' },
];

export const ALL_MEASUREMENT_SPEC = [
  { accessor: 'id', Header: 'ID' },
  { accessor: 'name', Header: 'NAME' },
  { accessor: 'description', Header: 'DESCRIPTION' },
  { accessor: 'unit', Header: 'UNIT' },
  { accessor: 'minimum', Header: 'MINIMUM' },
  { accessor: 'maximum', Header: 'MAXIMUM' },
  { accessor: 'type_id', Header: 'TYPE_ID' },
  { accessor: 'category_id', Header: 'CATEGORY_ID' },
];

export const ALL_MEASUREMENT_TYPE = [
  { accessor: 'id', Header: 'ID' },
  { accessor: 'name', Header: 'NAME' },
  { accessor: 'description', Header: 'DESCRIPTION' },
];

export const ALL_TAGS = [
  { accessor: 'id', Header: 'ID' },
  { accessor: 'physical_id', Header: 'PHYSICAL ID' },
  { accessor: 'type_id', Header: 'TYPE ID' },
  { accessor: 'type.name', Header: 'TYPE' },
  { accessor: 'created_at', Header: 'CREATED AT' },
];

export const TAG_TYPE = [
  { accessor: 'id', Header: 'ID' },
  { accessor: 'name', Header: 'TYPE NAME' },
  { accessor: 'created_at', Header: 'CREATED AT' },
  { accessor: 'description', Header: 'DESCRIPTION' },
];

export const SPACE_SCHEDULE = [
  { accessor: 'id', Header: 'ID' },
  { accessor: 'name', Header: 'NAME' },
  { accessor: 'day', Header: 'Day' },
  { accessor: 'start_time', Header: 'START TIME' },
  { accessor: 'end_time', Header: 'END TIME' },
  { accessor: 'created_by', Header: 'CREATED BY' },
];

export const schedule_columns: Column<SpaceSchedule>[] = [
  { accessor: 'name', Header: 'NAME' },
  { accessor: 'day', Header: 'Day' },
  { accessor: 'start_time', Header: 'START TIME' },
  { accessor: 'end_time', Header: 'END TIME' },
  { accessor: 'created_by', Header: 'CREATED BY' },
  { accessor: 'org_id', Header: 'Org id' },
  { accessor: 'space_id', Header: 'space id' },
];

export const columns = [
  { accessor: 'id', Header: 'ID' },

  { accessor: 'hardware_id', Header: 'HARDWARE ID', filter: 'includes' },
  { accessor: 'organisation.name', Header: 'ORGANISATION' },
  { accessor: 'building.name', Header: 'BUILDING' },

  { accessor: 'space.name', Header: 'SPACE' },
  {
    id: 'spec',
    accessor: ({ spec }: any) => <SpecList spec={spec} />,
    Header: 'SPEC',
  },
  { accessor: 'created_at', Header: 'CREATED AT', Cell: formatDate },
  {
    accessor: 'edit',
    Cell: (props: any) => {
      const classes = useStyles();
      return <EditIcon className={classes.icon}> </EditIcon>;
    },
  },
];

export enum StepsType {
  SET_STEPS = 'SET_STEPS',
  INCREMENT_CURRENT_STEP = 'INCREMENT_CURRENT_STEP',
  DECREMENT_CURRENT_STEP = 'DECREMENT_CURRENT_STEP',
}
